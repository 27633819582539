<template>
    <div class="block-section">
        <div class="profile-section">
            <img v-if="store.getters.getUserObject?.profileUrl" :src="store.getters.getUserObject?.profileUrl" />
            <img v-else src="@/assets/mypage/dummy.png">
            <label>{{ store.getters.getUserObject?.name || '-' }}</label>

            <button v-if="!store.getters.isVisitMode" @click="state.showModify = true">{{ store.getters.isEnglish ? 'edit' : '수정하기'}}</button>
            <button v-else-if="!state.isMyFriend" class="addfriend" @click="onClickAdd">{{ store.getters.isEnglish ? '+ add' : '+ 친구 추가' }}</button>
        </div>
        <ul class="menu-section">
            <li :class="{'active': state.menu == 0}" @click="onClick(0)">
                <img src="@/assets/mypage/menu_dashboard.png">
                <label>{{ store.getters.isEnglish ? 'Dashboard' : '대시보드'}}</label>
            </li>
            <li v-if="!store.getters.isVisitMode" :class="{'active': state.menu == 1}" @click="onClick(1)">
                <img src="@/assets/mypage/menu_reward.png">
                <label>{{ store.getters.isEnglish ? 'Reward' : '리워드'}}</label>
            </li>
            <li v-if="!store.getters.isVisitMode" :class="{'active': state.menu == 2}" @click="onClick(2)">
                <img src="@/assets/mypage/menu_friend.png">
                <label>{{ store.getters.isEnglish ? 'Friends' : '나의 친구'}}</label>
            </li>
            <li :class="{'active': state.menu == 3}" @click="onClick(3)">
                <img src="@/assets/mypage/menu_mission.png">
                <label>{{ store.getters.isEnglish ? 'Mission' : '미션'}}</label>
            </li>
            <li v-if="store.getters.getUserObject?.foreigner != 1 && store.getters.getOriginalUser?.foreigner != 1" :class="{'active': state.menu == 4}" @click="onClick(4)">
                <img src="@/assets/mypage/menu_world.png">
                <label>{{ store.getters.isEnglish ? 'World Issue Activity' : '세계이슈활동'}}</label>
            </li>
            <li v-if="!store.getters.isVisitMode && store.getters.getUserObject?.role == 'ADMIN'" :class="{'active': state.menu == 5}" @click="onClick(5)">
                <img src="@/assets/mypage/menu_setting.png">
                <label>{{ store.getters.isEnglish ? 'Manage' : '관리'}}</label>
            </li>
        </ul>

        <div v-if="state.showModify" class="modify-modalFull">
            <div class="modify-modal">
                <img v-if="store.getters.getUserObject?.profileUrl" :src="store.getters.getUserObject?.profileUrl"  @click="onClickUpload"/>
                <img v-else src="@/assets/mypage/dummy.png" @click="onClickUpload">
                <input type="text" v-model="state.name">

                <input type="file" accept="image/jpg,image/jpeg,image/png,video/mp4,video/mov" style="visibility: hidden;" ref="file" @change="onChangeFile" />

                <div class="button-section">
                    <button class="share" @click="onClickSaveFavorit">확인</button>
                    <button class="go" @click="state.showModify = false">취소</button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    name: 'ProfileBlock',
    emits: ['update:modelValue','click'],
    props: {
        modelValue: {
            type: Number,
            default: 0,
        }
    },
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const store = useStore();
        const state = reactive({
            menu: 0,
            isMyFriend: true,
            name: store.getters.getUserObject?.name || '',
        })
        
        const file = ref();

        watch(() => store.getters.getUserObject, () => initPage())
        watch(() => props.modelValue, (value) => state.menu = value);

        const initPage = () => {
            if(!store.getters.getUserObject) return;

            state.name = store.getters.getUserObject?.name || '';
            axios.get('/friend/isfriend/' + store.getters.getUserObject.id.toString())
            .then((res) => {
                state.isMyFriend = res.data.result;
            })
        }

        const onClick = (menu) => {
            state.menu = menu;
            emit('click', menu);
        }

        const onClickAdd = () => {
            axios.post('/friend/add', {
                friendId: store.getters.getUserObject.id,
            }).then((res) => {
                if(res.data.result) {
                    state.isMyFriend = true;
                    alert('친구로 등록했습니다.');
                } else {
                    state.isMyFriend = false;
                    alert('친구등록 실패')
                }
            }).catch(() => alert('친구등록 실패'));
        }

        const onClickUpload = () => {
            file.value.click();
        }

        const onChangeFile = () => {
            let formData = new FormData();
            formData.append("name", state.name);
            formData.append("file", file.value.files[0])
            axios.post('https://ic.girlscout.or.kr:5443/user/profile', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                if(res.data?.result || false) {
                    const user = store.getters.getUserObject;
                    user.profileUrl = res.data.data;

                    store.commit('setUserObject', user);

                    state.showModify = false;
                }
            })
        }

        return {
            store,
            state,
            file,
            initPage,
            onClick,
            onClickAdd,
            onClickUpload,
            onChangeFile,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
    align-items: center;
}

.addfriend {
    border:none;
    background-color: #84CAFF;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    padding: 7px 10px 5px 10px;
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        background-color: white;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 20px;
    }

    .profile-section {
        width: 100%;
        height: 70px;
        display: flex;
        background-image: url(~@/assets/mypage/back_profile.png);
        background-size: 75px;
        background-repeat: no-repeat;
        background-position: -13px -15px;
    }

    .profile-section > img {
        width: 47px;
        height: 47px;
        border-radius: 47px;
        overflow: hidden;
    }

    .profile-section > label {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 24px;
        font-weight: 700;
    }

    .profile-section > button {
        margin-left: auto;
        border: none;
        border-radius: 7px;
        height: 30px;
        background-color: #F5F7F9;
        color: #A5B5BB;
        font-size: 15px;
        font-weight: 700;
        padding: 5px 25px;
        margin-top: 5px;
    }

    .menu-section {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        justify-content:space-between;
        text-align: center;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        overflow: auto;
    }

    .menu-section::-webkit-scrollbar {
        display: none;
    }

    .menu-section > li {
        text-align: center;
        justify-items: center;
        align-items: center;
        width: 150px;
        height: 60px;
        position: relative;
    }

    .menu-section > li > img {
        display: block;
        margin: auto;
    }

    .menu-section > li > label {
        display: block;
        position: absolute;
        bottom: 10px;
        width: 60px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #A5B5BB;
    }

    .modify-modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 1000;
    }

    .modify-modal {
        position: fixed;
        z-index: 1000;
        width: 80%;
        height: 490px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;
        text-align: center;
    }

    .modify-modal > h1 {
        margin: 0;
        font-size: 24px;
        margin-top: 30px;
    }

    .modify-modal > p {
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    .modify-modal > img {
        width: 120px;
    }

    .modify-modal > .button-section {
        margin-top: 30px;
    }

    .modify-modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 16px;
        width: 80%;
        padding: 10px 20px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modify-modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-top: 20px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        overflow-y: auto;
        scrollbar-width: none;
        padding-bottom: 180px;
    }

    .block-section::-webkit-scrollbar {
        display: none;
    }

    .profile-section {
        width: 100%;
        height: 300px;
        display: block;
        background-image: url(~@/assets/mypage/back_profile.png);
        background-size: 188px;
        background-repeat: no-repeat;
        background-position: calc(50% + 5px) 20px;
        padding-top: 45px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .profile-section > img {
        width: 130px;
        height: 130px;
        display: block;
        margin: auto;
        border-radius: 130px;
    }

    .profile-section > label {
        margin-top: 25px;
        margin-left: 10px;
        font-size: 24px;
        font-weight: 500;
        display: block;
    }

    .profile-section > button {
        border: none;
        border-radius: 7px;
        height: 30px;
        background-color: #F5F7F9;
        color: #A5B5BB;
        font-size: 15px;
        font-weight: 700;
        padding: 5px 25px;
        margin-top: 15px;
    }

    .menu-section {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        justify-content:space-between;
        text-align: left;
        align-items: left;
    }

    .menu-section > li {
        text-align: left;
        width: 80%;
        height: 60px;
        display: flex;
        margin-top: 25px;
        margin: auto;
        border-radius: 12px;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding: 10px 20px;
        color: #69757F;
        vertical-align: center;
        line-height: 60px;
    }

    .menu-section > li > img {
        margin: auto;
        margin-left: 0px;
        margin-right: 0px;
    }

    .menu-section > li > label {
        display: inline-block;
        font-size: 18px;
        line-height: 16px;
        font-weight: 400;
        margin: auto 0;
        margin-left: 10px;
    }

    .menu-section > .active {
        background-color: #84CAFF !important;
        color: white !important;
        box-shadow: 4.8px 4.8px 7.8px rgba(65,91,114,0.2);
    }

    .modify-modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 1000;
    }

    .modify-modal {
        position: fixed;
        z-index: 1000;
        width: 350px;
        height: 400px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;

        text-align: center;
    }

    .modify-modal > img {
        object-fit: cover;
        width: 130px;
        height: 130px;
        overflow: hidden;
        border-radius: 130px;
    }

    .modify-modal > input {
        margin-top: 30px;
        border-radius: 10px;
        font-size: 24px;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        border: 1px solid #EFF2F3;
    }

    .modify-modal > input:focus {
        outline: none;
    }

    .modify-modal > .button-section {
        position: absolute;
        bottom: 60px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }

    .modify-modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 16px;
        width: 110px;
        padding: 10px 20px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modify-modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-right: 20px;
    }
}

</style>