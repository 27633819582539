<template>
    <div v-if="state.show" class="detailblock-section" @click="onClickClose">
        <div class="detail-section" @click="onClickDetail">
            <div class="content-box">
                <img :src="state.item?.fileUrl" />
            </div>
            <div class="input-box">
                <input type="text" :value="state.newReply" @input="onInputReply" @keypress.enter="onClickSaveReply">
                <button @click="onClickSaveReply">{{ store.getters.isEnglish ? 'save' : '댓글등록'}}</button>
            </div>
            <ul class="reply-box">
                <li v-for="(item, index) in state.reply" :key="index">
                    <img :src="item.profileUrl" />
                    <div class="reply-content">
                        <p>{{ item.content }}</p>
                        <label>{{ dateFormat(item.createdAt) }}</label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { reactive } from 'vue';
import axios from 'axios';
export default {
    name: 'MissionDetailBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            item: null,
            show: false,
            reply: [],
            newReply: '',
        })

        const initPage = function (id) {
            if(id > 0) {
                state.item = null;
                axios.get('/mission/detail/' + id.toString())
                .then((res) => {
                    if(res.data.result) {
                        state.item = res.data.data;
                    }
                })

                state.reply = [];
                axios.get('/reply/list/' + id.toString())
                .then((res) => {
                    if(res.data.result) {
                        state.reply = res.data.data;
                    }
                })
            }
        }

        const onShow = function (id) {
            state.show = true;
            initPage(id);
        }

        const onClickSaveReply = function () {
            if(state.newReply == '') {
                alert(store.getters.isEnglish ? 'Please enter your comment' : '댓글을 입력해 주세요');
                return;
            }

            axios.post('/reply', {
                id: state.item.id,
                content: state.newReply,
            }).then((res) => {
                if(res.data.result) {
                    state.reply.push({
                        id: 0,
                        content: state.newReply,
                        userId: store.getters.isVisitMode ? store.getters.getOriginalUser?.id : store.getters.getUserObject?.id,
                        userName: store.getters.isVisitMode ? store.getters.getOriginalUser?.name : store.getters.getUserObject?.name,
                        profileUrl: store.getters.isVisitMode ? store.getters.getOriginalUser?.profileUrl : store.getters.getUserObject?.profileUrl,
                        createdAt: new Date(),
                    });
                    state.newReply = '';
                } else {
                    alert('댓글 등록을 실패했습니다.');
                }
            })
        }

        const onClickClose = function () {
            state.show = false;
        }

        const onClickDetail = (e) => {
            e.stopPropagation();
        }

        const onInputReply = (e) => {
            state.newReply = e.target.value;
        }

        const dateFormat = function (ct) {
            const createdAt = new Date(ct);
            const year = createdAt.getFullYear().toString();
            const month = ((createdAt.getMonth() + 1) < 10 ? '0' : '') + (createdAt.getMonth() + 1).toString(); 
            const date = (createdAt.getDate() < 10 ? '0' : '') + createdAt.getDate().toString();
            const hour = (createdAt.getHours() < 10 ? '0' : '') + createdAt.getHours().toString();
            const minute = (createdAt.getMinutes() < 10 ? '0' : '') + createdAt.getMinutes().toString();

            return year + '.' + month + '.' + date + ' ' + hour + ' ' + minute;
        }

        return {
            store,
            state,
            initPage,
            onShow,
            onClickSaveReply,
            onClickClose,
            onClickDetail,
            onInputReply,
            dateFormat,
        }
    }
}
</script>

<style scoped>
.detailblock-section {
    display: block;
}

@media (min-width: 768px) {
    .detailblock-section {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 1000;
    }

    .detail-section {
        position: fixed;
        z-index: 1000;
        width: 650px;
        height: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;

        text-align: center;
    }

    .detail-section > .content-box {
        width: 590px;
        height: 350px;
        overflow: auto;

    }

    .detail-section > .content-box > img {
        width: 100%;
        object-fit: cover;
    }

    .detail-section > .input-box {
        margin-top: 30px;
    }

    .detail-section > .input-box > input {
        width: 400px;
        font-size: 20px;
        padding: 7px;
        border-radius: 10px;
        border: 1px solid #84CAFF;
    }

    .detail-section > .input-box > input:focus {
        outline: none;
    }

    .detail-section > .input-box > button {
        border: none;
        background-color: #84CAFF;
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 10px;
        margin-left: 10px;
    }

    .detail-section > .reply-box {
        list-style: none;
        margin: 30px 50px;
        padding: 0;
        height: 40%;
        white-space: nowrap;
        overflow: auto;
    }

    .reply-box > li {
        display: flex;
        margin-top: 30px;
    }

    .reply-box > li:first-child {
        margin-top: 0;
    }

    .reply-box > li > img {
        width: 48px;
        height: 48px;
        border-radius: 64px;
    }

    .reply-box > li > .reply-content {
        text-align: left;
        margin-left: 20px;
    }

    .reply-box > li > .reply-content > p {
        margin:0;
        color: #383838;
        font-size: 18px;
    }

    .reply-box > li > .reply-content > label {
        margin-top: 6px;
        display: block;
        font-size: 12px;
        color: #acacac;
    }
}
</style>