const favoritItem = [
    {
        id: 1,
        kor: "K-POP",
        eng: "K-POP",
        img: "myhead.png",
    },
    {
        id: 2,
        kor: "패션",
        eng: "fashion",
        img: "myfashion.png",
    },
    {
        id: 3,
        kor: "사진",
        eng: "Photography",
        img: "myphoto.png",
    },
    {
        id: 4,
        kor: "여행",
        eng: "Travel",
        img: "mytravel.png",
    },
    {
        id: 5,
        kor: "악기연주",
        eng: "Instruments",
        img: "myguitar.png",
    },
    {
        id: 6,
        kor: "댄스",
        eng: "Dance",
        img: "mydance.png",
    },
    {
        id: 7,
        kor: "노래",
        eng: "Song",
        img: "mymic.png",
    },
    {
        id: 8,
        kor: "드라마",
        eng: "Drama",
        img: "myslate.png",
    },
    {
        id: 9,
        kor: "음식",
        eng: "Food",
        img: "myspoon.png",
    },
    {
        id: 10,
        kor: "독서",
        eng: "Reading",
        img: "mybook.png",
    },
    {
        id: 11,
        kor: "그림",
        eng: "Picture",
        img: "mypicture.png",
    },
    {
        id: 12,
        kor: "운동",
        eng: "Health",
        img: "myhealth.png",
    },
    {
        id: 13,
        kor: "영화",
        eng: "Movie",
        img: "mypopcorn.png",
    },
    {
        id: 14,
        kor: "유튜브",
        eng: "Youtube",
        img: "myyoutube.png",
    },
    {
        id: 15,
        kor: "카페",
        eng: "Cafe",
        img: "mycoffee.png",
    },
    {
        id: 16,
        kor: "게임",
        eng: "Game",
        img: "mygame.png",
    },
];

export default favoritItem;
