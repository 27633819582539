<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/myshoes.png">
            <label>{{ store.getters.isEnglish ? 'My Favorit' : '나의 취향' }}</label>
        </div>
        <button v-if="!store.getters.isVisitMode" class="btn-modify" @click="onClickModify">{{ store.getters.isEnglish ? 'EDIT' : '수정하기' }}</button>
        <ul class="favorit-list">
            <li v-for="(item, index) in state.list" :key="index">
                <div class="favorit-item">
                    <img :src='`/img/mypage/favorit/` + state.favoritItems[item.favoritId-1].img'>
                    <label>{{ store.getters.isEnglish ? state.favoritItems[item.favoritId-1].eng : state.favoritItems[item.favoritId-1].kor }}</label>
                </div>
            </li>
        </ul>
        <div v-if="state.showModify" class="modify-full">
            <div class="modify-modal">
                <div class="title-section">
                    <h1 :innerHTML='store.getters.isEnglish ? `Choice<br />Your favorit` : `나의 취향을<br />선택해주세요`'></h1>
                    <h2>{{ store.getters.isEnglish ? 'Select up to 5' : '5개 까지 선택 가능' }}</h2>
                </div>
                

                
                <ul>
                    <li :class="{active: state.selectedFavorit.find((v) => v.favoritId == item.id)}" v-for="(item, index) in state.favoritItems" :key="index" @click="onSelectFavorit(item)">
                        <img :src="'/img/mypage/favorit/' + item.img" />
                        <label>{{  store.getters.isEnglish ? item.eng : item.kor }}</label>
                    </li>
                </ul>

                <div class="button-section">
                    <button class="share" @click="onClickSaveFavorit">{{ store.getters.isEnglish ? 'OK' : '확인' }}</button>
                    <button class="go" @click="state.showModify = false">{{ store.getters.isEnglish ? 'Cancel' : '취소' }}</button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';
import favoritData from '../FavoritData';

export default {
    name: 'FavoritBlock',
    emits: ['click'],
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list: [
                {
                    favoritId: 1,
                }
            ],
            favoritItems: favoritData,
            selectedFavorit: [],
            showModify: false,
        })

        watch(() => store.getters.getUserObject, () => initPage() );

        const initPage = () => {
            axios.get('/favorit/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                    state.selectedFavorit = [...state.list];
                } else {
                    state.list = [];
                    state.selectedFavorit = [];
                }
            })
        }

        const onClickModify = () => {
            state.showModify = true;
        }

        const onSelectFavorit = (item) => {
            const idx = state.selectedFavorit.findIndex((v) => v.favoritId == item.id)

            if(idx >= 0) {
                state.selectedFavorit.splice(idx, 1);
            } else {
                if(state.selectedFavorit.length < 5) {
                    state.selectedFavorit.push({
                        favoritId: item.id
                    });
                }
            }
        }

        const onClickSaveFavorit = () => {
            axios.post('/favorit/regist', {
                list: state.selectedFavorit
            }).then((res) => {
                if(res.data.result) {
                    state.list = [...state.selectedFavorit];
                    alert('나의 취향을 저장했습니다.');
                    state.showModify = false;
                }
            }).catch(() => alert('나의 취향을 저장하지 못했습니다.'));
        }

        return {
            state,
            store,
            initPage,
            onClickModify,
            onSelectFavorit,
            onClickSaveFavorit,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 160px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 32px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .favorit-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        width: 100%;
        margin-top: 30px;
        overflow: auto;
        white-space: nowrap;
    }

    .favorit-list::-webkit-scrollbar {
        display: none;
    }

    .favorit-list > li {
        background-color: #F5F7F9;
        position: relative;
        border-radius: 10px;
        display: flex;
        margin-right: 10px;
    }

    .favorit-item {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 5px 15px 7px 15px;
        display: flex;
    }

    .favorit-item > img {
        width: 22px;
        margin-top: -2px;
    }

    .favorit-item > label {
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-top: 5px;
        margin-left: 6px;
    }

    .btn-modify {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: #A5B5BB;
    }

    .modify-full {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,64,128,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modify-modal {
        position: fixed;
        z-index: 1000;
        width: 95%;
        height: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px 20px;
        text-align: left;
    }

    .modify-modal > h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        color: #69757F;
    }

    .modify-modal > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .modify-modal > ul > li {
        width: 100px;
        height: 70px;
        border-radius: 20px;
        background-color: #EFF2F3;
        color: black;
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        margin-bottom: 10px;
    }
    
    .modify-modal > ul > li > img {
        width: 32px;
    }

    .modify-modal > ul > li > label {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    .modify-modal > .button-section {
        position: absolute;
        bottom: 40px;
        right: 35px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
    }

    .modify-modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 20px;
        padding: 10px 40px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modify-modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-right: 20px;
    }

    .active {
        background-color: #48B9FF !important;
        color: white !important;
        background-image: url(~@/assets/checked.png);
        background-repeat: no-repeat;
        background-position: 105px 15px;
    }

    .modify-modal > .title-section {
        display: block;
        justify-content: space-between;
        margin-top: -30px;
    }

    .modify-modal > .title-section > h1 {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
    }


    .modify-modal > .title-section > h2 {
        display: block;
        margin-top: auto;
        margin-bottom: 10px;
        color: #48B9FF;
        font-size: 16px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 220px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 32px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .favorit-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        width: 100%;
        margin-top: 30px;
        overflow: auto;
        white-space: nowrap;
    }

    .favorit-list > li {
        width: 200px;
        padding-bottom: 14%;
        background-color: #F5F7F9;
        margin-right: 10px;
        margin-bottom: 5.5%;
        position: relative;
        border-radius: 10px;
        max-height: 97px;
    }

    .favorit-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
        max-height: 130px;
    }

    .favorit-item > img {
        width: 38px;
    }

    .favorit-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: block;
        font-size: 18px;
        font-weight: 500;
    }

    .btn-modify {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: #A5B5BB;
    }

    .modify-full {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,64,128,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modify-modal {
        position: fixed;
        z-index: 1000;
        width: 750px;
        height: 850px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: left;
    }

    .modify-modal > h1 {
        margin: 0;
        font-size: 32px;
        font-weight: 500;
        color: #69757F;
    }

    .modify-modal > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .modify-modal > ul > li {
        width: 150px;
        height: 120px;
        border-radius: 20px;
        background-color: #EFF2F3;
        color: black;
        font-size: 20px;
        font-weight: 500;
        padding: 20px;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        margin-bottom: 30px;
    }
    
    .modify-modal > ul > li > img {
        width: auto;
        max-width: 69px;
        min-height: 50px;
        max-height: 50px;
    }

    .modify-modal > ul > li > label {
        display: block;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .modify-modal > .button-section {
        position: absolute;
        bottom: 40px;
        right: 35px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
    }

    .modify-modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 20px;
        padding: 10px 40px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modify-modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-right: 20px;
    }

    .active {
        background-color: #48B9FF !important;
        color: white !important;
        background-image: url(~@/assets/checked.png);
        background-repeat: no-repeat;
        background-position: 105px 15px;
    }

    .modify-modal > .title-section {
        display: flex;
        justify-content: space-between;
        margin-top: -30px;
    }

    .modify-modal > .title-section > h1 {
        display: block;
        margin-bottom: 10px;
    }

    .modify-modal > .title-section > h2 {
        display: block;
        margin-top: auto;
        margin-bottom: 10px;
        color: #48B9FF;
    }
}
</style>