<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mylamp.png">
            <label>{{ store.getters.isEnglish ? 'My Friends' : '나의 친구' }}</label>
        </div>

        <button v-if="!store.getters.isVisitMode" style="margin-right: 100px;" class="btn-more" @click="onClickShowGuide">{{ store.getters.isEnglish ? '+ Add by name' : '+ 이름으로 추가' }}</button>
        <button v-if="!store.getters.isVisitMode" class="btn-more" @click="onClickMore">{{ store.getters.isEnglish ? '+ more' : '+ 더보기' }}</button>

        <ul class="friend-list">
            <li v-for="(item, index) in state.list" :key="index" @click="onGoFriend(item)">
                <div class="friend-item">
                    <img :src="item.profileUrl == '' ? '/img/mypage/dummy.png' : item.profileUrl">
                    <label>{{ item.name }}</label>
                </div>
            </li>
        </ul>

        <div v-if="state.showGuide" class="modalFull" @click="onClickCloseNotice">
            <div class="modal" @click="onClickModal">
                <div class="input-box">
                    <input type="text" :value="state.findName" @input="onInputReply" @keypress.enter="onClickFindName">
                    <button @click="onClickFindName">{{ store.getters.isEnglish ? 'Find' : '이름찾기'}}</button>
                </div>
                <ul class="reply-box">
                    <li v-for="(item, index) in state.findList" :key="index">
                        <img :src="item.profileUrl == '' ? '/img/mypage/dummy.png' : item.profileUrl" />
                        <div class="reply-content">
                            <p>{{ item.name }}</p>
                            <button @click="onClickAdd(item)">{{ store.getters.isEnglish ? '+ add' : '+ 친구추가' }}</button>
                        </div>
                    </li>
                </ul>
            </div>
            <button @click="onClickCloseNotice">X</button>
        </div>
    </div>
</template>

<script>
import { reactive, watch, getCurrentInstance } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'FriendBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const vueInstance = getCurrentInstance();
        const emitter = vueInstance.appContext.config.globalProperties.emitter;
        const store = useStore();
        const state = reactive({
            list: [],
            showGuide: false,
            findName: '',
            findList: [],
        })

        watch(() => store.getters.getUserObject, () => initPage() );

        const initPage = () => {
            emitter.on('addFriend', friend => {
                const exist = state.list.find((v) => v.userId == friend.userId);
                if(!exist) {
                    state.list.push(friend);
                }
            })
            axios.get('/friend/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onClickMore = () => {
            emit('more');
        }

        const onGoFriend = (user) => {
            axios.post('/history', {
                friendId: user.friendId,
            }).then(() => {
                store.commit('setVisitObject', {
                    id: user.friendId,
                    name: user.name,
                    profileUrl: user.profileUrl,
                });
            })
        }
        const onClickShowGuide = () => {
            state.findName = '';
            state.findList = [];
            state.showGuide = true;
        }

        const onClickCloseNotice = () => {
            state.showGuide = false;
        }

        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onInputReply = (e) => {
            state.findName = e.target.value;
        }

        const onClickFindName = () => {
            axios.post('/user/find', {
                name: state.findName,
            })
            .then((res) => {
                if(res.data.result) {
                    state.findList = res.data.data;
                }
            })
            .catch(() => {
                alert("친구를 찾을 수 없습니다.\nNot Found");
            }) 
        }

        const onClickAdd = (friend) => {
            axios.post('/friend/add', {
                friendId: friend.id,
            }).then((res) => {
                if(res.data.result) {
                    /*try {
                        emitter.emit('addFriend', friend);
                    }
                    catch(e) {
                        console.log(e);
                    }*/
                    
                    state.list.push({
                        userId: friend.id,
                        friendId: friend.id,
                        name: friend.name,
                        profileUrl: friend.profileUrl,
                    });
                    state.showGuide = false;
                    alert('친구로 등록했습니다.');
                } else {
                    alert('친구등록 실패')
                }
            }).catch(() => alert('친구등록 실패??'));
        }

        return {
            state,
            store,
            initPage,
            onClickMore,
            onGoFriend,
            onClickShowGuide,
            onClickCloseNotice,
            onClickModal,
            onInputReply,
            onClickFindName,
            onClickAdd,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 18px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .friend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .friend-list > li {
        width: 29.5%;
        padding-bottom: 29.5%;
        background-color: #F5F7F9;
        margin-right: 5.5%;
        margin-bottom: 5.5%;
        position: relative;
        border-radius: 10px;
    }

    .friend-list > li:nth-child(3n) {
        margin-right: 0;
    }

    .friend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
    }

    .friend-item > img {
        width: 40px;
    }

    .friend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: #A5B5BB;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        min-height: 450px;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        background-image: url(~@/assets/mypage/friendgirl.png);
        background-repeat: no-repeat;
        background-position: 30px 100%;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 18px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .friend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 280px;
    }

    .friend-list > li {
        width: 125px;
        height: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        background-color: #F5F7F9;
        position: relative;
        border-radius: 10px;
    }

    .friend-list > li:nth-child(3n) {
        margin-right: 0;
    }

    .friend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
    }

    .friend-item > img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .friend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: #A5B5BB;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 650px;
        height: 550px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;

        text-align: center;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }

    

    .modal > .input-box {
        margin-top: 30px;
    }

    .modal > .input-box > input {
        width: 400px;
        font-size: 20px;
        padding: 7px;
        border-radius: 10px;
        border: 1px solid #84CAFF;
    }

    .modal > .input-box > input:focus {
        outline: none;
    }

    .modal > .input-box > button {
        border: none;
        background-color: #84CAFF;
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        border-radius: 10px;
        margin-left: 10px;
    }

    .modal > .reply-box {
        list-style: none;
        margin: 30px 50px;
        padding: 0;
        height: 70%;
        white-space: nowrap;
        overflow: auto;
        width: 80%;
    }

    .reply-box > li {
        display: flex;
        margin-top: 30px;
    }

    .reply-box > li:first-child {
        margin-top: 0;
    }

    .reply-box > li > img {
        width: 48px;
        height: 48px;
        border-radius: 64px;
    }

    .reply-box > li > .reply-content {
        text-align: left;
        margin-left: 20px;
        position: relative;
        width: 100%;
    }

    .reply-box > li > .reply-content > p {
        margin:0;
        color: #383838;
        font-size: 20px;
        margin-top: 10px;
    }

    .reply-box > li > .reply-content > label {
        margin-top: 6px;
        display: block;
        font-size: 12px;
        color: #acacac;
    }

    .reply-content > button {
        border:none;
        background-color: #84CAFF;
        color: white;
        font-size: 14px;
        font-weight: 500;
        border-radius: 10px;
        padding: 7px 10px 5px 10px;
        position: absolute;
        right: 0;
        top: 10px;
        width: 90px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}
</style>