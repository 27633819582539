<template>
    <div class="body-section">
        <div class="top-image">
            <img src="/img/login_top.png">
        </div>
        <div class="bottom-image">
            <img src="/img/main_bottom.png">
        </div>
        <div class="login-section">
            <ul class="top-buttons">
                <li style="margin-right: 2vw;">
                    <img @click="onSwitchLanguage" :src="store.getters.isEnglish ? '/img/english.png' : '/img/korean.png'">
                </li>
                <li>
                    <img @click="onShowNotice" :src="store.getters.isEnglish ? '/img/notice_eng.png' : '/img/notice.png'">
                </li>
            </ul>

            <h1>Login</h1>

            <div class="login-form">
                <div class="input-section">
                    <label for="email">Email</label>
                    <input type="email" id="email" v-model="state.userId">
                </div>
                <div class="input-section second">
                    <label for="password">Password</label>
                    <input type="password" id="password" v-model="state.userPwd">
                </div>
                <div class="remember-section">
                    <input type="checkbox" id="rememberme" v-model="state.rememberMe">
                    <label for="rememberme">Remember me</label>
                </div>
            </div>

            <button class="login-button" @click="onLogin">Login</button>

            <div class="host-section">
                <img src="/img/host_logo.png">
            </div>
        </div>

        <div v-if="state.showNotice" class="modalFull" @click="onClickCloseNotice">
            <div class="modal" @click="onClickModal">
                <ul>
                    <li v-for="(item, index) in state.list" :key="index" @click="onToggenItem(index)">
                        <h1>{{ store.getters.isEnglish ? item.engSubject : item.korSubject }}</h1>
                        <p v-if="index == state.currentIndex" :innerHTML="store.getters.isEnglish ? state.currentItem?.engContent || '' : state.currentItem?.korContent || ''"></p>
                    </li>
                </ul>
            </div>
            <button @click="onClickCloseNotice">X</button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { reactive } from 'vue';
import axios from 'axios'

export default {
    name: 'LoginPage',
    mounted() {
        const store = useStore();
        this.state.userId = store.getters.getRememberId;
        this.state.rememberMe = this.state.userId ? true : false;
    },
    setup() {
        const store = useStore();
        const route = useRouter();
        const state = reactive({
            userId: '',
            userPwd: '',
            showNotice: false,
            currentIndex: -1,
            currentItem: null,
            rememberMe: false,
        })

        const onLogin = () => {
            if(state.rememberMe) {
                store.commit('setRememberId', state.userId);
            } else {
                store.dispatch('clearMe');
            }
            axios.post('/user/login', {
                email: state.userId,
                password: state.userPwd,
            }).then((res) => {
                if(res.data?.result || false || res.data?.code == 200) {
                    store.commit('setUserObject', {
                        id:res.data.data.id,
                        name:res.data.data.name,
                        saftyAgree:res.data.data.saftyAgree,
                        profileUrl: res.data.data.profileUrl,
                        foreigner: res.data.data.foreigner,
                        role: res.data.data.role,
                    })
                    route.push('/');
                } else {
                    alert(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                alert('계정이 없거나 정보가 맞지 않아 로그인 하지 못했습니다.');
            })
        }

        const onShowNotice = () => {
            state.showNotice = true;
            axios.get('/notice/list')
            .then((res) => {
                if(res.data.result) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onClickCloseNotice = () => {
            state.showNotice = false;
        }

        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onToggenItem = (idx) => {
            if(state.currentIndex == idx) {
                state.currentIndex = -1;
                state.currentItem = null;
            } else {
                state.currentIndex = idx;

                axios.get('/notice/detail/' + state.list[idx].id.toString())
                .then((res) => {
                    if(res.data.result) {
                        state.currentItem = res.data.data;
                        console.log(state.currentItem);
                    } else {
                        state.currentItem = null;
                    }
                })
            }
        }

        const onSwitchLanguage = () => {
            store.commit('setEnglishMode', store.getters.isEnglish ? 0 : 1);
        }

        return {
            onLogin,
            store,
            state,
            onSwitchLanguage,
            onShowNotice,
            onClickCloseNotice,
            onClickModal,
            onToggenItem,
        }
    }
}
</script>

<style scoped>
.body-section {
    position: relative;
    background-color: #48B9FF;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .top-image {
        width: 100%;
    }

    .top-image > img {
        width: 100%;
    }

    .bottom-image {
        position: absolute;
        width: 100%;
        bottom: -5px;
    }

    .bottom-image > img {
        width: 100%;
    }

    .top-buttons {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
    }

    .top-buttons > li > img {
        height: 6vw;
    }

    .login-section {
        position: absolute;
        z-index: 100;
        top: 50vh;
        background-color: rgba(255,255,255,0.3);
        backdrop-filter: blur(3px);
        height: 112vw;
        width: 80vw;
        left: 50%;
        transform: translate(-50%, -40%);
        border-radius: 5vw;
        box-sizing: border-box;
        padding: 5vw;
    }

    .login-section > h1 {
        width: 100%;
        text-align: center;
        display: block;
        margin: 0;
        padding: 0;
        font-size: 7vw;
        margin-top: 7vw;
        color: #231F20;
    }

    .login-section > .login-form {
        margin-top: 2.5vw;
        width: 80%;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .login-form > .input-section > label {
        display: block;
        color: #8E9192;
        font-size: 3vw;
    }

    .login-form > .input-section > input[type="email"],
    .login-form > .input-section > input[type="password"] {
        background-color: #F0F3F6;
        box-sizing: border-box;
        padding: 3vw 3vw;
        border-radius: 3vw;
        color: #231F20;
        border: none;
        width: 100%;
        margin-top:1vw;
    }

    .login-form > .input-section > input:focus {
        outline: none;
    }

    .login-form > .second {
        margin-top: 3vw;
    }

    .login-form > .remember-section {
        margin-top: 1vw;
        color: #58595B;
        font-size: 12px;
        display: flex;
    }

    .login-form > .remember-section > label {
        display: block;
        margin-top: 0.5vw;
    }

    .login-section > .login-button {
        background-color: #0C8195;
        border: none;
        border-radius: 3vw;
        color: white;
        width: 80%;
        height: 10vw;
        margin-left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        margin-top: 5vw;
    }

    .login-section > .host-section {
        width: 100%;
        margin-top: 7vw;
        text-align: center;
    }
    .login-section > .host-section > img {
        height: 5vw;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 90%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;
        text-align: center;

        overflow: auto;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }
}

@media (min-width:768px)
{
    .body-section {
        min-height: 960px;
        width: 1600px;
        margin: auto;
    }

    .top-image {
        width: 50%;
    }

    .top-image > img {
        width: 100%;
    }

    .bottom-image {
        width: 50%;
        position: absolute;
        bottom: -5px;
    }

    .bottom-image > img {
        width: 100%;
    }

    .top-buttons {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
    }

    .top-buttons > li > img {
        height: 48px;
    }

    .login-section  {
        position: absolute;
        top: 10px;
        margin-left: 50%;
        width: 49%;
        height: 100%;
        margin-top: 0;
        background-color: white;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 60px;
    }

    .login-section > h1 {
        width: 100%;
        text-align: center;
        font-size: 68px;
        margin-top: 60px;
    }

    .login-section > .login-form {
        margin-top: 3vh;
        width: 80%;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .login-form > .input-section > label {
        display: block;
        color: #8E9192;
        font-size: 20px;
        font-weight: 500;
    }

    .login-form > .input-section > input[type="email"],
    .login-form > .input-section > input[type="password"] {
        background-color: #F0F3F6;
        box-sizing: border-box;
        padding: 7px 30px;
        border-radius: 20px;
        height: 68px;
        color: #231F20;
        border: none;
        width: 100%;
        margin-top:10px;
        font-size: 24px;
    }

    .login-form > .input-section > input:focus {
        outline: none;
    }

    .login-form > .second {
        margin-top: 30px;
    }

    .login-form > .remember-section {
        margin-top: 1vw;
        color: #58595B;
        font-size: 18px;
        display: flex;
    }

    .login-form > .remember-section > label {
        display: block;
        margin-top: -10;
        margin-left: 3px;
    }

    .login-section > .login-button {
        background-color: #0C8195;
        border: none;
        border-radius: 20px;
        color: white;
        width: 80%;
        height: 74px;
        margin-left: 50%;
        transform: translateX(-50%);
        font-size: 28px;
        font-weight: 700;
        margin-top: 5vw;
    }

    .login-section > .host-section {
        width: 100%;
        margin-top: 7vw;
        text-align: center;
    }
    .login-section > .host-section > img {
        height: 36px;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 1000px;
        height: 620px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;

        overflow: auto;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }
}

</style>