<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/myshoes.png">
            <label>{{ store.getters.isEnglish ? 'My Favorit' : '나의 취향' }}</label>
        </div>
        <ul class="favorit-list">
            <li v-for="(item, index) in state.list" :key="index">
                <div class="favorit-item">
                    <img :src='`/img/mypage/favorit/` + state.favoritItems[item.favoritId].img'>
                    <label>{{ store.getters.isEnglish ? state.favoritItems[item.favoritId].eng : state.favoritItems[item.favoritId].kor }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';
import favoritData from '../FavoritData';

export default {
    name: 'FavoritBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list: [],
            favoritItems: favoritData,
        })

        const initPage = () => {
            axios.get('/favorit/list')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                }
            })
        }

        return {
            store,
            state,
            initPage,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 160px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 32px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .favorit-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        width: 100%;
        margin-top: 30px;
        overflow: auto;
        white-space: nowrap;
    }

    .favorit-list::-webkit-scrollbar {
        display: none;
    }

    .favorit-list > li {
        background-color: #F5F7F9;
        position: relative;
        border-radius: 10px;
        display: flex;
        margin-right: 10px;
    }

    .favorit-item {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 5px 15px 7px 15px;
        display: flex;
    }

    .favorit-item > img {
        width: 22px;
        margin-top: -2px;
    }

    .favorit-item > label {
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-top: 5px;
        margin-left: 6px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        background-image: url(~@/assets/mypage/favoritgirl.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 40px) 100%;
        min-height: 430px;
        max-height: 430px;
    }

    .title-section {
        display: flex;
    }

    .title-section > label {
        font-size: 24px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .favorit-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        width: 100%;
        margin-top: 30px;
    }

    .favorit-list > li {
        width: 29.5%;
        padding-bottom: 22.5%;
        background-color: #F5F7F9;
        margin-right: 5.5%;
        margin-bottom: 5.5%;
        position: relative;
        border-radius: 10px;
        max-height: 97px;
    }

    .favorit-list > li:nth-child(3n) {
        margin-right: 0;
    }

    .favorit-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
        max-height: 130px;
    }

    .favorit-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        display: block;
        font-size: 20px;
        font-weight: 500;
    }
}
</style>