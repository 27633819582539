<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mylamp.png">
            <label>{{ store.getters.isEnglish ? 'Mission Cetification' : '미션 인증' }}</label>
            <div class="filter-box">
                <select v-model="state.date" @change="onChangeDate">
                    <option value="1002">10월 2일</option>
                    <option value="1003">10월 3일</option>
                    <option value="1004">10월 4일</option>
                    <option value="1005">10월 5일</option>
                    <option value="1006">10월 6일</option>
                </select>

                <select v-model="state.selectedMissionId" @change="onChangeMission">
                    <option v-for="(item, index) in state.missionItems.filter((v) => v.date == state.date)" :key="index" :value="item.id">{{ item.kor.replace("<br />", " ") }}</option>
                </select>
            </div>
        </div>

        <!--div class="date-section">
            <ul>
                <li :class="{'dateActive': state.selected == 0}" @click="onClickDate(0)">10/2 월</li>
                <li :class="{'dateActive': state.selected == 1}" @click="onClickDate(1)">10/3 화</li>
                <li :class="{'dateActive': state.selected == 2}" @click="onClickDate(2)">10/4 수</li>
                <li :class="{'dateActive': state.selected == 3}" @click="onClickDate(3)">10/5 목</li>
            </ul>
        </div-->

        <ul class="stamp-list">
            <li v-for="(item, index) in state.list" :key="index">
                <div class="stamp-item">
                    <div class="image-box" @click="onShowDetail(item)">
                        <img v-if="getExtType(item.fileUrl) == 'image'" :src="item.fileUrl" />
                        <video v-else-if="getExtType(item.fileUrl) == 'video'" :src="item.fileUrl" />
                        <div v-else>
                            <a style="display:block;margin-top: 50px;" download :href="item.fileUrl">DOWNLOAD</a>
                            <a style="display:block;margin-top: 20px;" @click="onShowDetail(item)">상세보기</a>
                        </div>                    
                    </div>
                    <label :innerHTML="getMissionTitle(item.missionId)"></label>
                </div>
                <div class="button-section">
                    <button class="ok" @click="onOk(item)">승인</button>
                    <button class="cancel" @click="onCancel(item)">반려</button>
                </div>
            </li>
        </ul>

        <div v-if="state.selectedItem" class="modalFull" @click="onClickClosModal">
            <div class="modal" @click="onClickModal">
                <div class="image-box">
                    <img v-if="getExtType(state.selectedItem.fileUrl) == 'image'" :src="state.selectedItem.fileUrl" />
                    <video v-else-if="getExtType(state.selectedItem.fileUrl) == 'video'" :src="state.selectedItem.fileUrl" controls />
                    <a v-else download :href="state.selectedItem.fileUrl">DOWNLOAD</a>
                </div>
                <div class="user-section">
                    <img :src="state.selectedItem.user.profileUrl ? state.selectedItem.user.profileUrl : '/img/mypage/dummy.png'" />
                    <div class="content-box">
                        <label>미&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;션&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;: {{ state.selectedItem.missionName }}</label>
                        <label>참가자 이&nbsp;&nbsp;&nbsp;름 : {{ state.selectedItem.user.name }}</label>
                        <label>참가자 이메일 : {{ state.selectedItem.user.email }}</label>
                    </div>
                </div>
                <div class="button-section">
                    <button class="ok" @click="onOk(state.selectedItem)">승인</button>
                    <button class="cancel" @click="onCancel(state.selectedItem)">반려</button>
                    <button class="cancel" @click="onClickClosModal">닫기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from 'axios'
import missionData from '../MissionData';
import { useStore } from 'vuex';

export default {
    name: 'CertifiedBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            selected: 0,
            missionItems: missionData,
            offset: 0,
            limit: 10,
            count: 0,
            selectedItem: null,
            date: '1002',
            selectedMissionId: '11',
        });
        const detail = ref();
        const onClickDate = (select) => {
            state.selected = select;
        };
        const getExtType = (url) => {
            const idx = url.lastIndexOf('.');
            const ext = url.substring(idx+1).toLowerCase();
            console.log(ext);

            if(ext == 'mov' || ext == 'mp4') {
                return 'video';
            }

            if(ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'gif') {
                return 'image';
            }

            return 'other';
        }
        const getMission = (id) => {
            return state.missionItems.find((v) => v.id == id);
        };
        const getMissionTitle = (id) => {
            const mission = getMission(id);
            if (!mission)
                return '';
            return store.getters.isEnglish ? mission.eng : mission.kor;
        };
        const initPage = () => {
            axios.get(`/mission/all/${state.offset}/${state.limit}/${state.selectedMissionId}`)
                .then((res) => {
                if (res.data?.result || false) {
                    state.list = res.data.data.list;
                    state.count = res.data.data.count;
                } else {
                    state.list = [];
                    state.count = 0;
                    state.offset = 0;
                }
            });
        };
        const onShowDetail = function (item) {
            state.selectedItem = item;
        };
        const onOk = function (item) {
            if(confirm('승인처리 하시겠습니까?')) {
                axios.patch(`/mission/certified/${item.id}/1`)
                .then((res) => {
                    if(res.data.result) {
                        state.selectedItem = null;
                        alert("승인을 처리했습니다.");
                        initPage();
                    } else {
                        alert("처리를 실패했습니다.");
                    }
                })
                .catch(() => {
                    alert("서버에 이상이 있습니다.");
                })
            }
            
        }
        const onCancel = function (item) {
            if(confirm('반려처리 하시겠습니까?\n반려 처리 후 복원이 불가합니다.')) {
                axios.patch(`/mission/certified/${item.id}/-1`)
                .then((res) => {
                    if(res.data.result) {
                        state.selectedItem = null;
                        alert("반려 처리했습니다.");
                        initPage();
                    } else {
                        alert("처리를 실패했습니다.");
                    }
                })
                .catch(() => {
                    alert("서버에 이상이 있습니다.");
                })
            }
        }
        const onClickClosModal = (e) => {
            e.stopPropagation();
            state.selectedItem = null;
        }
        const onClickModal = (e) => {
            e.stopPropagation();
        }
        const onChangeDate = () => {
            const item = state.missionItems.filter((v) => v.date == state.date)[0];
            state.selectedMissionId = item.id;
            initPage();
        }
        const onChangeMission = () => {
            initPage();
        }
        return {
            store,
            state,
            detail,
            onClickDate,
            initPage,
            getMission,
            getMissionTitle,
            onShowDetail,
            onOk,
            onCancel,
            onClickClosModal,
            onClickModal,
            onChangeDate,
            onChangeMission,
            getExtType,
        };
    },
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        margin-top: 40px;
    }

    .title-section {
        display: none;
    }

    .date-section {
        
    }

    .date-section > ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        width: 100%;
        white-space: nowrap;
        overflow: auto;
    }

    .date-section > ul::-webkit-scrollbar {
        display: none;
    }

    .date-section > ul > li {
        background-color: #F5F7F9;
        padding: 8px 10px;
        border-radius: 10px;
        font-size: 16px;
        color: #69828C;
        margin-right: 10px;
        font-size: 12px;
        
    }

    .dateActive {
        background-color: #84CAFF !important;
        color: white !important;
    }

    .stamp-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 40px;
        overflow: auto;
        width: 100%;
        justify-content: space-between;
    }

    .stamp-list > li {
        width: 110px;
        height: 160px;
        margin:auto;
        background-color: #F5F7F9;
        position: relative;
        margin-bottom: 30px;
        border-radius: 15px;
        box-shadow: 4.8px 4.8px 15.8px rgba(65,91,114,0.2);
    }

    .stamp-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .stamp-item > img {
        width: 60px;
    }

    .stamp-item > label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        overflow: auto;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 18px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .date-section {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .date-section > ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
    }

    .date-section > ul > li {
        background-color: #F5F7F9;
        padding: 8px 15px;
        border-radius: 10px;
        font-size: 16px;
        color: #69828C;
        margin-left: 20px;
    }

    .dateActive {
        background-color: #84CAFF !important;
        color: white !important;
    }

    .stamp-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        overflow: auto;
        width: 75%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .stamp-list > li {
        width: 180px;
        height: 260px;
        background-color: #F5F7F9;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 15px;
        box-shadow: 10px 10px 15px rgba(65,91,114,0.2);
        overflow: hidden;
        margin-top: 5px;
    }

    .stamp-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .stamp-item > .image-box {
        max-height: 150px;
        width: 100%;
        overflow: hidden;
    }
    .stamp-item > .image-box > img {
        height: 150px;
        object-fit: cover;
    }

    .stamp-item > .image-box > video {
        height: 150px;
        object-fit: cover;
    }

    .stamp-item > label {
        display: block;
        font-size: 18px;
        font-weight: 500;
        margin-top: 0px;
    }

    .stamp-list > li > .button-section {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }

    .stamp-list > li > .button-section > button {
        border: none;
        background-color: #84CAFF;
        color: white;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 700;
    }

    .stamp-list > li > .button-section > .ok {
        margin-right: 10px;
    }

    .stamp-list > li > .button-section > .cancel {
        background-color: #96bac8;
    }

    .pagination {
        list-style: none;
        display: flex;
        justify-content: space-between;
        width: 300px;
    }

    .pagination > li > a {
        background-color: red;
    }

    .active {
        background-color: red;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 950px;
        height: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
    }

    .modal > .button-section {
        position: absolute;
        bottom: 60px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        width: 80%;
        text-align: center;
        display: flex;
        justify-items: center;
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 170px;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        display: block;
        margin:auto;
    }

    .modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-left: 20px;
    }

    .image-box {
        width: 100%;
        height: 500px;
        margin: auto;
        position: relative;
        overflow: auto;
    }
    .modal > .image-box > img {
        width: 100%;
        top: 50%;
        left:0;
        transform: translateY(-50%);
        position: absolute;
        object-fit: cover;
    }

    .modal > .image-box > video {
        width: 100%;
        top: 50%;
        left:0;
        transform: translateY(-50%);
        position: absolute;
        object-fit: cover;
    }

    .modal > .image-box:after{display:inline-block; height:100%; content:""; vertical-align:middle;}

    .modal > p {
        font-size: 28px;
        font-weight: 700;
    }

    .modal > .user-section {
        display: flex;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .modal > .user-section > img {
        width: 72px;
        height: 72px;
        overflow: hidden;
        border-radius: 72px;
        margin-top: 10px;
    }

    .modal > .user-section > .content-box {
        margin-left: 20px;
        font-size: 24px;
    }

    .modal > .user-section > .content-box > label {
        display: block;
    }

    .title-section > .filter-box {
        margin-left: 35%;
    }

    .filter-box > select {
        padding: 10px 20px;
        font-size: 20px;
        margin-right: 10px;
        border-radius: 15px;
    }
}
</style>