<template>
    <div class="body-section">
        <MissionBlock />
        <StampBlock />
    </div>
</template>

<script>
import MissionBlock from './MisstionBlock/MissionBlock.vue';
import StampBlock from './MisstionBlock/StampBlock.vue';

export default {
    name: 'MissionComponent',
    setup() {
    },
    components: { MissionBlock, StampBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .body-section {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

@media (min-width:768px) {
    .body-section {
        width: 100%;
        margin-left: 40px;
    }
}

</style>