<template>
    <div v-if="store.getters.getUserObject.foreigner != 1 && store.getters.getOriginalUser?.foreigner != 1" class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>{{ store.getters.isEnglish ? 'World Issue Activity' : '세계이슈활동' }}</label>
        </div>

        <button class="btn-more" @click="onClickMore">{{ store.getters.isEnglish ? '+ more' : '+ 더보기' }}</button>

        <div class="total-section">
            <div class="title" :innerHTML="store.getters.isEnglish ? 'My<br />Activity Patch' : '나의<br />활동 패치'">
            </div>
            <div class="value">
                {{ state.list.length }}
            </div>
        </div>
    </div>
    <div v-else class="block-section"></div>
</template>

<script>
import { reactive, watch } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'WorldBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const store = useStore();
        const state = reactive({
            list: [],
        })

        watch(() => store.getters.getUserObject, () => initPage() );

        const initPage = () => {
            axios.get('/worldissue/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onClickMore = () => {
            emit('more');
        }

        return {
            state,
            store,
            initPage,
            onClickMore,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #F5F7F9;
        border-radius: 10px;
        margin-top: 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: #A5B5BB;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 30px;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-top: 30px;
    }

    .total-section > .title {
        width: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 50%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: #A5B5BB;
    }
}
</style>