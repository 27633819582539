<template>
    <div class="body-section">
        <TopMenuComponent />

        <div class="title-section">
            <img src="/img/main_title.png">
        </div>

        <MainVideo />
        <OnlineMission @click="onClickMission"/>
        <WorldISsueComponent v-if="store.getters.getUserObject?.foreigner != 1" />
        <OtherHompage />
        <MissionComplete />
        <Sponsor />

        <!--div v-if="!state.isCheckedIn" class="checkinquiz-section" @click="state.showCheckinQuiz = true">
            <img src="/img/checkinquiz.png" />
        </div-->
        
        <div class="bottom-section">
            <img src="/img/main_bottom.png">
        </div>

        <div v-if="state.showCheckinQuiz" class="checkinModalFull" @click="onClickCloseCheckin">
            <div class="checkinModal" @click="onClickModal">
                <img src="/img/sun.png" />
                <h2 :innerHTML='store.getters.isEnglish ? state.todayQuiz.engTitle : state.todayQuiz.korTitle'></h2>

                <h1 :innerHTML='store.getters.isEnglish ? state.todayQuiz.engQuiz : state.todayQuiz.korQuiz'></h1>

                <div class="answer" v-if="state.date == '1002'">
                    <span>T</span>
                    <input type="text" size="5" v-model="state.answer1" placeholder="_____" />
                    <span> in the N</span>
                    <input type="text" size="5" v-model="state.answer2" placeholder="_____"/>
                    <span>!</span>
                </div>
                <div class="answer" v-else-if="state.date == '1004'">
                    <span>{{ store.getters.isEnglish ? 'October' : '10월' }} </span>
                    <input type="text" size="1" v-model="state.answer1" placeholder="_" />
                    <span>{{ store.getters.isEnglish ? 'th' : '일' }}</span>
                </div>
                <div class="answer" v-else>
                    <input type="text" size="3" v-model="state.answer1" />
                </div>

                <div class="button-section">
                    <button class="share" @click="onClickAnswer">{{ store.getters.isEnglish ? 'Answer' : '답변제출' }}</button>
                </div>
            </div>
        </div>

        <div v-if="state.selectedItem" class="modalFull" @click="onClickClosModal">
            <div class="modal" @click="onClickModal">
                <div class="image-box">
                    <img :src="'/img/mypage/mission/' + state.selectedItem.img">
                </div>
                <h1 :innerHTML="store.getters.isEnglish ? state.selectedItem.eng : state.selectedItem.kor"></h1>
                <p :innerHTML="store.getters.isEnglish ? state.selectedItem.endDesc : state.selectedItem.korDesc"></p>
                <div class="button-section">
                    <button class="go" @click="onClickGo">{{ store.getters.isEnglish ? 'Mission Challenge' : '미션 참여하기' }}</button>
                    <button v-if="state.selectedItem.autocerti != true" class="share" @click="onClickCerti">{{ store.getters.isEnglish ? 'Certification' : '미션완료 인증하기' }}</button>
                </div>
                <input type="file" accept="image/jpg,image/jpeg,image/png,video/mp4,video/mov" ref="certiFile" id="certiFile" style="visibility: hidden;" @change="onChangeFile" />
            </div>
        </div>

        <div v-if="store.getters.getUserObject?.saftyAgree === 0 && state.denyAgree == false" class="agreeModalFull">
            <div class="agreeModal">
                <label>{{store.getters.isEnglish ? 'Guide' : '가이드'}}</label>
                <p class="message-section" :innerHTML="store.getters.isEnglish ? state.agreeItems[state.agreeIndex].eng : state.agreeItems[state.agreeIndex].kor"></p>
                <div v-if="state.agreeItems[state.agreeIndex].yesno == false" class="continue" @click="onClickContinue">
                    <img src="/img/continuebtn.png">
                </div>
                <div v-else class="continue">
                    <button class="say-yes" @click="onClickYes">{{ store.getters.isEnglish ? 'Yes' : '예' }}</button>
                    <button class="say-no" @click="onClickNo">{{ store.getters.isEnglish ? 'No' : '아니오' }}</button>
                </div>
            </div>
        </div>

        <div v-if="state.denyAgree" class="denyModalFull">
            <div class="denyModal">
                <div class="warning-icon">
                    <img src="/img/warning.png">
                </div>
                <h1>Warning!</h1>
                <p :innerHTML = "store.getters.isEnglish ? 'You have to agree to the activity safety guidelines<br />You can participate in the online international camping' : '활동안전지침에 동의하셔야<br /> 온라인 국제야영에 참가 가능합니다'"></p>
                <button @click="state.denyAgree = false">CLOSE</button>
            </div>
        </div>

        <div v-if="state.surveyMode" class="surveyModalFull" @click="onClickCloseSurvey">
            <div class="surveyModal" @click="onClickModal">
                <h1>{{store.getters.isEnglish ? '19th Girl Scouts International Camp Survey (on-line activity)' : '제19회 걸스카우트 국제야영(온라인활동) 설문조사'}}</h1>
                
                <h2>1. {{ enkor('Category', '구분') }}</h2>
                <div class="answer-section">
                    <div class="answer-box">
                        <input type="radio" v-model="state.ans1" value="대원∙청소년">
                        <label>{{enkor('Member·Teenager','대원∙청소년')}}</label>
                    </div>
                    <div class="answer-box">
                        <input type="radio" v-model="state.ans1" value="지도자∙성인">
                        <label>{{enkor('Leader·Adult','지도자∙성인')}}</label>
                    </div>
                </div>
                <h2>2. {{ enkor('Gender', '성별') }}</h2>
                <div class="answer-section">
                    <div class="answer-box">
                        <input type="radio" v-model="state.ans2" value="여">
                        <label>{{enkor('Female','여')}}</label>
                    </div>
                    <div class="answer-box">
                        <input type="radio" v-model="state.ans2" value="남">
                        <label>{{enkor('Male','남')}}</label>
                    </div>
                </div>
                <h2>3. {{ enkor('Age', '연령') }}</h2>
                <ul class="answer-list">
                    <li>
                        <input type="radio" v-model="state.ans3" value="유치원">
                        <label>{{enkor('Kindergarten','유치원')}}</label>
                    </li>
                    <li>
                        <input type="radio" v-model="state.ans3" value="초등">
                        <label>{{enkor('Elementary school','초등')}}</label>
                    </li>
                    <li>
                        <input type="radio" v-model="state.ans3" value="중등">
                        <label>{{enkor('Middle school','중등')}}</label>
                    </li>
                    <li>
                        <input type="radio" v-model="state.ans3" value="고등">
                        <label>{{enkor('High school','고등')}}</label>
                    </li>
                    <li>
                        <input type="radio" v-model="state.ans3" value="대학(원)">
                        <label>{{enkor('University(Graduate school)','대학(원)')}}</label>
                    </li>
                    <li>
                        <input type="radio" v-model="state.ans3" value="성인">
                        <label>{{enkor('Adult','성인')}}</label>
                    </li>
                </ul>
                <h2>4. {{ enkor('Contury', '국가') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans4" value="대한민국">
                            <label>{{enkor('Republic of Korea','대한민국')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="트리니다드 토바고">
                            <label>{{enkor('Trinidad and Tobago','트리니다드 토바고')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="파키스탄">
                            <label>{{enkor('Pakistan ','파키스탄')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="아르헨티나">
                            <label>{{enkor('Argentina ','아르헨티나')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="캄보디아">
                            <label>{{enkor('Cambodia ','캄보디아')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="호주">
                            <label>{{enkor('Australia ','호주')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="세인트키츠 네비스">
                            <label>{{enkor('Saint Kitts and Nevis','세인트키츠 네비스')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="인도">
                            <label>{{enkor('India ','인도')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="그리스">
                            <label>{{enkor('Greece ','그리스')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="퀴라소">
                            <label>{{enkor('Curaçao ','퀴라소')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="싱가포르">
                            <label>{{enkor('Singapore ','싱가포르')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="대만">
                            <label>{{enkor('Taiwan ','대만')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="레소토">
                            <label>{{enkor('Lesotho ','레소토')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="스리랑카">
                            <label>{{enkor('Sri Lanka','스리랑카')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="마다가스카르">
                            <label>{{enkor('Madagascar ','마다가스카르')}}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4" value="말레이시아">
                            <label>{{enkor('Malaysia','말레이시아')}}</label>
                        </li>
                    </ul>
                </div>

                <h2 v-if="state.ans4 =='대한민국'">4-1. {{ enkor('Location', '지역') }}</h2>
                <div v-if="state.ans4 =='대한민국'" class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="서울">
                            <label>서울</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="부산">
                            <label>부산</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="대구">
                            <label>대구</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="인천">
                            <label>인천</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="대전">
                            <label>대전</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="울산">
                            <label>울산</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="경기남부">
                            <label>경기남부</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="경기북부">
                            <label>경기북부</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="강원">
                            <label>서울</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="충북">
                            <label>충북</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="충남">
                            <label>충남</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="전북">
                            <label>전북</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="전남">
                            <label>전남</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="경북">
                            <label>경북</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="경남">
                            <label>경남</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="강원">
                            <label>강원</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="제주">
                            <label>제주</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans4_1" value="카톨릭">
                            <label>카톨릭</label>
                        </li>
                    </ul>
                </div>
                <h2>5. {{ enkor('Participation Type', '참가유형') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans5" value="온라인">
                            <label>{{ enkor('On-line','온라인') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans5" value="온라인+오프라인">
                            <label>{{ enkor('On-line+Off-line','온라인+오프라인') }}</label>
                        </li>
                    </ul>
                </div>
                <h2>6. {{ enkor('Participation motivation', '참가동기') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans6" value="자발적">
                            <label>{{ enkor('Voluntary ','자발적') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans6" value="친구권유">
                            <label>{{ enkor('Friend Recommendation','친구권유') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans6" value="부모님권유">
                            <label>{{ enkor('Parental Recommendation','부모님권유') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans6" value="대장님(선생님)권유">
                            <label>{{ enkor('Captain(Teacher) Recommendation','대장님(선생님)권유') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans6" value="기타">
                            <label>{{ enkor('Etc','기타') }}</label>
                            <input type="text" v-model="state.ans6_1" style="margin-left: 10px;">
                        </li>
                    </ul>
                </div>
                <h2>7. {{ enkor('International Camping Experience', '국제야영 참가경험') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans7" value="처음">
                            <label>{{ enkor('First time','처음') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans7" value="2~5회">
                            <label>{{ enkor('2~5 times','2~5회') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans7" value="6~10회">
                            <label>{{ enkor('6~10 times','6~10회') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans7" value="11회 이상">
                            <label>{{ enkor('More then 11 times','11회 이상') }}</label>
                        </li>
                    </ul>
                </div>
                <h2>8. {{ enkor(`Please check 'Expectation before activity ' and 'Satisfaction after activity' for this activity.`, `본 활동에 대한 ‘활동 전 기대치’ 및 ‘활동 후 만족도’를 체크해주세요.`) }}</h2>
                <div class="answer-section" style="margin-bottom: 10px;">
                    <ul class="answer-list">
                        <li style="width:130px;">
                            {{ enkor('∙ Before activity','∙ 활동 전') }}
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_1" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_1" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_1" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_1" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_1" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li style="width:130px;">
                            {{ enkor('∙ After activity','∙ 활동 후') }}
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_2" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_2" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_2" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_2" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans8_2" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <h2>9. {{ enkor('Please check the satisfaction level of each program.','각 프로그램에 대한 만족도를 체크해주세요.') }}</h2>
                <table class="missionTable">
                    <thead>
                        <tr>
                            <th style="width:150px;"></th>
                            <th style="width:200px;"></th>
                            <th style="width:80px;text-align: center;">{{enkor('Very satisfied','매우만족')}}</th>
                            <th style="width:80px;text-align: center;">{{ enkor('Satisfied','만족')}}</th>
                            <th style="width:80px;text-align: center;">{{ enkor('Normal','보통')}}</th>
                            <th style="width:80px;text-align: center;">{{ enkor('Unsatisfied','불만족')}}</th>
                            <th style="width:80px;text-align: center;">{{ enkor('Very unsatisfied','매우불만족')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="2">{{ enkor('Event','행사') }}</td>
                            <td>{{ enkor('Opening Ceremony','개영식') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_1"  v-model="state.ans9_1" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_1"  v-model="state.ans9_1" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_1"  v-model="state.ans9_1" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_1"  v-model="state.ans9_1" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_1"  v-model="state.ans9_1" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>International Day</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_2"  v-model="state.ans9_2" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_2"  v-model="state.ans9_2" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_2"  v-model="state.ans9_2" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_2"  v-model="state.ans9_2" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_2"  v-model="state.ans9_2" value="0">
                            </td>
                        </tr>

                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td rowspan="7">{{ enkor('World Issue Activity','세계이슈활동') }}</td>
                            <td>Safety&Joy A/B</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_3"  v-model="state.ans9_3" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_3"  v-model="state.ans9_3" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_3"  v-model="state.ans9_3" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_3"  v-model="state.ans9_3" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_3"  v-model="state.ans9_3" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>SDGs#TeamGirl</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_4"  v-model="state.ans9_4" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_4"  v-model="state.ans9_4" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_4"  v-model="state.ans9_4" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_4"  v-model="state.ans9_4" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_4"  v-model="state.ans9_4" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>ABC II</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_5"  v-model="state.ans9_5" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_5"  v-model="state.ans9_5" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_5"  v-model="state.ans9_5" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_5"  v-model="state.ans9_5" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_5"  v-model="state.ans9_5" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>Water</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_6"  v-model="state.ans9_6" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_6"  v-model="state.ans9_6" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_6"  v-model="state.ans9_6" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_6"  v-model="state.ans9_6" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_6"  v-model="state.ans9_6" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>Forests</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_7"  v-model="state.ans9_7" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_7"  v-model="state.ans9_7" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_7"  v-model="state.ans9_7" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_7"  v-model="state.ans9_7" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_7"  v-model="state.ans9_7" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>Ocean</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_8"  v-model="state.ans9_8" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_8"  v-model="state.ans9_8" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_8"  v-model="state.ans9_8" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_8"  v-model="state.ans9_8" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_8"  v-model="state.ans9_8" value="0">
                            </td>
                        </tr>
                        <tr v-if="store.getters.getUserObject?.foreigner != 1">
                            <td>Soil</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_9"  v-model="state.ans9_9" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_9"  v-model="state.ans9_9" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_9"  v-model="state.ans9_9" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_9"  v-model="state.ans9_9" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_9"  v-model="state.ans9_9" value="0">
                            </td>
                        </tr>

                        <tr>
                            <td rowspan="7">{{ enkor('Daily','Daily') }}</td>
                            <td>{{ enkor('Check it out Screentime','Check it out 스크린타임') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_10"  v-model="state.ans9_10" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_10"  v-model="state.ans9_10" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_10"  v-model="state.ans9_10" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_10"  v-model="state.ans9_10" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_10"  v-model="state.ans9_10" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Happy Box','해피박스') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_11"  v-model="state.ans9_11" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_11"  v-model="state.ans9_11" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_11"  v-model="state.ans9_11" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_11"  v-model="state.ans9_11" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_11"  v-model="state.ans9_11" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Mino yoga','미니요가') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_12"  v-model="state.ans9_12" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_12"  v-model="state.ans9_12" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_12"  v-model="state.ans9_12" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_12"  v-model="state.ans9_12" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_12"  v-model="state.ans9_12" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Sisters Backpacking','언니들의 백팩킹') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_13"  v-model="state.ans9_13" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_13"  v-model="state.ans9_13" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_13"  v-model="state.ans9_13" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_13"  v-model="state.ans9_13" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_13"  v-model="state.ans9_13" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Guide Your Money','Guide Your Money') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_14"  v-model="state.ans9_14" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_14"  v-model="state.ans9_14" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_14"  v-model="state.ans9_14" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_14"  v-model="state.ans9_14" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_14"  v-model="state.ans9_14" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Music Cue','금쪽이들의 뮤직큐') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_15"  v-model="state.ans9_15" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_15"  v-model="state.ans9_15" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_15"  v-model="state.ans9_15" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_15"  v-model="state.ans9_15" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_15"  v-model="state.ans9_15" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Digital Footprint','디지털 발자국') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_16"  v-model="state.ans9_16" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_16"  v-model="state.ans9_16" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_16"  v-model="state.ans9_16" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_16"  v-model="state.ans9_16" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_16"  v-model="state.ans9_16" value="0">
                            </td>
                        </tr>

                        <tr>
                            <td rowspan="3">{{ enkor('International exchange','국제교류') }}</td>
                            <td>{{ enkor('Guess Who?','Guess Who?') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_17"  v-model="state.ans9_17" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_17"  v-model="state.ans9_17" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_17"  v-model="state.ans9_17" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_17"  v-model="state.ans9_17" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_17"  v-model="state.ans9_17" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Friend Chat','Friend Chat') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_18"  v-model="state.ans9_18" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_18"  v-model="state.ans9_18" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_18"  v-model="state.ans9_18" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_18"  v-model="state.ans9_18" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_18"  v-model="state.ans9_18" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Challenge Golden bell','Challenge Golden bell') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_19"  v-model="state.ans9_19" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_19"  v-model="state.ans9_19" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_19"  v-model="state.ans9_19" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_19"  v-model="state.ans9_19" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_19"  v-model="state.ans9_19" value="0">
                            </td>
                        </tr>

                        <tr>
                            <td rowspan="3">{{ enkor('Today Quest','Today Quest') }}</td>
                            <td>{{ enkor('Daily Check Quiz','출첵퀴즈') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_20"  v-model="state.ans9_20" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_20"  v-model="state.ans9_20" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_20"  v-model="state.ans9_20" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_20"  v-model="state.ans9_20" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_20"  v-model="state.ans9_20" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Visit Profiles','프로필 파도타기') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_21"  v-model="state.ans9_21" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_21"  v-model="state.ans9_21" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_21"  v-model="state.ans9_21" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_21"  v-model="state.ans9_21" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_21"  v-model="state.ans9_21" value="0">
                            </td>
                        </tr>
                        <tr>
                            <td>{{ enkor('Comment on Your Friends','선플은 사랑입니다') }}</td>
                            <td class="centerCheck">
                                <input type="radio" name="9_22"  v-model="state.ans9_22" value="4">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_22"  v-model="state.ans9_22" value="3">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_22"  v-model="state.ans9_22" value="2">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_22"  v-model="state.ans9_22" value="1">
                            </td>
                            <td class="centerCheck">
                                <input type="radio" name="9_22"  v-model="state.ans9_22" value="0">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h2>10. {{ enkor('Was it helpful for international exchange?', '국제교류에 도움이 되었나요?') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans10" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans10" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans10" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans10" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans10" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <h2 v-if="store.getters.getUserObject?.foreigner != 1">11. {{ enkor('Was the world issue activity helpful to me, such as understanding and becoming interested in the topic of activity?', '세계이슈활동은 활동주제를 이해하고 관심을 갖게 되는 등 나에게 도 움이 되었습니까?') }}</h2>
                <div v-if="store.getters.getUserObject?.foreigner != 1" class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans11" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans11" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans11" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans11" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans11" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <h2 v-if="store.getters.getUserObject?.foreigner != 1">12. {{ enkor('After the world issue activities, did you come to think that the topic of activity is not only important to me, but also an issue that requires my attention and active participation?', '세계이슈활동 후, 활동주제가 나에게도 중요할 뿐만 아니라 나의 관심 과 적극적인 참여가 필요한 이슈라고 생각하게 되었습니까?') }}</h2>
                <div v-if="store.getters.getUserObject?.foreigner != 1" class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans12" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans12" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans12" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans12" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans12" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <h2>{{store.getters.getUserObject?.foreigner != 1 ? '13' : '11'}}. {{ enkor('Please check the connection environment of the event site and satisfaction with the efforts of the progress team (leaders, operating personnel, etc.).', '행사사이트의 접속환경, 행사 전반에 대한 진행팀(대지도자, 운영요원 등)의 노력에 대한 만족도를 체크해주세요.') }}</h2>
                <div class="answer-section">
                    <ul class="answer-list">
                        <li>
                            <input type="radio" v-model="state.ans13" value="4">
                            <label>{{ enkor('Very satisfied','매우만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans13" value="3">
                            <label>{{ enkor('Satisfied','만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans13" value="2">
                            <label>{{ enkor('Normal ','보통') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans13" value="1">
                            <label>{{ enkor('Unsatisfied  ','불만족') }}</label>
                        </li>
                        <li>
                            <input type="radio" v-model="state.ans13" value="0">
                            <label>{{ enkor('Very unsatisfied','매우불만족') }}</label>
                        </li>
                    </ul>
                </div>
                <h2>{{store.getters.getUserObject?.foreigner != 1 ? '14' : '12'}}. {{ enkor('Please enter your thoughts, suggestions, and suggestions after participating.', '참가 후 소감, 건의사항, 제안사항 등을 입력해주세요.') }}</h2>
                <textarea v-model="state.ans14" style="width:80%;height:150px;"></textarea>

                <div class="button-section">
                    <button class="share" @click="onSendSurvey">{{ store.getters.isEnglish ? 'Answer' : '답변제출' }}</button>
                </div>
            </div>
            <button @click="onClickCloseSurvey">X</button>
        </div>
    </div>
</template>

<script>
import MainVideo from './MainVideo.vue';
import MissionComplete from './MissionComplete.vue';
import OnlineMission from './OnlineMission.vue';
import OtherHompage from './OtherHompage.vue';
import Sponsor from './Sponsor.vue';
import TopMenuComponent from '../../components/TopMenu';
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import agreeItem from './agreeData';
import axios from 'axios'
import missionDescript from './MissionDescipt';
import WorldISsueComponent from './WorldIssue.vue';

export default {
    name: 'MainPage',
    beforeMount() {
        const dt = new Date();
        this.state.date = (dt.getMonth()+1).toString() + '0' + dt.getDate().toString();
        this.state.todayQuiz = this.state.checkinquiz.find((v) => v.date == this.state.date);
    },
    mounted() {
        const route = useRoute();
        this.initPage();

        const mission = missionDescript.find((v) => v.id == route.query?.missionId);
        if(mission) {
            this.onClickMission({
                date: route.query.date,
                item: mission,
            });
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            selectedItem: null,
            selectedDate: '',
            agreeItems: agreeItem,
            agreeIndex: 0,
            denyAgree: false,
            showCheckinQuiz: false,
            isCheckedIn: true,
            answer1: '',
            answer2: '',
            date: '',
            todayQuiz: null,
            surveyMode: false,
            sending: false,
            checkinquiz: [
                {
                    date: '1002',
                    korTitle: '10월 2일 출첵 Quiz',
                    engTitle: '10. 2. Check-in Quiz',
                    korQuiz: '제19회 걸스카우트 국제야영의<br/>주제는 무엇일까요?',
                    engQuiz: "What's the topic of the<br />19th Girl Scouts International Camp, KOREA?",
                    answer: 'Together in the Nature',
                },
                {
                    date: '1003',
                    korTitle: '10월 3일 출첵 Quiz',
                    engTitle: '10. 3. Check-in Quiz',
                    korQuiz: '걸스카우트는 소녀와 젊은 여성을 위한 국제교류단체입니다.<br/>그렇다면 전 세계 걸 스카우트 운동에 동참하는 회원국은<br />모두 몇 개국일까요?',
                    engQuiz: 'Girl Scouts is an international exchange organization for girls and young women.<br />So how many member countries are joining the Girl Scouts movement around the world?',
                    answer: '153',
                },
                {
                    date: '1004',
                    korTitle: '10월 4일 출첵 Quiz',
                    engTitle: '10. 4. Check-in Quiz',
                    korQuiz: '우리가 지금 참여하고 있는 제19회 걸스카우트 국제야영은 언제까지 열리는 걸까요?',
                    engQuiz: 'Until when is the 19th Girl Scout International Camp, KOREA?',
                    answer: '6',
                },
                {
                    date: '1005',
                    korTitle: '10월 5일 출첵 Quiz',
                    engTitle: '10. 5. Check-in Quiz',
                    korQuiz: '한국걸스카우트연맹의 나이는?',
                    engQuiz: 'How old is the Girl Scouts of Korea?',
                    answer: '77',
                },
            ],
            ans1: '',
            ans2: '',
            ans3: '',
            ans4: '',
            ans5: '',
            ans6: '',
            ans6_1: '',
            ans7: '',
            ans8_1: -1,
            ans8_2: -1,
            ans9_1: -1,
            ans9_2: -1,
            ans9_3: -1,
            ans9_4: -1,
            ans9_5: -1,
            ans9_6: -1,
            ans9_7: -1,
            ans9_8: -1,
            ans9_9: -1,
            ans9_10: -1,
            ans9_11: -1,
            ans9_12: -1,
            ans9_13: -1,
            ans9_14: -1,
            ans9_15: -1,
            ans9_16: -1,
            ans9_17: -1,
            ans9_18: -1,
            ans9_19: -1,
            ans9_20: -1,
            ans9_21: -1,
            ans9_22: -1,
            ans10: -1,
            ans11: -1,
            ans12: -1,
            ans13: -1,
            ans14: '',
        })
        const certiFile = ref();
        
        const initPage = () => {
            axios.get('/checkin')
            .then((res) => {
                if(res.data.result) {
                    state.isCheckedIn = res.data.data.checkedIn;
                }
            }).catch(() => state.isCheckedIn = false);

            axios.get('/survey/check')
            .then((res) => {
                if(res.data.result == false && res.data.code == 404) {
                    state.surveyMode = true;
                } else {
                    //result true는 이미 한거, code 401은 아직 시간이 안된거
                    state.surveyMode = false;
                }
            });
        }
        const onClickMission = (item) => {
            state.selectedItem = item.item;
            state.selectedDate = item.date;
        }
        const onClickClosModal = (e) => {
            e.stopPropagation();
            state.selectedItem = null;
        }
        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onClickContinue = () => {
            if(state.agreeIndex < (state.agreeItems.length - 1)) {
                state.agreeIndex++;
            } else {
                return false;
            }

            return true;
        }

        const onClickYes = () => {
            if(onClickContinue() == false) {
                axios.patch('/user/saftyagree')
                .then((res) => {
                    if(!res.data.result) {
                        alert('활동안전동의를 저장하지 못했습니다.');
                    }
                })
                
                const user = store.getters.getUserObject;
                user.saftyAgree = 1;
                store.commit('setUserObject', user);
            }
        }

        const onClickNo = () => {
            state.denyAgree = true;
        }

        const onClickGo = () => {
            if(state.selectedItem.url == '/mypage' || state.selectedItem.url == '/') {
                router.push(state.selectedItem.url);
            } else {
                window.open(state.selectedItem.url);
            }
        }

        const onClickCerti = () => {
            axios.get('/mission/list')
            .then((res) => {
                if(res.data.result) {
                    const find = res.data.data.find((v) => v.missionId == state.selectedItem.id);
                    if(find) {
                        if(!confirm(store.getters.isEnglish ? 'Do you want to delete the existing authentication and replace it with the one you upload now?' : '기존 인증을 삭제하고 지금 올리는 인증으로 대체하시겠습니까?')) {
                            return;
                        }
                    }
                } 

                certiFile.value.click();
            })
        }

        const onChangeFile = () => {
            let formData = new FormData();
            console.log(state.selectedItem);
            formData.append("missionDate", state.selectedDate);
            formData.append("missionId", state.selectedItem.id);
            formData.append("missionName", state.selectedItem.kor);
            formData.append("point", state.selectedItem.point);
            formData.append("file", certiFile.value.files[0])
            axios.post('https://ic.girlscout.or.kr:5443/mission/certi', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                if(res.data?.result || false) {
                    state.selectedItem = null;
                    state.selectedDate = '';
                } else {
                    alert('Mission Upload fail2\n'+res.data.code.toString());
                }
            }).catch((e) => {
                alert('Mission Upload fail\n'+e.message);
            })
        }

        const onClickCloseCheckin = () => {
            state.showCheckinQuiz = false;
        }

        const onClickAnswer = () => {
            let answer = '';

            if(state.date == '1002') {
                if(state.answer1 == '' || state.answer2 == '') {
                    alert('답변이 입력되지 않았습니다.')
                    return;
                }
                answer = 'T'+state.answer1+' in the N'+state.answer2;
            } else {
                answer = state.answer1;
            }

            const quiz = state.checkinquiz.find((v) => v.date == state.date);
            if(state.date == '1003' && answer == 152) {
                quiz.answer = 152;
            }


            if(answer != quiz.answer) {
                alert(
                    store.getters.isEnglish ? 'Incorrect!\nTry again please.' : '다시 생각해 보자!'
                );
                return;
            }

            axios.post('/checkin', {
                date: state.date,
                answer: answer,
            }).then((res) => {
                if(res.data.result) {
                    state.showCheckinQuiz = false;
                    alert(store.getters.isEnglish ? "Succecc" : "출석체크 답변이 제출되었습니다");
                }
            })
        }

        const enkor = (eng, kor) => {
            return store.getters.isEnglish ? eng : kor;
        }

        const onClickCloseSurvey = () => {
            state.surveyMode = false;
        }

        const onSendSurvey = () => {
            if(state.sending) return;

            
            if(state.ans1 == '' || state.ans2 == '' || state.ans3 == '' || state.ans4 == '' || state.ans5 == '' || state.ans6 == '' || state.ans7 == '') {
                alert(store.getters.isEnglish ? "Please select all answers" : "모든 질문에 답해 주세요.")
                return;
            }

            if(state.ans8_1 == -1 || state.ans8_2 == -1) {
                alert(store.getters.isEnglish ? "Please select all answers" : "모든 질문에 답해 주세요.")
                return;
            }

            if(state.ans10 == -1 ||  state.ans13 == -1) {
                alert(store.getters.isEnglish ? "Please select all answers" : "모든 질문에 답해 주세요.")
                return;
            }

            if(state.ans9_1 == -1 ||
            state.ans9_2 == -1 ||
            
            state.ans9_10 == -1 ||
            state.ans9_11 == -1 ||
            state.ans9_12 == -1 ||
            state.ans9_13 == -1 ||
            state.ans9_14 == -1 ||
            state.ans9_15 == -1 ||
            state.ans9_16 == -1 ||
            state.ans9_17 == -1 ||
            state.ans9_18 == -1 ||
            state.ans9_19 == -1 ||
            state.ans9_20 == -1 ||
            state.ans9_21 == -1 ||
            state.ans9_22 == -1
            ) {
                alert(store.getters.isEnglish ? "Please select all answers" : "모든 질문에 답해 주세요.")
                return;
            }

            if(store.getters.getUserObject?.foreigner == 0 && (state.ans9_3 == -1 ||
            state.ans9_4 == -1 ||
            state.ans9_5 == -1 ||
            state.ans9_6 == -1 ||
            state.ans9_7 == -1 ||
            state.ans9_8 == -1 ||
            state.ans9_9 == -1 ||
            state.ans11 == -1 || state.ans12 == -1) ) {
                alert(store.getters.isEnglish ? "Please select all answers" : "모든 질문에 답해 주세요.")
                return;
            }

            if(state.ans6 == '기타' && state.ans6_1 == '') {
                alert('6번의 기타를 입력해 주세요.');
                return;
            }

            if(state.ans4 == '대한민국' && state.ans4_1 == '') {
                alert('지역을 선택해 주세요.');
                return;
            }

            state.sending = true;

            axios.post('/survey', {
                ans1: state.ans1,
                ans2: state.ans2,
                ans3: state.ans3,
                ans4: state.ans4,
                ans4_1: state.ans4_1,
                ans5: state.ans5,
                ans6: state.ans6,
                ans6_1: state.ans6_1,
                ans7: state.ans7,
                ans8_1: state.ans8_1,
                ans8_2: state.ans8_2,
                ans9_1: state.ans9_1,
                ans9_2: state.ans9_2,
                ans9_3: state.ans9_3,
                ans9_4: state.ans9_4,
                ans9_5: state.ans9_5,
                ans9_6: state.ans9_6,
                ans9_7: state.ans9_7,
                ans9_8: state.ans9_8,
                ans9_9: state.ans9_9,
                ans9_10: state.ans9_10,
                ans9_11: state.ans9_11,
                ans9_12: state.ans9_12,
                ans9_13: state.ans9_13,
                ans9_14: state.ans9_14,
                ans9_15: state.ans9_15,
                ans9_16: state.ans9_16,
                ans9_17: state.ans9_17,
                ans9_18: state.ans9_18,
                ans9_19: state.ans9_19,
                ans9_20: state.ans9_20,
                ans9_21: state.ans9_21,
                ans9_22: state.ans9_22,
                ans10: state.ans10,
                ans11: state.ans11,
                ans12: state.ans12,
                ans13: state.ans13,
                ans14: state.ans14
            }).then((res) => {
                if(res.data.result) {
                    state.surveyMode = false;
                    alert(store.getters.isEnglish ? "Thank you for Survey" : "설문에 참여해 주셔서 감사합니다.");
                } else {
                    state.sending = false;
                    alert("Survey save error. Try again");
                }
            }).catch(() => {
                state.sending = false;
                alert("Survey save exception!");
            })
        }

        return {
            router,
            store,
            state,
            certiFile,
            initPage,
            onClickMission,
            onClickClosModal,
            onClickModal,
            onClickContinue,
            onClickYes,
            onClickNo,
            onClickGo,
            onClickCerti,
            onChangeFile,
            onClickCloseCheckin,
            onClickAnswer,
            enkor,
            onClickCloseSurvey,
            onSendSurvey,
        }
    },
    components: { TopMenuComponent, MainVideo, OnlineMission, OtherHompage, MissionComplete, Sponsor, WorldISsueComponent }
}
</script>

<style scoped>
.body-section {
    position: relative;
    background-color: #48B9FF;
    width: 100vw;
    height: 100vh;
    overflow: auto;
}

.body-section::-webkit-scrollbar {
    display: none;
}

@media (min-width: 100px) and (max-width: 767px) {
    .title-section {
        width: 100%;
        text-align: center;
    }

    .title-section > img {
        width: 90%;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 80%;
        height: 490px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;
        text-align: center;
    }

    .modal > h1 {
        margin: 0;
        font-size: 24px;
        margin-top: 30px;
    }

    .modal > p {
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    .image-box {
        width: 120px;
    }
    .modal > .image-box > img {
        width: 120px;
        object-fit: contain;
    }

    .modal > .button-section {
        margin-top: 30px;
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 80%;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-top: 20px;
    }

    .agreeModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.3);
        backdrop-filter: blur(10px);
        background-image: url(~@/assets/agent.png);
        background-repeat: no-repeat;
        background-position: 0px 100%;
        background-size: 150px;
        z-index: 1000;
    }

    .agreeModal {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 430px;
        bottom: 170px;
        background-color: transparent;
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
        background-image: url(~@/assets/mobile_guideback.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 430px;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        padding: 70px 10px 0px 20px ;
    }

    .agreeModal > p {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .agreeModal > .continue {
        position: fixed;
        box-sizing: border-box;
        text-align: right;
        right: 40px;
        bottom: 240px;
    }

    .agreeModal > .continue > button {
        border: none;
        width: 70px;
        height: 30px;
        background-color: blue;
        color: white;
        margin-left: 10px;
        border-radius: 10px;
    }

    .agreeModal > label {
        position: absolute;
        top: 10px;
        left: 50px;
    }

    .agreeModal > .continue > img {
        margin-right: 10px;
    }

    .agreeModal > .continue > .say-yes {
        background-color: #01B80A;
    }

    .agreeModal > .continue > .say-no {
        background-color: #F65656;
    }

    .denyModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.3);
        backdrop-filter: blur(10px);
        background-image: url(~@/assets/agent.png);
        background-repeat: no-repeat;
        background-position: 0px 100%;
        background-size: 150px;
    }

    .denyModal {
        position: fixed;
        z-index: 1000;
        width: 300px;
        height: 350px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-sizing: border-box;
        text-align: center;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 30px;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        overflow: hidden;
        text-align: center;
    }

    .denyModal > .warning-icon {
        background-color: #F65656;
        height: 100px;
        margin-bottom: 50px;
        box-sizing: border-box;
        padding-top: 20px;
    }

    .denyModal > button {
        background-color: #F65656;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 20px;
        color: white;
        margin-top: 20px;
    }

    .denyModal > h1 {
        margin-top: -20px;
    }

    .denyModal > p {
        margin-top: 20px;
    }

    .bottom-section {
        width: 100%;
        text-align: center;
    }
    .bottom-section > img {
        width: 100%;
        margin-bottom: -5px;
    }

    .checkinquiz-section {
        position: fixed;
        right: 50px;
        bottom: 50px;
        z-index: 900;
    }

    .checkinquiz-section > img {
        width: 120px;
        height: 120px;
    }

    .checkinModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 1000;
    }

    .checkinModal {
        position: fixed;
        z-index: 1001;
        width: 95%;
        height: 600px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-sizing: border-box;
        text-align: center;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 30px;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        overflow: hidden;
        text-align: center;
        padding: 50px 30px;
    }

    .checkinModal > img {
        width: 52px;
    }

    .checkinModal > h2 {
        font-size: 20px;
        font-weight: 500;
        margin-top: 10px;
    }

    .checkinModal > h1 {
        font-size: 20px;
        font-weight: 500;
        margin-top: 50px;
    }

    .checkinModal > .answer {
        font-size: 32px;
        font-weight: 500;
        margin-top: 70px;
    }

    .checkinModal > .answer > input[type="text"] {
        outline: none;
        font-size: 24px;
        padding: 10px 20px;
    }

    .checkinModal > .answer > input::placeholder {
        color: #afafaf;
    }

    .checkinModal > .button-section {
        margin-top: 30px;
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
    }

    .checkinModal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 20px;
        font-weight: 600;
        width: 170px;
        height: 50px;
        padding: 10px 20px;
        border-radius: 15px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .checkinModal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
    }

    .surveyModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .surveyModal {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 720px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 20px 50px;
        text-align: left;

        overflow: auto;
    }

    .surveyModal > h1 {
        font-size: 24px;
    }

    .surveyModal > h2 {
        font-size: 20px;
    }

    .surveyModal > .answer-section {
        display: flex;
        margin-bottom: 30px;
    }

    .surveyModal > .answer-section > .answer-box {
        margin-right: 20px;
    }

    .answer-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .answer-list > li {
        margin-right: 20px;
    }

    .surveyModal > .button-section {
        bottom: 60px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        display: flex;
        justify-items: center;
        margin-top: 30px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .surveyModal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 170px;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        display: block;
        margin:auto;
    }

    .surveyModal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-left: 20px;
    }

    .surveyModal > .missionTable > tbody > tr {
        border-top: 1px solid #EFF2F3;
    }
    
    .surveyModal > .missionTable > tbody > tr > td {
        vertical-align: top;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .centerCheck {
        text-align: center;
    }

    input[type="radio"] {
        width: 18px;
        height: 18px;
    }

    .answer-box {
        display: flex;
    }

    .answer-box > label {
        display: block;
        margin-top: 3px;
        margin-left: 5px;
    }

    .answer-list > li {
        display: flex;
        margin-bottom: 10px;
    }

    .answer-list > li > label {
        display: block;
        margin-top: 3px;
        margin-left: 5px;
    }
}

@media (min-width:768px) {
    .body-section {
        background-image: url('../../assets/main_top_back.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 960px;
        width: 1600px;
        margin: auto;
    }

    .title-section {
        width: 100%;
        text-align: center;
        margin-top: 80px;
    }

    .title-section > img {
        width: 80%;
        max-width: 750px;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 500px;
        height: 520px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
    }

    .modal > .button-section {
        position: absolute;
        bottom: 60px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        width: 80%;
        text-align: center;
        display: flex;
        justify-items: center;
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 170px;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        display: block;
        margin:auto;
    }

    .modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-left: 20px;
    }

    .image-box {
        width: 180px;
        height: 200px;
        margin: auto;
        position: relative;
    }
    .modal > .image-box > img {
        width: 100%;
        top: 50%;
        left:0;
        transform: translateY(-50%);
        position: absolute;
        object-fit: contain;
    }

    .modal > .image-box:after{display:inline-block; height:100%; content:""; vertical-align:middle;}

    .agreeModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.3);
        backdrop-filter: blur(10px);
        background-image: url(~@/assets/agent.png);
        background-repeat: no-repeat;
        background-position: 50px 100%;
        z-index: 1000;
    }

    .agreeModal {
        position: fixed;
        z-index: 1000;
        width: 860px;
        height: 251px;
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
        background-color: transparent;
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
        background-image: url(~@/assets/pc_guideback.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        padding: 70px 0px 0px 80px ;

    }

    .agreeModal > p {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .agreeModal > .continue {
        position: fixed;
        box-sizing: border-box;
        text-align: right;
        right: 40px;
        bottom: 20px;
    }

    .agreeModal > .continue > button {
        border: none;
        width: 70px;
        height: 30px;
        background-color: blue;
        color: white;
        margin-left: 10px;
        border-radius: 10px;
    }

    .agreeModal > label {
        position: absolute;
        top: 10px;
        left: 130px;
    }

    .agreeModal > .continue > img {
        margin-right: 10px;
    }

    .agreeModal > .continue > .say-yes {
        background-color: #01B80A;
    }

    .agreeModal > .continue > .say-no {
        background-color: #F65656;
    }
    

    .modal > img {
        width: 180px;
        max-height: 180px;
    }

    .denyModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.3);
        backdrop-filter: blur(10px);
        background-image: url(~@/assets/agent.png);
        background-repeat: no-repeat;
        background-position: 50px 100%;
    }

    .denyModal {
        position: fixed;
        z-index: 1000;
        width: 560px;
        height: 560px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-sizing: border-box;
        text-align: center;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 30px;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        overflow: hidden;
        text-align: center;
    }

    .denyModal > .warning-icon {
        background-color: #F65656;
        height: 200px;
        margin-bottom: 50px;
        box-sizing: border-box;
        padding-top: 70px;
    }

    .denyModal > button {
        background-color: #F65656;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 20px;
        color: white;
        margin-top: 50px;
    }

    .denyModal > p {
        margin-top: 50px;
    }

    .bottom-section {
        width: 100%;
        text-align: center;
    }
    .bottom-section > img {
        width: 100%;
        margin-bottom: -5px;
    }

    .checkinquiz-section {
        position: fixed;
        right: 100px;
        bottom: 100px;
        z-index: 900;
    }

    .checkinquiz-section > img {
        width: 150px;
        height: 150px;
    }

    .checkinModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .checkinModal {
        position: fixed;
        z-index: 1000;
        width: 560px;
        height: 660px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-sizing: border-box;
        text-align: center;
        font-size: 20px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 30px;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        overflow: hidden;
        text-align: center;
        padding: 50px 30px;
    }

    .checkinModal > img {
        width: 52px;
    }

    .checkinModal > h2 {
        font-size: 24px;
        font-weight: 500;
        margin-top: 10px;
    }

    .checkinModal > h1 {
        font-size: 30px;
        font-weight: 500;
        margin-top: 50px;
    }

    .checkinModal > .answer {
        font-size: 32px;
        font-weight: 500;
        margin-top: 50px;
    }

    .checkinModal > .answer > input[type="text"] {
        outline: none;
        font-size: 24px;
        padding:10px 20px;
    }

    .checkinModal > .answer > input::placeholder {
        color: #afafaf;
    }

    .checkinModal > .button-section {
        margin-top: 30px;
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
    }

    .checkinModal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 24px;
        font-weight: 600;
        width: 170px;
        height: 70px;
        padding: 10px 20px;
        border-radius: 15px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .checkinModal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
    }

    .surveyModalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .surveyModal {
        position: fixed;
        z-index: 1000;
        width: 1050px;
        height: 720px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 20px 50px;
        text-align: left;

        overflow: auto;
    }

    .surveyModal > h1 {
        font-size: 24px;
    }

    .surveyModal > h2 {
        font-size: 20px;
    }

    .surveyModal > .answer-section {
        display: flex;
        margin-bottom: 30px;
    }

    .surveyModal > .answer-section > .answer-box {
        margin-right: 20px;
    }

    .answer-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .answer-list > li {
        margin-right: 20px;
    }

    .surveyModal > .button-section {
        bottom: 60px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        display: flex;
        justify-items: center;
        margin-top: 30px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .surveyModal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 170px;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        display: block;
        margin:auto;
    }

    .surveyModal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-left: 20px;
    }

    .surveyModal > .missionTable > tbody > tr {
        border-top: 1px solid #EFF2F3;
    }
    
    .surveyModal > .missionTable > tbody > tr > td {
        vertical-align: top;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .centerCheck {
        text-align: center;
    }

    input[type="radio"] {
        width: 18px;
        height: 18px;
    }

    .answer-box {
        display: flex;
    }

    .answer-box > label {
        display: block;
        margin-top: 3px;
        margin-left: 5px;
    }

    .answer-list > li {
        display: flex;
        margin-bottom: 10px;
    }

    .answer-list > li > label {
        display: block;
        margin-top: 3px;
        margin-left: 5px;
    }
}

</style>