<template>
    <div class="body-section">
        <WorldBlock />
    </div>
</template>

<script>
import WorldBlock from './WorldBlock/WorldBlock.vue';

export default {
    name: 'WorldIssueComponent',
    setup() {
    },
    components: { WorldBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {

}

@media (min-width:768px) {

}

</style>