<template>
  <router-view/>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import axios from 'axios'
import { watch } from 'vue';

export default {
  name: 'App',
  beforeCreate() {
    const store = useStore();

    axios.get('https://ic.girlscout.or.kr:5443/user/check')
    .then((res) => {
        if(res.data?.result || false) {
            store.commit('setUserObject', {
                id:res.data.data.id,
                name:res.data.data.name,
                saftyAgree:res.data.data.saftyAgree,
                profileUrl: res.data.data.profileUrl,
                foreigner: res.data.data.foreigner,
                role: res.data.data.role,
            })
        }
    })
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    watch(route, () => store.commit('setCurrentPath', route.fullPath));
    watch(() => store.state.visit, () => {
      if(store.state.visit) {
        document.querySelector('.mypage-section').scrollTo(0,0);
      }
    })

    return {
      route,
      store,
    }
  }
}
</script>

<style>
body {
  margin:0;
  padding:0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #48B9FF;
}

#app {
  font-family: 'Pretendard';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
