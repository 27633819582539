<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>{{ store.getters.isEnglish ? 'Guest Book' : '방명록' }}</label>
        </div>

        <button class="btn-more" @click="onClickMore">{{ store.getters.isEnglish ? '+ more' : '+ 더보기' }}</button>

        <ul>
            <li v-for="(item, index) in state.list" :key="index">
                <span class="title">{{ item.content }}</span>
                <span class="author">{{ item.visitorName }}</span>
            </li>
            <li v-if="state.list.length == 0">
                아직 방문 인사가 없습니다.
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, watch } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'VisitorBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const store = useStore();
        const state = reactive({
            list: [],
        })

        watch(() => store.getters.getUserObject, () => initPage() );

        const initPage = () => {
            axios.get('/visitor/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onClickMore = () => {
            emit('more');
        }

        return {
            state,
            store,
            initPage,
            onClickMore,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .block-section > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 20px;
    }

    .block-section > ul > li {
        display: flex;
        margin-bottom: 10px;
    }

    .block-section > ul > li > .title {
        color: #323A3F;
        font-size: 15px;
        display: block;
        width: 280px;
    }

    .block-section > ul > li > .author {
        color: #8F9EA8;
        font-size: 13px;
        text-align: right;
        width: 90px;
        display: block;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: #A5B5BB;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .block-section > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 20px;
    }

    .block-section > ul > li {
        display: flex;
        margin-bottom: 10px;
    }

    .block-section > ul > li > .title {
        color: #323A3F;
        font-size: 15px;
        display: block;
        width: 280px;
    }

    .block-section > ul > li > .author {
        color: #8F9EA8;
        font-size: 13px;
        text-align: right;
        width: 90px;
        display: block;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: #A5B5BB;
    }
}
</style>