<template>
    <div class="mypage-back">
        <div class="mypage-section">
            <div class="top-section">
                <TopMenuComponent class="topmenu" @reset="onResetMypage" />
            </div>

            <div class="main-section">
                <div class="profile-section">
                    <ProfileBlock @click="onSelectMenu" v-model="state.selectedMenu"/>
                </div>

                <div class="content-section">
                    <component :is="state.activePage" @more="onMore" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, shallowRef } from 'vue';
import TopMenuComponent from '../../components/TopMenu';
import ProfileBlock from './CommonBlock/ProfileBlock.vue';
import Dashboard from './Dashboard.vue';
import Reward from './Reward.vue';
import Friend from './Friend.vue';
import Mission from './Mission.vue';
import WorldIssue from './WorldIssue.vue';
import Setup from './Setup.vue';

import Visitor
 from './Visitor.vue';
export default {
    name: 'MyPage',
    setup() {
        const state = reactive({
            activePage: shallowRef(Dashboard),
            selectedMenu: 0,
        });

        const onSelectMenu = (menu) => { 
            state.selectedMenu = menu;
            
            switch(menu) {
                case 0:
                    state.activePage = shallowRef(Dashboard);
                    break;
                case 1:
                    state.activePage = shallowRef(Reward);
                    break;
                case 2:
                    state.activePage = shallowRef(Friend);
                    break;
                case 3:
                    state.activePage = shallowRef(Mission);
                    break;
                case 4:
                    state.activePage = shallowRef(WorldIssue);
                    break;
                case 5:
                    state.activePage = shallowRef(Setup);
                    break;
            }    
        }

        const onMore = (menu) => {
            switch(menu) {
                case 'dashboard':
                    onSelectMenu(0);
                    break;
                case 'reward':
                    onSelectMenu(1);
                    break;
                case 'friend':
                    onSelectMenu(2);
                    break;
                case 'mission':
                    onSelectMenu(3);
                    break;
                case 'world':
                    onSelectMenu(4);
                    break;
                case 'setup':
                    onSelectMenu(5);
                    break;
                case 'visitor':
                    state.activePage = shallowRef(Visitor);
                    break;
            }
        }

        const onResetMypage = function () {
            onSelectMenu(0);
        }

        return {
            state,
            onSelectMenu,
            onMore,
            onResetMypage,
        }
    },
    components: { TopMenuComponent, ProfileBlock, Dashboard, Reward, Friend, Mission, WorldIssue, Visitor }
}
</script>


<style scoped>
.mypage-back {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url(~@/assets/m_myback.jpg);
}

.mypage-section {
    position: relative;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    width: 1600px;
    height: 100%;
    overflow: auto;
}

.mypage-section::-webkit-scrollbar {
    display: none;
}

@media (min-width: 100px) and (max-width: 767px) {
    .mypage-section {
        box-sizing: border-box;
        padding: 10px 20px;
        
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: auto;
        width: 100%;
    }

    .mypage-section::-webkit-scrollbar {
        display: none;
    }
}

@media (min-width:768px) {
    .mypage-section {
        max-width: 1600px;
        min-height: 960px;
        margin: auto;
    }

    .main-section {
        display: flex;
        width: 1560px;
    }

    .profile-section {
        width: 300px;
        height: 100%;
    }

    .content-section {
        width: 1240px;
    }

    .top-section {
        display: flex;
        margin-top: 0px;
        margin-bottom: 30px;
        box-sizing: border-box;
        margin-left: -20px;
    }
}

</style>