<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mylamp.png">
            <label>{{ store.getters.isEnglish ? 'My Friends' : '나의 친구' }}</label>
            <span>{{state.list.length}} {{ store.getters.isEnglish ? (state.list.length > 1 ? 'friends' : 'friend') : '명' }}</span>
        </div>

        <ul class="friend-list">
            <li v-for="(item, index) in state.list" :key="index" @click="onGoFriend(item)">
                <div class="friend-item">
                    <img :src="item.profileUrl == '' ? '/img/mypage/dummy.png' : item.profileUrl">
                    <label>{{ item.name }}</label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'FriendBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(porps, { emit }) {
        const store = useStore();
        const state = reactive({
            list: [],
        })

        const initPage = () => {
            axios.get('/friend/list')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                }
            })
        }

        const onGoFriend = (user) => {
            axios.post('/history', {
                friendId: user.friendId,
            }).then(() => {
                emit('more', 'dashboard');
                store.commit('setVisitObject', {
                    id: user.friendId,
                    name: user.name,
                    profileUrl: user.profileUrl,
                });
            })
        }

        return {
            store,
            state,
            initPage,
            onGoFriend,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
    }

    .title-section {
        display: flex;
    }

    .title-section > label {
        font-size: 24px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .title-section > span {
        font-size: 32px;
        font-weight: 500;
        margin-left: auto;
        padding-top: 5px;
        color: #84CAFF;
    }

    .friend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        justify-content: space-between;
    }

    .friend-list > li {
        width: 29.5%;
        padding-bottom: 27.5%;
        background-color: #F5F7F9;
        margin-bottom: 30px;
        position: relative;
        border-radius: 10px;
        text-align: center;
        min-width: 115px;
        min-height: 40px;
    }

    .friend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
        text-align: left;
    }

    .friend-item > img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .friend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
    }

    .title-section {
        display: flex;
    }

    .title-section > label {
        font-size: 24px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .title-section > span {
        font-size: 32px;
        font-weight: 500;
        margin-left: auto;
        padding-top: 5px;
        color: #84CAFF;
    }

    .friend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .friend-list > li {
        width: 125px;
        padding-bottom: 100px;
        background-color: #F5F7F9;
        margin-right: 20px;
        margin-bottom: 5.5%;
        position: relative;
        border-radius: 10px;
    }

    .friend-list > li:nth-child(3n) {
        margin-right: 0;
    }

    .friend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
    }

    .friend-item > img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .friend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }
}
</style>