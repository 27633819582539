<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>방명록</label>
        </div>

        <button v-if="store.getters.isVisitMode" class="btn-write" @click="onClickWrtie">{{ store.getters.isEnglish ? 'WRITE' : '글쓰기' }}</button>
        <button v-if="!store.getters.isVisitMode" class="btn-write" @click="onClickClose">{{ store.getters.isEnglish ? 'CLOSE' : '닫기' }}</button>

        <ul>
            <li v-for="(item, index) in state.list" :key="index">
                <div class="visitor" @click="onGoFriend(item)">
                    <img v-if="item.profileUrl != ''" :src="item.profileUrl" />
                    <img v-else src="/img/mypage/dummy.png" />
                    <span class="author">{{ item.visitorName }}</span>
                </div>
                
                <span class="title">{{ item.content }}</span>
            </li>
            <li v-if="state.list?.length == 0">
                아직 방문 인사가 없습니다.
            </li>
        </ul>

        <div v-if="state.showWrite" class="modalFull" @click="state.showWrite = false">
            <div class="modal" @click="onClickModal">
                <textarea ref="note" @input="onInputNote"></textarea>
                <div class="button-section">
                    <button class="share" @click="onClickSave">등록</button>
                    <button class="cancel" @click="state.showWrite = false">취소</button>
                </div>
                <input type="file" accept="image/jpg,image/jpeg,image/png,video/mp4,video/mov" ref="certiFile" id="certiFile" style="visibility: hidden;" @change="onChangeFile" />
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'VisitorBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const store = useStore();
        const state = reactive({
            list: [],
            reward: 0,
            showWrite: false,
            note: '',
        })

        const initPage = () => {
            axios.get('/visitor/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onClickClosModal = (e) => {
            e.stopPropagation();
            state.selectedItem = null;
        }
        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onClickWrtie = () => {
            state.showWrite = true;
        }

        const onInputNote = (e) => {
            state.note = e.target.value;
        }

        const onClickSave = () => {
            axios.post('/visitor/write', {
                friendId: store.getters.getUserObject.id,
                myName: store.getters.getOriginalUser.name,
                content: state.note,
            }).then((res) => {
                if(res.data.result) {
                    let item = res.data.data;
                    item.profileUrl = store.getters.isVisitMode ? store.getters.getOriginalUser.profileUrl : store.getters.getUserObject.profileUrl;
                    state.list.push(item);
                    state.showWrite = false;
                }
            })
        }

        const onGoFriend = (user) => {
            axios.post('/history', {
                friendId: user.visitorId,
            }).then(() => {
                store.commit('setVisitObject', {
                    id: user.visitorId,
                    name: user.visitorName,
                    profileUrl: user.profileUrl,
                });
            })
        }

        const onClickClose = function () {
            emit('more', 'dashboard');
        }

        return {
            store,
            state,
            initPage,
            onClickClosModal,
            onClickModal,
            onClickWrtie,
            onInputNote,
            onClickSave,
            onGoFriend,
            onClickClose,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #F5F7F9;
        border-radius: 10px;
        margin-top: 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > h1 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
        margin-top: 30px;
    }

    .block-section > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 20px;
        box-sizing: border-box;
    }

    .block-section > ul > li {
        background-color: #F5F7F9;
        width: 40%;
        height: 80px;
        border-radius: 15px;
    }

    .visitor {
        display: flex;
    }

    .visitor > img {
        width: 36px;
    }

    .visitor > .author {
        font-size: 22px;
        display: block;
        margin-top: 4px;
        margin-left: 8px;
    }

    .block-section > ul > li > .title {
        display: block;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
    }

    .block-section > .btn-write {
        border: none;
        background-color: #48B9FF;
        color: white;
        font-size: 20px;
        font-weight: 500;
        padding: 6px 15px;
        border-radius: 10px;
        position: absolute;
        top: 35px;
        right: 50px;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 80%;
        height: 490px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;
        text-align: center;
    }

    .modal > textarea {
        width: 100%;
        height: 70%;
    }

    .modal > h1 {
        margin: 0;
        font-size: 24px;
        margin-top: 30px;
    }

    .modal > p {
        margin: 0;
        padding: 0;
        margin-top: 20px;
    }

    .modal > img {
        width: 120px;
    }

    .modal > .button-section {
        margin-top: 30px;
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 16px;
        width: 80%;
        padding: 10px 20px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-top: 20px;
    }
}

@media (min-width:768px) {
    .block-section {
        height: 100%;
        box-sizing: border-box;
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        margin-left: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 40px 20px 0px 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > h1 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
        margin-top: 30px;
        margin-left: 20px;
    }

    .block-section > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .block-section > ul > li {
        background-color: #F5F7F9;
        width: 49%;
        min-height: 130px;
        height: auto;
        border-radius: 10px;
        margin-bottom: 2%;
        box-sizing: border-box;
        padding: 20px;
    }

    .visitor {
        display: flex;
    }

    .visitor > img {
        width: 36px;
        height: 36px;
        border-radius: 36px;
    }

    .visitor > .author {
        font-size: 22px;
        display: block;
        margin-top: 4px;
        margin-left: 8px;
    }

    .block-section > ul > li > .title {
        display: block;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
    }

    .block-section > .btn-write {
        border: none;
        background-color: #48B9FF;
        color: white;
        font-size: 20px;
        font-weight: 500;
        padding: 6px 15px;
        border-radius: 10px;
        position: absolute;
        top: 35px;
        right: 50px;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 500px;
        height: 520px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
    }

    .modal > textarea {
        width: 100%;
        height: 70%;
        font-size: 20px;
    }

    .modal > .button-section {
        position: absolute;
        bottom: 60px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 16px;
        width: 170px;
        padding: 10px 20px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
    }

    .modal > .button-section > .share {
        background-color: #48B9FF;
        color: white;
        margin-right: 20px;
    }

}
</style>