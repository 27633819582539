<template>
    <div class="body-section">
        <FavoritBlock id="favorit" />
        <MissionBlock id="mission" @more="onMore('mission')" />
        <VisitorBlock id="visitor" @more="onMore('visitor')" />
        <FriendBlock id="friend" @more="onMore('friend')" />
        <RewardBlock id="reward" @more="onMore('reward')" />
        <FavoritFriendBlock id="favfriend" />
        <WorldBlock id="worldissue" @more="onMore('world')" />
    </div>
</template>

<script>
import FavoritBlock from './DashboardBlock/FavoritBlock.vue';
import FavoritFriendBlock from './DashboardBlock/FavoritFriendBlock.vue';
import FriendBlock from './DashboardBlock/FriendBlock.vue';
import MissionBlock from './DashboardBlock/MissionBlock.vue';
import RewardBlock from './DashboardBlock/RewardBlock.vue';
import VisitorBlock from './DashboardBlock/VisitorBlock.vue';
import WorldBlock from './DashboardBlock/WorldBlock.vue';

export default {
    name: 'DashboardComponent',
    emits: ['more'],
    setup(props, { emit }) {
        const onMore = (menu) => {
            emit('more', menu);
        }

        return {
            onMore,
        }
    },
    components: { FavoritBlock, MissionBlock, VisitorBlock, FriendBlock, RewardBlock, FavoritFriendBlock, WorldBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 40px;
}

@media (min-width: 100px) and (max-width: 767px) {
    .body-section {
        margin: 0;
    }

    
}

@media (min-width:768px) {
    .body-section {
        display: grid;
        grid-template-areas: 
        "a a e"
        "b g c"
        "d d f";
        grid-template-columns: 365px 365px auto;
        grid-template-rows: 220px 200px auto;
        column-gap: 40px;
        row-gap: 40px;
    }

    #favorit {
        grid-area: a;
    }

    #mission {
        grid-area: b;
    }

    #visitor {
        grid-area: c;
    }

    #friend {
        grid-area: d;
    }

    #reward {
        grid-area: e;
    }

    #favfriend {
        grid-area: f;
    }

    #worldissue {
        grid-area: g;
    }
}

</style>