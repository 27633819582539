<template>
    <div class="body-section">
        <div class="block-container">
            <FriendChatBlock id="friendChat" />
            <FriendBlock id="friend" @more="onMore"/>
            <FavoritBlock id="favorit" />
            <FavoritFriendBlock if="favoritFriend" />
        </div>
    </div>
</template>

<script>
import FavoritBlock from './FriendBlock/FavoritBlock.vue';
import FriendBlock from './FriendBlock/FriendBlock.vue';
import FavoritFriendBlock from './FriendBlock/FavoritFriendBlock.vue'
import FriendChatBlock from './FriendBlock/FriendChatBlock.vue'

export default {
    name: 'FriendComponent',
    emits: ['more'],
    setup(pops, { emit }) {
        const onMore = function (menu) {
            emit('more', menu);
        }

        return {
            onMore,
        }
    },
    components: { FriendBlock, FavoritBlock, FavoritFriendBlock, FriendChatBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.block-container {
    display: grid;
}

@media (min-width: 100px) and (max-width: 767px) {
    .body-section {
        box-sizing: border-box;
        padding: 40px 0px;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .block-container {
        display: grid;
        grid-template-areas: 
            "a"
            "c"
            "d"
            "b";
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        row-gap: 40px;
        
    }

    #friendChat {
        grid-area: a;
    }

    #friend {
        grid-area: b;
    }

    #favorit {
        grid-area: c;
    }

    #favoritFriend {
        grid-area: d;
    }
}

@media (min-width:768px) {
    .body-section {
        min-width: 1100px;
    }

    .block-container {
        grid-template-columns: 30% 40%;
        grid-template-rows: 170px 230px 1fr 1fr;
        padding: 0px 5.882vw 0px 40px;
        column-gap: 40px;
        row-gap: 40px;
        grid-template-areas:
        "b d"
        "c d"
        "c e"
        "c e";
    }

    #profile {
        grid-area: a;
    }

    #friendchat {
        grid-area: b;
    }

    #friend {
        grid-area: c;
    }

    #favorit {
        grid-area: d;
    }

    #favoritfriend {
        grid-area: e;
    }
}

</style>