<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mylamp.png">
            <label>{{ store.getters.isEnglish ? 'Mission Stamp' : '미션 스탬프' }}</label>
        </div>

        <div class="date-section">
            <ul>
                <li :class="{'dateActive': state.selected == 0}" @click="onClickDate(0)">10/2 월</li>
                <li :class="{'dateActive': state.selected == 1}" @click="onClickDate(1)">10/3 화</li>
                <li :class="{'dateActive': state.selected == 2}" @click="onClickDate(2)">10/4 수</li>
                <li :class="{'dateActive': state.selected == 3}" @click="onClickDate(3)">10/5 목</li>
            </ul>
        </div>

        <ul class="stamp-list">
            <li v-for="(item, index) in state.list" :key="index" @click="onShowDetail(item.id)">
                <div class="stamp-item">
                    <div class="image-box">
                        <img :src="item.fileUrl" />
                    </div>
                    <label :innerHTML="getMissionTitle(item.missionId)"></label>
                    <img v-if="item.certified == 1" src="/img/medal.png" />
                    <label v-if="item.certified == 0" class="waiting">{{ store.getters.isEnglish ? 'Awaiting approval' : '승인 대기중' }}</label>
                    <label v-if="item.certified == -1" class="returned">{{ store.getters.isEnglish ? 'Try Again!' : '다시 도전해봐!' }}</label>
                </div>
            </li>
        </ul>

        <MissionDetailBlock ref="detail" />
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from 'axios'
import missionData from '../MissionData';
import { useStore } from 'vuex';
import MissionDetailBlock from './MissionDetailBlock.vue';

export default {
    name: 'StampBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            selected: 0,
            missionItems: missionData,
        });
        const detail = ref();
        const onClickDate = (select) => {
            state.selected = select;
        };
        const getMission = (id) => {
            return state.missionItems.find((v) => v.id == id);
        };
        const getMissionTitle = (id) => {
            const mission = getMission(id);
            if (!mission)
                return '';
            return store.getters.isEnglish ? mission.eng : mission.kor;
        };
        const initPage = () => {
            axios.get('/mission/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
                .then((res) => {
                if (res.data?.result || false) {
                    state.list = res.data.data;
                }
            });
        };
        const onShowDetail = function (id) {
            detail.value.onShow(id);
        };
        return {
            store,
            state,
            detail,
            onClickDate,
            initPage,
            getMission,
            getMissionTitle,
            onShowDetail,
        };
    },
    components: { MissionDetailBlock }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        margin-top: 40px;
    }

    .title-section {
        display: none;
    }

    .date-section {
        
    }

    .date-section > ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        width: 100%;
        white-space: nowrap;
        overflow: auto;
    }

    .date-section > ul::-webkit-scrollbar {
        display: none;
    }

    .date-section > ul > li {
        background-color: #F5F7F9;
        padding: 8px 10px;
        border-radius: 10px;
        font-size: 16px;
        color: #69828C;
        margin-right: 10px;
        font-size: 12px;
        
    }

    .dateActive {
        background-color: #84CAFF !important;
        color: white !important;
    }

    .stamp-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 40px;
        overflow: auto;
        width: 100%;
        justify-content: space-between;
    }

    .stamp-list > li {
        width: 110px;
        height: 160px;
        margin:auto;
        background-color: #F5F7F9;
        position: relative;
        margin-bottom: 30px;
        border-radius: 15px;
        box-shadow: 4.8px 4.8px 15.8px rgba(65,91,114,0.2);
    }

    .stamp-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .stamp-item > img {
        width: 60px;
    }

    .stamp-item > label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 18px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .date-section {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .date-section > ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
    }

    .date-section > ul > li {
        background-color: #F5F7F9;
        padding: 8px 15px;
        border-radius: 10px;
        font-size: 16px;
        color: #69828C;
        margin-left: 20px;
    }

    .dateActive {
        background-color: #84CAFF !important;
        color: white !important;
    }

    .stamp-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 80px;
        overflow: auto;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .stamp-list > li {
        width: 20.5%;
        height: 250px;
        background-color: #F5F7F9;
        position: relative;
        margin-right: 50px;
        margin-bottom: 50px;
        border-radius: 15px;
        box-shadow: 10px 10px 15px rgba(65,91,114,0.2);
        margin-top: 5px;
    }

    .stamp-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .stamp-item > .image-box {
        max-height: 150px;
        width: 100%;
        overflow: hidden;
    }
    .stamp-item > .image-box > img {
        height: 150px;
        object-fit: cover;
    }

    .stamp-item > label {
        display: block;
        font-size: 22px;
        font-weight: 500;
        margin-top: 20px;
    }

    .stamp-item > img {
        position: absolute;
        top: 0px;
        right: -10px;
        z-index: 10;
    }

    .stamp-item > .waiting {
        position: absolute;
        z-index: 10;
        top: 30%;
        width: 100%;
        display: block;
        background-color: #84CAFF;
        color: white;
        font-weight: 700;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .stamp-item > .returned {
        position: absolute;
        z-index: 10;
        top: 30%;
        width: 100%;
        display: block;
        background-color: #ff8484;
        color: white;
        font-weight: 700;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
</style>