<template>
    <div class="component-section">
        <div class="title-section">
            <img src="@/assets/compass.png">
            <span>{{ strings.sectionTitle[store.getters.isEnglish ? 1 : 0] }}</span>
            <div class="button-box">
                <a href="#" @click="onClickShowGuide">{{store.getters.isEnglish ? 'Mission Guide' : '미션 참가안내'}}</a>
                <a href="https://zep.us/play/2ewZKb" target="_blank">{{store.getters.isEnglish ? 'Mission Home' : '미션 홈'}}</a>
                <a href="#" @click="onClickShowCerti">{{store.getters.isEnglish ? 'Certification' : '미션인증기준'}}</a>
            </div>
        </div>

        <div class="daily-section-group">
            <div class="daily-section">
                <h1 class="day1">{{ strings.day1Title[store.getters.isEnglish] }}</h1>
                <ul id="day1" name="mission-list">
                    <li v-for="(item, index) in state.missionItems.filter((v) => v.id >= 10 && v.id <= 19)" :key="index" @click="onClickMission('1002',item)">
                        <img :src="'/img/homemission/'+item.img">
                        <span :innerHTML="store.getters.isEnglish ? item.eng : item.kor"></span>
                    </li>
                </ul>
                <img id="day1scroll" @click="onClickScroll('day1')" src="/img/scroll.png" />
                <img id="day1scrollBack" @click="onClickScrollBack('day1')" src="/img/scrollBack.png" />
            </div>
            <div class="daily-section">
                <h1 class="day2">{{ strings.day2Title[store.getters.isEnglish] }}</h1>
                <ul id="day2" name="mission-list">
                    <li v-for="(item, index) in state.missionItems.filter((v) => v.id >= 20 && v.id <= 29)" :key="index" @click="onClickMission('1003',item)">
                        <img :src="'/img/homemission/'+item.img">
                        <span :innerHTML="store.getters.isEnglish ? item.eng : item.kor"></span>
                    </li>
                </ul>
                <img id="day2scroll" @click="onClickScroll('day2')" src="/img/scroll.png" />
                <img id="day2scrollBack" @click="onClickScrollBack('day2')" src="/img/scrollBack.png" />
            </div>
            <div class="daily-section">
                <h1 class="day3">{{ strings.day3Title[store.getters.isEnglish] }}</h1>
                <ul id="day3" name="mission-list">
                    <li v-for="(item, index) in state.missionItems.filter((v) => v.id >= 30 && v.id <= 39)" :key="index" @click="onClickMission('1004',item)">
                        <img :src="'/img/homemission/'+item.img">
                        <span :innerHTML="store.getters.isEnglish ? item.eng : item.kor"></span>
                    </li>
                </ul>
                <img id="day3scroll" @click="onClickScroll('day3')" src="/img/scroll.png" />
                <img id="day3scrollBack" @click="onClickScrollBack('day3')" src="/img/scrollBack.png" />
            </div>
            <div class="daily-section">
                <h1 class="day4">{{ strings.day4Title[store.getters.isEnglish] }}</h1>
                <ul id="day4" name="mission-list">
                    <li v-for="(item, index) in state.missionItems.filter((v) => v.id >= 40 && v.id <= 49)" :key="index" @click="onClickMission('1005',item)">
                        <img :src="'/img/homemission/'+item.img">
                        <span :innerHTML="store.getters.isEnglish ? item.eng : item.kor"></span>
                    </li>
                </ul>
                <img id="day4scroll" @click="onClickScroll('day4')" src="/img/scroll.png" />
                <img id="day4scrollBack" @click="onClickScrollBack('day4')" src="/img/scrollBack.png" />
            </div>
            <div class="daily-section">
                <h1 class="day5">{{ strings.day5Title[store.getters.isEnglish] }}</h1>
                <ul id="day5" name="mission-list">
                    <li v-for="(item, index) in state.missionItems.filter((v) => v.id >= 50 && v.id <= 59)" :key="index" @click="onClickMission('1006',item)">
                        <img :src="'/img/homemission/'+item.img">
                        <span :innerHTML="store.getters.isEnglish ? item.eng : item.kor"></span>
                    </li>
                </ul>
                <img id="day5scroll" @click="onClickScroll('day5')" src="/img/scroll.png" />
                <img id="day5scrollBack" @click="onClickScrollBack('day5')" src="/img/scrollBack.png" />
            </div>
        </div>

        <div v-if="state.showGuide" class="modalFull" @click="onClickCloseNotice">
            <div class="modal" @click="onClickModal">
                <h1>{{store.getters.isEnglish ? 'Mission Guide' : '미션 참가 안내'}}</h1>
                <img :src="store.getters.isEnglish ? state.engGuide : state.korGuide" />
            </div>
            <button @click="onClickCloseNotice">X</button>
        </div>

        <div v-if="state.showCerti" class="modalFull" @click="onClickCloseCerti">
            <div class="modal" @click="onClickModal">
                <h1>{{store.getters.isEnglish ? 'Mission Certification' : '미션인증기준'}}</h1>
                <img :src="store.getters.isEnglish ? state.engCerti : state.korCerti" />
            </div>
            <button @click="onClickCloseCerti">X</button>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import missionDescript from '../main/MissionDescipt';
import missionItem from '../mypage/MissionData';
import { useStore } from 'vuex';

export default {
    name: 'OnlineMissionComponent',
    emits: ['click'],
    mounted() {
        const day1 = document.querySelector('#day1');
        const day1scroll = document.querySelector('#day1scroll');
        const day1scrollBack = document.querySelector('#day1scrollBack');
        if(day1.clientWidth < day1.scrollWidth) {
            day1.addEventListener('scroll', function () {
                day1scroll.style.display = day1.scrollLeft == (day1.scrollWidth - day1.clientWidth) ? 'none' : 'block';
                day1scrollBack.style.display = day1.scrollLeft == 0 ? 'none' : 'block';
            }, {passive: true});
            day1scroll.style.display = 'block';
        } else {
            day1scroll.style.display = 'none';
        }

        const day2 = document.querySelector('#day2');
        const day2scroll = document.querySelector('#day2scroll');
        const day2scrollBack = document.querySelector('#day2scrollBack');
        if(day2.clientWidth < day2.scrollWidth) {
            day2.addEventListener('scroll', function () {
                day2scroll.style.display = day2.scrollLeft == (day2.scrollWidth - day2.clientWidth) ? 'none' : 'block';
                day2scrollBack.style.display = day2.scrollLeft == 0 ? 'none' : 'block';
            }, {passive: true});
            day2scroll.style.display = 'block';
        } else {
            day2scroll.style.display = 'none';
        }

        const day3 = document.querySelector('#day3');
        const day3scroll = document.querySelector('#day3scroll');
        const day3scrollBack = document.querySelector('#day3scrollBack');
        if(day3.clientWidth < day3.scrollWidth) {
            day3.addEventListener('scroll', function () {
                day3scroll.style.display = day3.scrollLeft == (day3.scrollWidth - day3.clientWidth) ? 'none' : 'block';
                day3scrollBack.style.display = day3.scrollLeft == 0 ? 'none' : 'block';
            }, {passive: true});
            day3scroll.style.display = 'block';
        } else {
            day3scroll.style.display = 'none';
        }

        const day4 = document.querySelector('#day4');
        const day4scroll = document.querySelector('#day4scroll');
        const day4scrollBack = document.querySelector('#day4scrollBack');
        if(day4.clientWidth < day4.scrollWidth) {
            day4.addEventListener('scroll', function () {
                day4scroll.style.display = day4.scrollLeft == (day4.scrollWidth - day4.clientWidth) ? 'none' : 'block';
                day4scrollBack.style.display = day4.scrollLeft == 0 ? 'none' : 'block';
            }, {passive: true});
            day4scroll.style.display = 'block';
        } else {
            day4scroll.style.display = 'none';
        }

        const day5 = document.querySelector('#day5');
        const day5scroll = document.querySelector('#day5scroll');
        const day5scrollBack = document.querySelector('#day5scrollBack');
        if(day5.clientWidth < day5.scrollWidth) {
            day5.addEventListener('scroll', function () {
                day5scroll.style.display = day5.scrollLeft == (day5.scrollWidth - day5.clientWidth) ? 'none' : 'block';
                day5scrollBack.style.display = day5.scrollLeft == 0 ? 'none' : 'block';
            }, {passive: true});
            day5scroll.style.display = 'block';
        } else {
            day5scroll.style.display = 'none';
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const strings = reactive({
            sectionTitle: [
                'Daily 미션',
                'Daily Mission',
            ],
            day1Title: [
                '10/2 월요일',
                '10/2 Mon'
            ],
            day2Title: [
                '10/3 화요일',
                '10/3 Tue'
            ],
            day3Title: [
                '10/4 수요일',
                '10/4 Wed'
            ],
            day4Title: [
                '10/5 목요일',
                '10/5 Thu'
            ],
            day5Title: [
                '10/6 금요일',
                '10/6 Fri'
            ],
        })
        const state = reactive({
            missionItems: missionItem,
            missionDesc: missionDescript,
            selectedItem: null,
            showGuide: false,
            showCerti: false,
            engGuide: 'https://girlscout2023.s3.ap-northeast-2.amazonaws.com/notice/joinguide_eng.jpg',
            korGuide: 'https://girlscout2023.s3.ap-northeast-2.amazonaws.com/notice/joinguide_kor.jpg',
            engCerti: 'https://girlscout2023.s3.ap-northeast-2.amazonaws.com/notice/engCerti.jpg',
            korCerti: 'https://girlscout2023.s3.ap-northeast-2.amazonaws.com/notice/korCerti.jpg',
        })

        const onClickMission = (date, item) => {
            //state.selectedItem = item;
            const desc = state.missionDesc.find((v) => v.id == item.id);
            emit('click', {date, item: desc});
        }

        const onCloseModal = () => {
            state.selectedItem = null;
        }

        const  onClickScroll = (id) => {
            const ul = document.querySelector(`#${id}`);
            ul.scrollTo({
                left: ul.scrollWidth, 
                top: 0,
                behavior: 'smooth',
            });
        }

        const onClickScrollBack = (id) => {
            const ul = document.querySelector(`#${id}`);
            ul.scrollTo({
                left: 0, 
                top: 0,
                behavior: 'smooth',
            });
        }

        const onClickShowGuide = () => {
            state.showGuide = true;
        }

        const onClickShowCerti = () => {
            state.showCerti = true;
        }

        const onClickCloseNotice = () => {
            state.showGuide = false;
        }

        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onClickCloseCerti = () => {
            state.showCerti = false;
        }

        return {
            store,
            strings,
            state,
            onCloseModal,
            onClickMission,
            onClickScroll,
            onClickScrollBack,
            onClickShowGuide,
            onClickCloseNotice,
            onClickModal,
            onClickCloseCerti,
            onClickShowCerti,
        }
    }
}
</script>


<style scoped>
.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden;
}

.day1 {
    background-color: #FFB500 !important;
}
.day2 {
    background-color: #4AB4C2 !important;
}
.day3 {
    background-color: #FF8550 !important;
}
.day4 {
    background-color: #1C2D7F !important;
}

.day5 {
    background-color: #419E10 !important;
}

@media (min-width: 100px) and (max-width: 767px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .title-section > img {
        width: 15vw;
        margin-top: 0px;
        margin-right: 7px;
    }

    .title-section > span {
        display: block;
        font-size: 8vw;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .title-section > .button-box {
        display: block;
        width: 100%;
    }

    .title-section > .button-box > a {
        display: block;
        width: 80%;
        background-color: #84CAFF;
        color: white;
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 10px;
        height: 30px;
        margin-left: 30px;
        margin-top: 10px;
    }
    
    .daily-section {
        margin-top: 40px;
    }

    .daily-section > img {
        display: none !important;
    }

    .daily-section > h1 {
        font-size: 18px;
        font-weight: 500;
        color: white;
        background-color: lightgray;
        padding: 7px 15px;
        box-sizing: border-box;
        text-align: center;
        width: 130px;
        height: 36px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        margin-bottom: 10px;
    }

    .daily-section-group {
        flex-wrap: wrap;
    }

    .daily-section > ul {
        display: flex;
        list-style: none;
        width:80%;
        padding: 0;
        margin: 0;
        overflow-x: auto;
        scrollbar-width: none;
        margin-left: 10%;
    }

    .daily-section > ul::-webkit-scrollbar {
        display: none;
    }

    .daily-section > ul > li > img {
        width: 80px;
        height: 90px;
    }

    .daily-section > ul > li > span {
        display: block;
        margin-top: 5px;
    }

    .daily-section > ul > li {
        text-align: center;
        width: 120px;
        height: 175px;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        margin-right: 10px;
    }

    .daily-section > ul > li:last-child {
        margin-right: 0;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 95%;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 5px;
        text-align: center;

        overflow: auto;
    }

    .modal::-webkit-scrollbar {
        display: none;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }

    .modal > h1 {
        font-size: 20px;
    }

    .modal > img {
        width: 100%;
    }
}

@media (min-width:768px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 72px;
        margin-top: 0px;
        margin-right: 12px;
    }

    .title-section > span {
        display: block;
        font-size: 48px;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .title-section > .button-box {
        display: flex;
    }

    .title-section > .button-box > a {
        display: block;
        background-color: #84CAFF;
        color: white;
        font-size: 32px;
        font-weight: 700;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 10px;
        height: 40px;
        margin-left: 30px;
        margin-top: 10px;
    }

    .daily-section > h1 {
        font-size: 18px;
        font-weight: 500;
        color: white;
        background-color: lightgray;
        padding: 7px 15px;
        box-sizing: border-box;
        text-align: center;
        width: 130px;
        height: 36px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 7px;
        margin-bottom: 10px;
    }

    .daily-section-group {
        flex-wrap: wrap;
        display: flex;
        width: 70vw;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
    }

    .daily-section {
        width: 35vw;
        position: relative;
        margin-bottom: 50px;
    }

    .daily-section > ul {
        display: flex;
        list-style: none;
        width:80%;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        margin-left: 10%;
        margin-top: 20px;
    }

    .daily-section > ul::-webkit-scrollbar {
        display: none;
    }

    .daily-section > ul > li {
        text-align: center;
        width: 140px;
        height: 175px;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        margin-right: 10px;
    }

    .daily-section > ul > li > img {
        width: 80px;
        height: 90px;
    }

    .daily-section > ul > li > span {
        display: block;
        margin-top: 5px;
        width: 100px;
    }

    .daily-section > ul > li:last-child {
        margin-right: 0;
    }

    

    .button-section {
        margin-top: 30px;
    }

    .button-section > button {
        border: none;
        margin-right: 20px;
        padding: 10px 20px;
        border-radius: 10px;
    }

    .button-section > button:last-child {
        background-color: #84CAFF;
        color: white;
        margin-right: 0;
    }

    #day1scroll, #day2scroll, #day3scroll, #day4scroll, #day5scroll {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(calc(-50% + 10px), calc(-50% + 22px));
        box-shadow: 0px 0px 20px rgba(65,91,114,0.5);
        border-radius: 100px;
    }

    #day1scrollBack, #day2scrollBack, #day3scrollBack, #day4scrollBack, #day5scrollBack {
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translate(calc(-50% + 10px), calc(-50% + 22px));
        box-shadow: 0px 0px 20px rgba(65,91,114,0.5);
        border-radius: 100px;
        display: none;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 1000px;
        height: 620px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;

        overflow: auto;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }
}

</style>