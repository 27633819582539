import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from '@/store'
import VueCookies from 'vue3-cookies'
import mitt from 'mitt'

const app = createApp(App);
const emitter = mitt();

window.app = app;

app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});


//axios.defaults.baseURL = 'http://localhost:3300';
axios.defaults.baseURL = 'https://ic.girlscout.or.kr:5443';    

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response=> response,
    error =>{
      if (error.response.status == 401){
        store.dispatch('logout');
        router.push('/login');
      }
      return Promise.reject(error)
    }
);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
app.config.globalProperties.$route = router;
app.config.globalProperties.emitter = emitter;

app.use(router);
app.use(store);

app.mount('#app');