const missionFlatData = [
    {
        id: 11,
        kor: '개영식',
        eng: 'Opening Ceremony',
        img: 'opening.png',
        point: 10,
    },
    {
        id: 12,
        kor: 'Self 스크린타임',
        eng: 'Self Screen Time',
        img: 'screentime.png',
        point: 10,
    },
    {
        id: 13,
        kor: 'Guess Who?',
        eng: 'Guess Who?',
        img: 'guesswho.png',
        point: 10,
    },
    {
        id: 14,
        kor: 'Today 퀘스트',
        eng: 'Today Quest',
        img: 'todayquest.png',
        point: 3,
    },
    {
        id: 21,
        kor: '나만의 해피박스',
        eng: 'Happy Box',
        img: 'happybox.png',
        point: 10,
    },
    {
        id: 22,
        kor: '미니요가',
        eng: 'Mini yoga',
        img: 'miniyoga.png',
        point: 10,
    },
    {
        id: 23,
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        img: 'friendchat.png',
        point: 10,
    },
    {
        id: 24,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        img: 'goldenbell.png',
        point: 10,
    },
    {
        id: 25,
        kor: 'Today Quest',
        eng: 'Today Quest',
        img: 'todayquest.png',
        point: 3,
    },
    {
        id: 31,
        kor: '언니들의 백패킹',
        eng: 'Sisters Backpacking',
        img: 'backpacking.png',
        point: 10,
    },
    {
        id: 32,
        kor: 'Guide Your Money',
        eng: 'Guide Your Money',
        img: 'guidemoney.png',
        point: 10,
    },
    {
        id: 33, 
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        img: 'friendchat.png',
        point: 10,
    },
    {
        id: 34,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        img: 'goldenbell.png',
        point: 10,
    },
    {
        id: 35,
        kor: 'Today Quest',
        eng: 'Today Quest',
        img: 'todayquest.png',
        point: 3,
    },
    {
        id: 41,
        kor: '금쪽이들의 뮤직큐',
        eng: 'Music Q',
        img: 'musicq.png',
        point: 10,
    },
    {
        id: 42,
        kor: '디지털 발자국',
        eng: 'Digital Footprint',
        img: 'digitalstamp.png',
        point: 10,
    },
    {
        id: 43,
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        img: 'friendchat.png',
        point: 10,
    },
    {
        id: 44,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        img: 'goldenbell.png',
        point: 10,
    },
    {
        id: 45,
        kor: 'Today Quest',
        eng: 'Today Quest',
        img: 'todayquest.png',
        point: 3,
    },
    {
        id: 51,
        kor: 'International Day',
        eng: 'International Day',
        img: 'closing.png',
        point: 10,
    },
    /*{
        id: 52,
        kor: '오프라인 미션',
        eng: 'Offline Mission',
        img: 'offline.png',
        point: 10,
    },*/
]

export default missionFlatData;
