<template>
    <div class="component-section">
        <div class="title-section">
            <span>{{ strings.sectionTitle1[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        <div class="sponsor-section">
            <ul>
                <li>
                    <img src="@/assets/logos/girlscout.jpeg" style="" class="gs" />
                </li>
                
            </ul>
        </div>

        <div class="title-section">
            <span>{{ strings.sectionTitle2[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        <div class="sponsor-section">
            <ul>
                <li>
                    <img src="/img/logos/MGEF.jpeg" style="" class="mgef" />
                </li>
            </ul>
        </div>

        <div class="title-section">
            <span>{{ strings.sectionTitle3[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        <div class="sponsor-section">
            <ul>
                <li>
                    <img src="/img/logos/kmwa.png" style="" class="kmwa" />
                </li>
                <li>
                    <img src="/img/logos/yonginsbh.png" style="" class="youngin" />
                </li>
            </ul>
        </div>

        <div class="title-section">
            <span>{{ strings.sectionTitle4[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        <div class="sponsor-section">
            <ul>
                <li>
                    <img src="/img/logos/hyundae.png" style="" class="hyundai"/>
                </li>
                <li>
                    <img src="/img/logos/orion.jpg" style="" class="orion"/>
                </li>
                <li>
                    <img src="/img/logos/ediya.png" class="ediya"/>
                </li>
                <li>
                    <img src="/img/logos/hanabank.png" style="" class="hanabank" />
                </li>
                <li>
                    <img src="/img/logos/jake.png" style="" class="jake" />
                </li>
                <li>
                    <img src="/img/logos/pegion.png" style="" class="pegion" />
                </li>
                <li>
                    <img src="/img/logos/yuhan.png" style="" class="yuhan" />
                </li>
                <li>
                    <img src="/img/logos/gajeul.png" style="" class="gajeul" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'SponsorComponent',
    setup() {
        const store = useStore();
        const strings = reactive({
            isEnglish: 0,
            sectionTitle1: [
                '주최',
                'Host',
            ],
            sectionTitle2: [
                '후원',
                'Sponsorship',
            ],
            sectionTitle3: [
                '지원',
                'Support',
            ],
            sectionTitle4: [
                '협찬',
                'Sponsor',
            ],
        })

        return {
            store,
            strings,
        }
    }
}
</script>


<style scoped>
.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 15vw;
        margin-top: 0px;
        margin-right: 7px;
    }

    .title-section > span {
        display: block;
        font-size: 8vw;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .sponsor-section {
        margin-top: 40px;
    }

    .sponsor-section > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 80%;
        margin-left: 10%;
        list-style: none;
    }

    .sponsor-section > ul > li {
        width: 100%;
        height: 85px;
        background-color: white;
        color: black;
        border-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .sponsor-section > ul > li:nth-child(even) {
        margin-right: 0;
    }

    .sponsor-section > ul > li > img {
        width: 90%;
    }

    .gs {
        margin-top: 15px !important;
        max-height:55px;
        margin-top:20px;
    }

    .mgef {
        margin-top: 15px !important;max-height:55px;margin-top:20px;
    }

    .kmwa {
        margin-top: 5px;max-height:80px !important;margin-top:5px;
        height: 100% !important;
        width: 75px !important;
        
    }

    .youngin {
        margin-top: 10px !important;max-height:80px;margin-top:5px;
    }

    .hyundai {
        margin-top: 5px;max-height:55px;margin-top:20px;
    }

    .orion {
        margin-top: 5px;max-height:55px;margin-top:20px;
    }

    .ediya {
        margin-top: 5px;height:30px;margin-top:30px;
    }

    .hanabank {
        margin-top: 5px;height:50px;margin-top:20px;
    }

    .jake {
        margin-top: 5px;height:30px;margin-top:30px;
    }

    .pegion {
        margin-top: 5px;height:150px;margin-top:-25px;
    }

    .yuhan {
        margin-top: 5px;height:70px;margin-top:10px;
    }

    .gajeul {
        margin-top: 5px;height:70px;margin-top:10px;
    }
}

@media (min-width:768px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 72px;
        margin-top: 0px;
        margin-right: 12px;
    }

    .title-section > span {
        display: block;
        font-size: 48px;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .sponsor-section {
        margin-top: 40px;
    }
    
    .sponsor-section > ul {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 80%;
        margin-left: 10%;
        list-style: none;
        display: flex;
    }

    .sponsor-section > ul > li {
        width: 350px;
        height: 90px;
        background-color: white;
        color: black;
        margin-right: 20px;
        border-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        font-size: 28px;
        overflow: hidden;
    }

    .sponsor-section > ul > li > img {
        height: 80px;
    }

    .gs {
        margin-top: 5px;
        max-height:55px;
        margin-top:20px;
    }

    .mgef {
        margin-top: 5px;max-height:55px;margin-top:20px;
    }

    .kmwa {
        margin-top: 5px;max-height:80px;margin-top:5px;
    }

    .youngin {
        margin-top: 5px;max-height:80px;margin-top:5px;
    }

    .hyundai {
        margin-top: 5px;max-height:55px;margin-top:20px;
    }

    .orion {
        margin-top: 5px;max-height:55px;margin-top:20px;
    }

    .ediya {
        margin-top: 5px;height:30px !important;margin-top:30px;
    }

    .hanabank {
        margin-top: 5px;height:50px !important;margin-top:20px;
    }

    .jake {
        margin-top: 5px;height:30px !important;margin-top:30px;
    }

    .pegion {
        margin-top: 5px;height:150px !important;margin-top:-25px;
    }

    .yuhan {
        margin-top: 5px;height:70px !important;margin-top:10px;
    }

    .gajeul {
        margin-top: 5px;height:70px !important;margin-top:10px;
    }
}

</style>