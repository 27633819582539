<template>
    <div class="block-section">
        <div class="title-section">
            <img src="/img/mypage/worldissue.png">
            <label>{{ store.getters.isEnglish ? 'World Issue Activity' : '세계이슈활동' }}</label>
        </div>

        <ul class="issue-list">
            <li v-for="(item,index) in state.issue" :key="index">
                <img :src="'/img/worldissue/' + item.img" />
                <label>{{ item.title }}</label>
                <div class="blur" :style="item.completed ? 'display:none;':''">
                    <label>{{ item.title }}</label>
                    <a :href="item.link" target="_blank">{{ store.getters.isEnglish ? 'Challenge' : '도전 GO'}}</a>
                </div>
            </li>
            <li class="blank-card">

            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'WorldBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list: [],
            issue: [
                {
                    id: 15,
                    title: 'Safety&Joy',
                    img: 'safety2.png',
                    completed: false,
                    link: 'https://zep.us/@Safety-Joy',
                },
                {
                    id: 7,
                    title: 'SDGs#TeamGirl',
                    img: 'sdg2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 8,
                    title: 'ABC II',
                    img: 'abc2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 13,
                    title: 'Water',
                    img: 'water2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 10,
                    title: 'Forests',
                    img: 'forests2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 11,
                    title: 'Ocean',
                    img: 'ocean2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 12,
                    title: 'Soil',
                    img: 'soil2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                
            ]
        })

        const initPage = () => {
            axios.get('/worldissue/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                    state.list.forEach(e => {
                        const item = state.issue.find((v) => v.id == e.issueId)
                        item.completed = true;
                    });
                }
            })
        }

        const getMission = (id) => {
            const item = state.missionItems.find((v) => v.id == id);

            return store.getters.isEnglish ? item.eng : item.kor;
        }

        return {
            store,
            state,
            initPage,
            getMission,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #F5F7F9;
        border-radius: 10px;
        margin-top: 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > h1 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
        margin-top: 30px;
    }

    .block-section > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 20px;
        box-sizing: border-box;
    }

    .block-section > ul > li {
        display: flex;
        justify-content: space-between;
        height: 60px;
    }

    .block-section > ul > li > .date {
        color: #8F9EA8;
        font-size: 16px;
        font-weight: 500;
        width:80px;
    }

    .block-section > ul > li > .title {
        color: #8F9EA8;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        width: auto;
    }

    .block-section > ul > li > .value {
        color: #84CAFF;
        font-size: 20px;
        font-weight: 500;
        text-align: right;
        width: 50px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 90%;
        height: 100%;
        box-sizing: border-box;
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        margin-left: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 40px 20px 0px 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > .issue-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 30px;
    }

    .block-section > .issue-list > li {
        width: 150px;
        height: 200px;
        text-align: center;
        padding: 20px;
        border-radius: 15px;
        box-sizing: border-box;
        margin-right: 20px;
        background-color: #EFF2F3;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
    }

    .block-section > .issue-list > li:nth-child(6n) {
        margin-right: 0;
    }

    .block-section > .issue-list > li > img {
        margin: 0 auto;
        max-width: 110px;
    }

    .block-section > .issue-list > li > label {
        display: block;
        margin-top: 15px;
    }

    .block-section > .issue-list > li > .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        backdrop-filter: blur(10px);
    }

    .block-section > .issue-list > li > .blur > label {
        font-weight: 700;
        margin-top: 40%;
        display: block;
        height: calc(110px - 30%);
    }

    .block-section > .issue-list > li > .blur > a {
        text-decoration: none;
        background-color: #48B9FF;
        color: white;
        font-weight: 500;
        width: 100px;
        border-radius: 30px;
        display: block;
        padding: 10px 10px;
        margin: auto;
        margin-top: 28px;
    }

    .block-section > .issue-list > .blank-card {
        background-image: url(~@/assets/mypage/favoritgirl.png);
        background-repeat: no-repeat;
        background-position: 100% 100%;
    }
}
</style>