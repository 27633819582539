<template>
    <div class="component-section">
        <div class="title-section">
            <img src="@/assets/compass.png">
            <span>{{ strings.sectionTitle[strings.isEnglish] }}</span>
        </div>

        <ul class="issue-list">
            <li v-for="(item,index) in state.issue" :key="index">
                <img :src="'/img/worldissue/' + item.img" />
                <label>{{ item.title }}</label>
                <div class="blur" :style="item.completed ? 'display:none;':''">
                    <label>{{ item.title }}</label>
                    <a :href="item.link" target="_blank">{{ store.getters.isEnglish ? 'Challenge' : '도전 GO'}}</a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'WorldISsueComponent',
    mounted() {
        this.initPage();
    },
    setup() {
        const strings = reactive({
            isEnglish: 0,
            sectionTitle: [
                '세계이슈활동',
                'World Issue Activity',
            ],
        })

        const store = useStore();
        const state = reactive({
            list: [],
            issue: [
                {
                    id: 15,
                    title: 'Safety&Joy',
                    img: 'safety2.png',
                    completed: false,
                    link: 'https://zep.us/@Safety-Joy',
                },
                {
                    id: 7,
                    title: 'SDGs#TeamGirl',
                    img: 'sdg2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 8,
                    title: 'ABC II',
                    img: 'abc2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 13,
                    title: 'Water',
                    img: 'water2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 10,
                    title: 'Forests',
                    img: 'forests2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 11,
                    title: 'Ocean',
                    img: 'ocean2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                {
                    id: 12,
                    title: 'Soil',
                    img: 'soil2.png',
                    completed: false,
                    link: 'https://www.girlscout.or.kr/WorldAct?subMenu=1#/1',
                },
                
            ]
        })

        const initPage = () => {
            axios.get('/worldissue/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                    state.list.forEach(e => {
                        const item = state.issue.find((v) => v.id == e.issueId)
                        item.completed = true;
                    });
                }
            })
        }

        const getMission = (id) => {
            const item = state.missionItems.find((v) => v.id == id);

            return store.getters.isEnglish ? item.eng : item.kor;
        }

        return {
            store,
            state,
            initPage,
            getMission,
            strings,
        }
    }
}
</script>


<style scoped>
.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 130px;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .component-section {
        width: 90vw;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-left: 5vw;
    }
    .title-section {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: left;
    }

    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .title-section > img {
        width: 15vw;
        margin-top: 0px;
        margin-right: 7px;
    }

    .title-section > span {
        display: block;
        font-size: 8vw;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .component-section > .issue-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 30px;
        justify-content: space-around;
    }

    .component-section > .issue-list > li {
        width: 38.461vw;
        height: 200px;
        text-align: center;
        padding: 20px;
        border-radius: 5px;
        box-sizing: border-box;
        margin-right: 20px;
        background-color: #EFF2F3;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
    }

    .component-section > .issue-list > li:nth-child(2n) {
        margin-right: 0;
    }

    .component-section > .issue-list > li:last-child {
        margin-right: 0;
    }

    .component-section > .issue-list > li > img {
        margin: 0 auto;
        max-width: 110px;
    }

    .component-section > .issue-list > li > label {
        display: block;
        margin-top: 15px;
    }

    .component-section > .issue-list > li > .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        border-radius: 15px;
    }

    .component-section > .issue-list > li > .blur > label {
        font-weight: 700;
        margin-top: 40%;
        display: block;
        height: calc(110px - 30%);
    }

    .component-section > .issue-list > li > .blur > a {
        text-decoration: none;
        background-color: #48B9FF;
        color: white;
        font-weight: 500;
        width: 100px;
        border-radius: 30px;
        display: block;
        padding: 10px 10px;
        margin: auto;
        margin-top: 28px;
    }

    .component-section > .issue-list > .blank-card {
        background-image: url(~@/assets/mypage/favoritgirl.png);
        background-repeat: no-repeat;
        background-position: 100% 100%;
    }

}

@media (min-width:768px) {
    .component-section {
        width: 80%;
        height: 320px;
        margin-left: 10%;
    }

    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 72px;
        margin-top: 0px;
        margin-right: 12px;
    }

    .title-section > span {
        display: block;
        font-size: 48px;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .component-section > .issue-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 30px;
        justify-content: space-around;
    }

    .component-section > .issue-list > li {
        width: 150px;
        height: 200px;
        text-align: center;
        padding: 20px;
        border-radius: 15px;
        box-sizing: border-box;
        margin-right: 20px;
        background-color: #EFF2F3;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
    }

    .component-section > .issue-list > li:last-child {
        margin-right: 0;
    }

    .component-section > .issue-list > li > img {
        margin: 0 auto;
        max-width: 110px;
    }

    .component-section > .issue-list > li > label {
        display: block;
        margin-top: 15px;
    }

    .component-section > .issue-list > li > .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        border-radius: 15px;
    }

    .component-section > .issue-list > li > .blur > label {
        font-weight: 700;
        margin-top: 40%;
        display: block;
        height: calc(110px - 30%);
    }

    .component-section > .issue-list > li > .blur > a {
        text-decoration: none;
        background-color: #48B9FF;
        color: white;
        font-weight: 500;
        width: 100px;
        border-radius: 30px;
        display: block;
        padding: 10px 10px;
        margin: auto;
        margin-top: 28px;
    }

    .component-section > .issue-list > .blank-card {
        background-image: url(~@/assets/mypage/favoritgirl.png);
        background-repeat: no-repeat;
        background-position: 100% 100%;
    }
}

</style>