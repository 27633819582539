<template>
    <div class="body-section">
        <InfoBlock />
        <CertifiedBlock />
    </div>
</template>

<script>
import CertifiedBlock from './SetupBlock/CertifiedBlock.vue';
import InfoBlock from './SetupBlock/InfoBlock.vue';

export default {
    name: 'SetupComponent',
    setup() {
        
    },
    components: { CertifiedBlock, InfoBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {

}

@media (min-width:768px) {

}

</style>