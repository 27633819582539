<template>
    <div class="body-section">
        <VisitorBlock @more="onMore('dashboard')"/>
    </div>
</template>

<script>
import VisitorBlock from './VisitorBlock/VisitorBlock.vue';

export default {
    name: 'VisitorComponent',
    emits: ['more'],
    setup(props, { emit }) {
        const onMore = (menu) => {
            emit('more', menu);
        }

        return {
            onMore,
        }
    },
    components: { VisitorBlock }
}
</script>


<style scoped>
.body-section {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {

}

@media (min-width:768px) {

}

</style>