<template>
    <div class="block-section">
        <table class="statistic">
            <thead>
                <tr>
                    <th rowspan="2" style="width:100px;text-align: center;">일자</th>
                    <!--th>로그인</th-->
                    <th colspan="4">미션</th>
                    <th rowspan="2">방명록</th>
                    <th rowspan="2">댓글</th>
                </tr>
                <tr>
                    <th>대기</th>
                    <th>승인</th>
                    <th>반려</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>10-02</td>
                    <!--td>{{ state.list1002[0]?.loginCnt }}회 / {{ state.list1002[0]?.loginUsers }}명</td-->
                    <!--td>{{ state.list1002[0]?.missionCnt }}회 / {{ state.list1002[0]?.missionUsers }}명</td-->
                    <td>{{ state.mission1002[0]?.watingCnt }}</td>
                    <td>{{ state.mission1002[0]?.certifiedCnt }}</td>
                    <td>{{ state.mission1002[0]?.returnedCnt }}</td>
                    <td>{{ state.mission1002[0]?.deletedCnt }}</td>
                    <td>{{ state.list1002[0]?.visitorCnt }}회 / {{ state.list1002[0]?.visitorUsers }}명</td>
                    <td>{{ state.list1002[0]?.replyCnt }}회 / {{ state.list1002[0]?.replyUsers }}명</td>
                </tr>
                <tr>
                    <td>10-03</td>
                    <!--td>{{ state.list1003[0]?.loginCnt }}회 / {{ state.list1003[0]?.loginUsers }}명</td-->
                    <!--td>{{ state.list1003[0]?.missionCnt }}회 / {{ state.list1003[0]?.missionUsers }}명</td-->
                    <td>{{ state.mission1003[0]?.watingCnt }}</td>
                    <td>{{ state.mission1003[0]?.certifiedCnt }}</td>
                    <td>{{ state.mission1003[0]?.returnedCnt }}</td>
                    <td>{{ state.mission1003[0]?.deletedCnt }}</td>
                    <td>{{ state.list1003[0]?.visitorCnt }}회 / {{ state.list1003[0]?.visitorUsers }}명</td>
                    <td>{{ state.list1003[0]?.replyCnt }}회 / {{ state.list1003[0]?.replyUsers }}명</td>
                </tr>
                <tr>
                    <td>10-04</td>
                    <!--td>{{ state.list1004[0]?.loginCnt }}회 / {{ state.list1004[0]?.loginUsers }}명</td-->
                    <!--td>{{ state.list1004[0]?.missionCnt }}회 / {{ state.list1004[0]?.missionUsers }}명</td-->
                    <td>{{ state.mission1004[0]?.watingCnt }}</td>
                    <td>{{ state.mission1004[0]?.certifiedCnt }}</td>
                    <td>{{ state.mission1004[0]?.returnedCnt }}</td>
                    <td>{{ state.mission1004[0]?.deletedCnt }}</td>
                    <td>{{ state.list1004[0]?.visitorCnt }}회 / {{ state.list1004[0]?.visitorUsers }}명</td>
                    <td>{{ state.list1004[0]?.replyCnt }}회 / {{ state.list1004[0]?.replyUsers }}명</td>
                </tr>
                <tr>
                    <td>10-05</td>
                    <!--td>{{ state.list1005[0]?.loginCnt }}회 / {{ state.list1005[0]?.loginUsers }}명</td-->
                    <!--td>{{ state.list1005[0]?.missionCnt }}회 / {{ state.list1005[0]?.missionUsers }}명</td-->
                    <td>{{ state.mission1005[0]?.watingCnt }}</td>
                    <td>{{ state.mission1005[0]?.certifiedCnt }}</td>
                    <td>{{ state.mission1005[0]?.returnedCnt }}</td>
                    <td>{{ state.mission1005[0]?.deletedCnt }}</td>
                    <td>{{ state.list1005[0]?.visitorCnt }}회 / {{ state.list1005[0]?.visitorUsers }}명</td>
                    <td>{{ state.list1005[0]?.replyCnt }}회 / {{ state.list1005[0]?.replyUsers }}명</td>
                </tr>
                <tr>
                    <td>10-06</td>
                    <!--td>{{ state.list1006[0]?.loginCnt }}회 / {{ state.list1006[0]?.loginUsers }}명</td-->
                    <!--td>{{ state.list1006[0]?.missionCnt }}회 / {{ state.list1006[0]?.missionUsers }}명</td-->
                    <td>{{ state.mission1006[0]?.watingCnt }}</td>
                    <td>{{ state.mission1006[0]?.certifiedCnt }}</td>
                    <td>{{ state.mission1006[0]?.returnedCnt }}</td>
                    <td>{{ state.mission1006[0]?.deletedCnt }}</td>
                    <td>{{ state.list1006[0]?.visitorCnt }}회 / {{ state.list1006[0]?.visitorUsers }}명</td>
                    <td>{{ state.list1006[0]?.replyCnt }}회 / {{ state.list1006[0]?.replyUsers }}명</td>
                </tr>
            </tbody>
        </table>

        <button class="showDetail" @click="state.showDetailStatistic = true">미션별 통계보기</button>

        <div v-if="state.showDetailStatistic" class="modalFull" @click="onClickClosModal">
            <div class="modal" @click="onClickModal">
                <table>
                    <thead>
                        <tr>
                            <th>일자</th>
                            <th>미션명</th>
                            <th>대기</th>
                            <th>승인</th>
                            <th>반려</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in state.missionDetail" :key="index">
                            <td>{{ item.date }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.watingCnt }}</td>
                            <td>{{ item.certifiedCnt }}</td>
                            <td>{{ item.returnedCnt }}</td>
                            <td>{{ item.deletedCnt }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="button-section">
                    <button class="ok" @click="onExport">엑셀추출</button>
                    <button class="cancel" @click="onClickClosModal">닫기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';
import missionDescript from '@/pages/main/MissionDescipt';
import * as xlsx from 'xlsx';

export default {
    name: 'InfoBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list1002: [],
            list1003: [],
            list1004: [],
            list1005: [],
            list1006: [],

            mission1002: [],
            mission1003: [],
            mission1004: [],
            mission1005: [],
            mission1006: [],

            missionDetail: [],

            showDetailStatistic: false,
        })

        const initPage = () => {
            axios.get('/user/statistic/2')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list1002 = res.data.data;
                }
            })
            axios.get('/user/statistic/3')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list1003 = res.data.data;
                }
            })
            axios.get('/user/statistic/4')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list1004 = res.data.data;
                }
            })
            axios.get('/user/statistic/5')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list1005 = res.data.data;
                }
            })
            axios.get('/user/statistic/6')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list1006 = res.data.data;
                }
            })

            axios.get('/mission/statistic/2')
            .then((res) => {
                if(res.data?.result || false) {
                    state.mission1002 = res.data.data;
                }
            })
            axios.get('/mission/statistic/3')
            .then((res) => {
                if(res.data?.result || false) {
                    state.mission1003 = res.data.data;
                }
            })
            axios.get('/mission/statistic/4')
            .then((res) => {
                if(res.data?.result || false) {
                    state.mission1004 = res.data.data;
                }
            })
            axios.get('/mission/statistic/5')
            .then((res) => {
                if(res.data?.result || false) {
                    state.mission1005 = res.data.data;
                }
            })
            axios.get('/mission/statistic/6')
            .then((res) => {
                if(res.data?.result || false) {
                    state.mission1006 = res.data.data;
                }
            })

            axios.get('/mission/statistic2')
            .then((res) => {
                if(res.data.result) {
                    state.missionDetail = res.data.data.map((v) => {
                        const mission = missionDescript.find((f) => f.id == v.missionId);
                        
                        let date = '';
                        if(v.missionId >= 10 && v.missionId <= 19) date = '10월2일';
                        if(v.missionId >= 20 && v.missionId <= 29) date = '10월3일';
                        if(v.missionId >= 30 && v.missionId <= 39) date = '10월4일';
                        if(v.missionId >= 40 && v.missionId <= 49) date = '10월5일';
                        if(v.missionId >= 50 && v.missionId <= 59) date = '10월6일';

                        return {
                            date: date,
                            name: mission ? mission.kor : 'Today Quest',
                            ...v,
                        }
                    });
                }
            })
        }

        const onClickClosModal = (e) => {
            e.stopPropagation();
            state.showDetailStatistic = false;
        }
        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onExport = () => {
            const worksheet = xlsx.utils.json_to_sheet(state.missionDetail);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet');
            xlsx.writeFile(workbook, 'mission_statistic.xlsx');

            alert('저장했습니다.');
        }

        return {
            store,
            state,
            initPage,
            onClickClosModal,
            onClickModal,
            onExport,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-bottom: 40px;
    }
}

.statistic {
    margin-top: -10px;
}

.statistic > tbody > tr > td {
    text-align: center;
    width: 60px;
}

.statistic > tbody > tr > td:first-child {
    width: 70px;
}

.statistic > tbody > tr > td:nth-child(6) {
    width: 170px;
}

.statistic > tbody > tr > td:nth-child(7) {
    width: 170px;
}

.modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 1000;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 750px;
        height: 800px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;
    }

    .modal > table {
        width: 100%;
        font-size: 16px;
    }

    .modal > table > tbody > tr > td {
        padding: 2px 0px;
    }

    .showDetail {
        position: absolute;
        top: 20px;
        right: 47%;
        padding: 5px 10px;
        font-size: 18px;
        background-color: #84caff;
        color: white;
        border-radius: 10px;
        border:none;
    }

    .modal > .button-section {
        position: absolute;
        bottom: 30px;
        box-sizing: border-box;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        width: 60%;
        text-align: center;
        display: flex;
        justify-items: center;
        margin-left: 50%;
        transform: translateX(-60%);
    }

    .modal > .button-section > button {
        background-color: #EFF2F3;
        border: none;
        font-size: 15px;
        width: 170px;
        padding: 10px 15px;
        border-radius: 10px;
        color: black;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.3);
        display: block;
        margin:auto;
    }

    .modal > .button-section > .ok {
        background-color: #48B9FF;
        color: white;
        margin-left: 20px;
    }
</style>