const missionDescript = [
    {
        id: 11,
        kor: '개영식',
        eng: 'Opening Ceremony',
        korDesc: '개영식 관람하세요!<br />*개영식 중 사회자가 안내하는 캡처 타임에 화면을 캡처하여 업로드하세요',
        endDesc: 'Watch the opening ceremony!<br />*MC will tell you the capture time during the Opening Ceremony, capture it and upload it',
        img: 'opening.png',
        url: 'https://zep.us/play/2XnKNx',
        point: 10,
    },
    {
        id: 12,
        kor: 'Self 스크린타임',
        eng: 'Self Screen Time',
        korDesc: `나의 스크린 타임을 확인하고 스크린 타임을 줄여야하는 이유를 알아봐요<br />* 10/2~10/5 스크린타임을 캡처하여 10/5(목)에 업로드하세요`,
        endDesc: `Let's check my screen time and find out why we need to reduce screen time<br />* Capture the screen time from 10/2 to 10/5 and upload it on 10/5(Thu)`,
        img: 'screentime.png',
        url: 'https://zep.us/play/2zMWwO',
        point: 10,
    },
    {
        id: 13,
        kor: 'Guess Who?',
        eng: 'Guess Who?',
        korDesc: `프로필, 나의 취향, 친구추가 등 마이페이지를 꾸며주세요<br />
        * 멋지게 꾸민 마이페이지를 캡처하여 업로드하세요`,
        endDesc: `Design your profile, favorite, and add friends to your My Page. <br />
        * Take a screenshot of your My Page and upload it`,
        img: 'guesswho.png',
        url: '/mypage',
        point: 10,
    },
    {
        id: 14,
        kor: 'Today Quest',
        eng: 'Today Quest',
        korDesc: `‘출석체크 퀴즈’, ‘파도타기(친구 방명록 작성 10회)‘, ’선플은 사랑입니다(친구 미션에 선플 작성 10회)‘에 참여하고 추가 리워드를 얻어보세요.`,
        endDesc: `Participate in the 'Daily Check Quiz,' 'Visit Profiles(write in the Guest Book 10 times)', 'Comment on Your Friends(write a comment on your friends' missions 10 times)' and earn additional rewards!`,
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        autocerti: true,
    },
    {
        id: 21,
        kor: '나만의 해피박스',
        eng: 'My own happy box',
        korDesc: `나를 행복하게 하는 물건은 무엇일까요? 친구들과 공유해요<br />* 나를 행복하게 하는 물건을 담은 해피박스를 만들어 사진을 업로드하세요`,
        endDesc: `What is the items that make me happy? Share them with friends<br />* Make a Happy Box containing the items that make me happy, take a picture and upload it`,
        img: 'happybox.png',
        url: 'https://zep.us/play/y1qB6x',
        point: 10,
    },
    {
        id: 22,
        kor: '미니요가',
        eng: 'Mini yoga for Teens',
        korDesc: `스트레스 해소법을 배워요<br />*미니요가 영상을 보고 요가 영상을 찍어 업로드하세요`,
        endDesc: `Learn how to relieve stress<br />* Watch the mini yoga video and record and upload a video of yourself imitating the mini yaga`,
        img: 'miniyoga.png',
        url: 'https://zep.us/play/y1qB6x',
        point: 10,
    },
    {
        id: 23,
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        korDesc: `나와 비슷한 취향의 대원들과 취향에 대해 이야기를 나눠요<br />
        오전 10시, 오후 8시에 내가 관심있는 주제의 방에 들어가 대화하는 모습을 캡처하여 업로드하세요`,
        endDesc: `Let's talk about our interests with members who have similar interests with me<br />
        * Capture and upload your figure in a topic room where you have interest at 10am and 8pm(KST).`,
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
    },
    {
        id: 24,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        korDesc: `퀴즈를 모두 맞추고 골든벨을 울려요<br />
        Challenge! Golden Bell 중 사회자가 안내하는 캡처 타임에 화면을 캡처하여 업로드하세요`,
        endDesc: `* Take a screenshot the capture time during the Golden Bell and upload it`,
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
    },
    {
        id: 25,
        kor: 'Today Quest',
        eng: 'Today Quest',
        korDesc: `‘출석체크 퀴즈’, ‘파도타기(친구 방명록 작성 10회)‘, ’선플은 사랑입니다(친구 미션에 선플 작성 10회)‘에 참여하고 추가 리워드를 얻어보세요.`,
        endDesc: `Participate in the 'Daily Check Quiz,' 'Visit Profiles(write in the Guest Book 10 times)', 'Comment on Your Friends(write a comment on your friends' missions 10 times)' and earn additional rewards!`,
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        autocerti: true,
    },
    {
        id: 31,
        kor: '언니들의 백패킹',
        eng: `Sister's Back packing`,
        korDesc: `야영에 필요한 물건을 알아봐요<br />
        * 백패킹 영상을 참고하여 나의 실제 백패킹 사진 또는 영상을 업로드하세요`,
        endDesc: `Learn about items you need for camping<br />
        * Upload your actual packpacking photos or videos by referring to backpacking video`,
        img: 'backpacking.png',
        url: 'https://zep.us/play/ydw6N4',
        point: 10,
    },
    {
        id: 32,
        kor: 'Guide Your Money',
        eng: 'Guide Your Money',
        korDesc: `금융에 대해 알아보고 용돈을 어떻게 사용하면 좋을지 배워요<br />
        * 금융교육 퀴즈 점수판을 캡처하여 업로드하세요`,
        endDesc: `Learn about finance and learn how to use your pocket money<br />
        * Capture and upload the scoreboard of the Guide Your Money quiz`,
        img: 'guidemoney.png',
        url: 'https://zep.us/play/ydw6N4',
        point: 10,
    },
    {
        id: 33,
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        korDesc: `나와 비슷한 취향의 대원들과 취향에 대해 이야기를 나눠요<br />
        오전 10시, 오후 8시에 내가 관심있는 주제의 방에 들어가 대화하는 모습을 캡처하여 업로드하세요`,
        endDesc: `Let's talk about our interests with members who have similar interests with me<br />
        * Capture and upload your figure in a topic room where you have interest at 10am and 8pm(KST).`,
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
    },
    {
        id: 34,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        korDesc: `퀴즈를 모두 맞추고 골든벨을 울려요<br />
        Challenge! Golden Bell 중 사회자가 안내하는 캡처 타임에 화면을 캡처하여 업로드하세요`,
        endDesc: `* Take a screenshot the capture time during the Golden Bell and upload it`,
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
    },
    {
        id: 35,
        kor: 'Today Quest',
        eng: 'Today Quest',
        korDesc: `‘출석체크 퀴즈’, ‘파도타기(친구 방명록 작성 10회)‘, ’선플은 사랑입니다(친구 미션에 선플 작성 10회)‘에 참여하고 추가 리워드를 얻어보세요.`,
        endDesc: `Participate in the 'Daily Check Quiz,' 'Visit Profiles(write in the Guest Book 10 times)', 'Comment on Your Friends(write a comment on your friends' missions 10 times)' and earn additional rewards!`,
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        autocerti: true,
    },
    {
        id: 41,
        kor: '금쪽이들의 뮤직큐',
        eng: 'Music Cue',
        korDesc: `걸스카우트 노래로 춤을 춰봐요<br />
        * 보물상자에서 얻은 손 이모지를 활용한 나만의 댄스 영상을 찍어 업로드하세요`,
        endDesc: `Let’s dance to the Girl Scout song!<br />
        * Upload a video of your dance using hand emojis from the mystery box`,
        img: 'musicq.png',
        url: 'https://zep.us/play/8lwQjr',
        point: 10,
    },
    {
        id: 42,
        kor: '디지털 발자국',
        eng: 'Digital Footprint',
        korDesc: `나의 걸스카우트 활동을 SNS에 공유하고 다른 대원의 활동도 구경해요<br />
        #KoreaGirlscoutCamp를 태그하여 SNS에 나의 걸스카우트 활동을 올린 게시글을 캡처하여 업로드하세요`,
        endDesc: `Share my Girl Scouts activities on SNS and watch other members' activities<br />
        * Tag #KoreaGirlscoutCamp and capture the post that shows my Girl Scout activities on, then upload it`,
        img: 'digitalstamp.png',
        url: 'https://zep.us/play/8lwQjr',
        point: 10,
    },
    {
        id: 43,
        kor: 'Friend Chat',
        eng: 'Friend Chat',
        korDesc: `나와 비슷한 취향의 대원들과 취향에 대해 이야기를 나눠요<br />
        오전 10시, 오후 8시에 내가 관심있는 주제의 방에 들어가 대화하는 모습을 캡처하여 업로드하세요`,
        endDesc: `Let's talk about our interests with members who have similar interests with me<br />
        * Capture and upload your figure in a topic room where you have interest at 10am and 8pm(KST).`,
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
    },
    {
        id: 44,
        kor: 'Challenge! Golden Bell',
        eng: 'Challenge! Golden Bell',
        korDesc: `퀴즈를 모두 맞추고 골든벨을 울려요<br />
        Challenge! Golden Bell 중 사회자가 안내하는 캡처 타임에 화면을 캡처하여 업로드하세요`,
        endDesc: `* Take a screenshot the capture time during the Golden Bell and upload it`,
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
    },
    {
        id: 45,
        kor: 'Today Quest',
        eng: 'Today Quest',
        korDesc: `‘출석체크 퀴즈’, ‘파도타기(친구 방명록 작성 10회)‘, ’선플은 사랑입니다(친구 미션에 선플 작성 10회)‘에 참여하고 추가 리워드를 얻어보세요.`,
        endDesc: `Participate in the 'Daily Check Quiz,' 'Visit Profiles(write in the Guest Book 10 times)', 'Comment on Your Friends(write a comment on your friends' missions 10 times)' and earn additional rewards!`,
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        autocerti: true,
    },
    {
        id: 51,
        kor: 'International Day',
        eng: 'International Day',
        img: 'closing.png',
        korDesc: `023. 10. 6 (FRI) 12:00 KST ZEP에서 참여하세요!<br />
        2023.10.06.(FRI) 12:00 KST Join ZEP<br />
        International Day를 시청하는 장면을 찍어 업로드하세요`,
        endDesc: `2023.10.06(FRI) 12:00 KST Join ZEP!<br />
        Take a picture of watching the International Day and upload it`,
        url: 'https://zep.us/play/2XnKNx',
        point: 3,
    },
    /*{
        id: 52,
        kor: '오프라인 미션',
        eng: 'Offline Mission',
        img: 'offline.png',
        korDesc: `2023. 10. 6 (FRI) ~ 9 (MON)<br />
        경기도청소년야영장 (경기도광주)<br />
        3박 4일간 오프라인에서 야영을 즐겨요!`,
        endDesc: ``,
        url: '/',
        point: 3,
    },*/
]

export default missionDescript;