<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>{{ store.getters.isEnglish ? 'Friend recommendation' : '비슷한 취향을 가진 친구' }}</label>
        </div>

        <ul class="favfriend-list">
            <li v-for="(item, index) in state.list" :key="index">
                <div class="favfriend-item">
                    <img :src="item.profileUrl == '' ? '/img/mypage/dummy.png' : item.profileUrl">
                    <label>{{ item.name }}</label>
                </div>
                <button @click="onClickAdd(item.userId)">{{ store.getters.isEnglish ? '+ add' : '+ 친구추가' }}</button>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'FavoritFriendBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list: [],
        })

        const initPage = () => {
            axios.get('/favorit/recommend')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;
                }
            })
        }

        const onClickAdd = (friendId) => {
            axios.post('/friend/add', {
                friendId: friendId,
            }).then((res) => {
                if(res.data.result) {
                    alert('친구로 등록했습니다.');
                } else {
                    alert('친구등록 실패')
                }
            }).catch(() => alert('친구등록 실패'));
        }

        return {
            state,
            store,
            initPage,
            onClickAdd,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .title-section > span {
        font-size: 32px;
        font-weight: 500;
        margin-left: auto;
        padding-top: 5px;
        color: #84CAFF;
    }

    .favfriend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        justify-content: space-between;
    }

    .favfriend-list > li {
        width: 29.5%;
        padding-bottom: 27.5%;
        background-color: #F5F7F9;
        margin-bottom: 70px;
        position: relative;
        border-radius: 10px;
        text-align: center;
        min-width: 115px;
        min-height: 40px;
    }

    .favfriend-list > li > button {
        border:none;
        background-color: #84CAFF;
        color: white;
        font-size: 14px;
        font-weight: 500;
        border-radius: 10px;
        padding: 7px 10px 5px 10px;
        position: absolute;
        bottom: -40px;
        width: 90px;
        transform: translateX(-50%);
    }

    .favfriend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
        text-align: left;
    }

    .favfriend-item > img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .favfriend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
    }

    .title-section {
        display: flex;
    }

    .title-section > label {
        font-size: 24px;
        display: block;
        margin-top: 7px;
        margin-left: 10px;
    }

    .title-section > span {
        font-size: 32px;
        font-weight: 500;
        margin-left: auto;
        padding-top: 5px;
        color: #84CAFF;
    }

    .favfriend-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .favfriend-list > li {
        width: 130px;
        padding-bottom: 100px;
        background-color: #F5F7F9;
        margin-right: 20px;
        margin-bottom: 70px;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }

    .favfriend-list > li > button {
        border:none;
        background-color: #84CAFF;
        color: white;
        font-size: 14px;
        font-weight: 500;
        border-radius: 10px;
        padding: 7px 5px 5px 5px;
        position: absolute;
        bottom: -40px;
        width: 100px;
        transform: translateX(-50%);
    }

    .favfriend-list > li:nth-child(4n) {
        margin-right: 0;
    }

    .favfriend-item {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
        width: 100%;
        height: 100%;
        text-align: left;
    }

    .favfriend-item > img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    .favfriend-item > label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin-top: auto;
        margin-left: auto;
        display: block;
    }
}
</style>