<template>
    <div class="page-section">
        <h1>report</h1>
        <div class="filter-section">
            <div class="filter-group">
                <label>내외국인</label>
                <select v-model="state.selectedForeigner">
                    <option value="-1">전체</option>
                    <option value="0">내국인</option>
                    <option value="1">외국인</option>
                </select>
            </div>
            <div class="filter-group">
                <label>국가</label>
                <select v-model="state.selectedNation">
                    <option value="-">전체</option>
                    <option v-for="(item,index) in state.nations" :key="index">
                        {{ item.nation }}
                    </option>
                </select>
            </div>
            <div class="filter-group">
                <label>연맹</label>
                <select v-model="state.selectedFederation" @change="onSelectFederation">
                    <option value="-">전체</option>
                    <option v-for="(item,index) in state.federations" :key="index">
                        {{ item.federation }}
                    </option>
                </select>
            </div>
            <div class="filter-group">
                <label>단위대</label>
                <select v-model="state.selectedRegiment">
                    <option value="-">전체</option>
                    <option v-for="(item,index) in state.regiments" :key="index">
                        {{ item.regiment }}
                    </option>
                </select>
            </div>
            <button @click="onReport">통계실행</button>
            <button @click="onExport">엑셀다운</button>
        </div>

        <table id="report" class="report-table">
            <thead>
                <tr>
                    <th width="80">국가</th>
                    <th width="80">연맹</th>
                    <th width="80">단위대명</th>
                    <th width="80">이름</th>
                    <th width="80">성별</th>
                    <th width="80">휴대전화</th>
                    <th width="80">이메일</th>
                    <th width="80">이수증대상</th>
                    <th width="80">설문</th>
                    <th width="80">리워드계</th>
                    <th>개영식</th>
                    <th>Self 스크린타임</th>
                    <th>나만의 해피박스</th>
                    <th>미니요가</th>
                    <th>언니들의 백패킹</th>
                    <th>Guide your money</th>
                    <th>금쪽이들의 뮤직큐</th>
                    <th>디지털 발자국</th>
                    <th>FriendChat</th>
                    <th>골든벨</th>
                    <th>Guess Who</th>
                    <th>Intl'Day</th>
                    <th>출첵퀴즈</th>
                    <th>프로필파도타기</th>
                    <th>선플</th>
                    <th>SDGs#TeamGirl</th>
                    <th>Water</th>
                    <th>Forests</th>
                    <th>Ocean</th>
                    <th>Soil</th>
                    <th>ABCII</th>
                    <th>Safety&Joy</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in state.report" :key="index">
                    <td>{{ item.nation }}</td>
                    <td>{{ item.federation }}</td>
                    <td>{{ item.regiment }}</td>
                    <td>{{ item.name }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ item.email }}</td>
                    <td>{{ checkCert(item) }}</td>
                    <td>{{ item.survey }}</td>
                    <td>{{ item.point }}</td>
                    <td>{{ item.ceremony }}</td>
                    <td>{{ item.screentime }}</td>
                    <td>{{ item.happybox }}</td>
                    <td>{{ item.miniyoga }}</td>
                    <td>{{ item.backpack }}</td>
                    <td>{{ item.guidemoney }}</td>
                    <td>{{ item.music }}</td>
                    <td>{{ item.footprint }}</td>
                    <td>{{ +item.chat1 + +item.chat2 + +item.chat3 }}</td>
                    <td>{{ +item.golden1 + +item.golden2 + +item.golden3 }}</td>
                    <td>{{ item.guesswho }}</td>
                    <td>{{ item.international }}</td>
                    <td>{{ +item.everyday1 + +item.everyday2 + +item.everyday3 + +item.everyday4 + +item.everyday5 }}</td>
                    <td>{{ +item.guestbook1 + +item.guestbook2 + +item.guestbook3 + +item.guestbook4 + +item.guestbook5 }}</td>
                    <td>{{ +item.reply1 + +item.reply2 + +item.reply3 + +item.reply4 + +item.reply5 }}</td>
                    <td>{{ item.sdg }}</td>
                    <td>{{ item.water }}</td>
                    <td>{{ item.forests }}</td>
                    <td>{{ item.ocean }}</td>
                    <td>{{ item.soil }}</td>
                    <td>{{ item.abc }}</td>
                    <td>{{ item.safty }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';
import { reactive } from 'vue';
import * as xlsx from 'xlsx';

export default {
    name: "ReportPage",
    mounted() {
        this.initPage();
    },
    setup() {
        const state = reactive({
            selectedForeigner: -1,
            selectedNation: '-',
            selectedFederation: '-',
            selectedRegiment: '-',
            selectedMissionId: -1,
            selectedCertified: -1,
            nations: [],
            federations: [],
            regiments: [],
            report: [],
        })

        const initPage = function () {
            axios.get('https://ic.girlscout.or.kr:5443/mission/nations')
            .then((res) => {
                if(res.data.result) {
                    state.nations = res.data.data;
                }
            })

            axios.get('https://ic.girlscout.or.kr:5443/mission/federations')
            .then((res) => {
                if(res.data.result) {
                    state.federations = res.data.data;
                }
            })
        }

        const onSelectFederation = function () {
            state.regiments = [];
            state.selectedRegiment = '-';
            
            axios.get('https://ic.girlscout.or.kr:5443/mission/regiments/' + state.selectedFederation)
            .then((res) => {
                if(res.data.result) {
                    state.regiments = res.data.data;
                }
            })
        }

        const onReport = function () {
            axios.get(`https://ic.girlscout.or.kr:5443/mission/report/${state.selectedForeigner}/${state.selectedNation}/${state.selectedFederation}/${state.selectedRegiment}/${state.selectedMissionId}/${state.selectedCertified}`)
            .then((res) => {
                if(res.data.result) {
                    state.report = res.data.data;
                }
            })
        }

        const onExport = function () {
            //const worksheet = xlsx.utils.json_to_sheet(state.missionDetail);
            const worksheet = xlsx.utils.table_to_sheet(document.getElementById('report'), "report");
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet');
            xlsx.writeFile(workbook, 'mission_report.xlsx');

            alert('저장했습니다.');
        }

        const makePosi = function (v) {
            return +v > 0 ? +v : 0;
        }

        const checkCert = function (item) {
            if(item.survey != 'O') return 'X';
            
            let daily = makePosi(item.screentime) + makePosi(item.happybox) + makePosi(item.miniyoga) + makePosi(item.backpack) + makePosi(item.guidemoney) + makePosi(item.music) + makePosi(item.footprint);
            if(daily < 5) return 'X';

            let intl = makePosi(item.guesswho) + makePosi(item.chat1) + makePosi(item.chat2) + makePosi(item.chat3) + makePosi(item.golden1) + makePosi(item.golden2) + makePosi(item.golden3);
            console.log(intl);
            if(intl < 5) return 'X';

            let today = makePosi(item.everyday1) + makePosi(item.everyday2) + makePosi(item.everyday3) + makePosi(item.everyday4) + makePosi(item.everyday5) + makePosi(item.guestbook1) + makePosi(item.guestbook2) + makePosi(item.guestbook3) + makePosi(item.guestbook4) + makePosi(item.guestbook5) + makePosi(item.reply1) + makePosi(item.reply2) + makePosi(item.reply3) + makePosi(item.reply4) + makePosi(item.reply5);
            console.log(today);
            if(today < 7) return 'X';

            let world = makePosi(item.sdg) + makePosi(item.abc) + makePosi(item.water) + makePosi(item.ocean) + makePosi(item.soil) + makePosi(item.forests) + makePosi(item.safty);
            console.log(world);
            if(world < 4) return 'X';

            return 'O';
        }

        return {
            state,
            initPage,
            onSelectFederation,
            onReport,
            onExport,
            checkCert,
            makePosi,
        }
    }
}
</script>

<style scoped>
.page-section {
    width: 90%;
    height: 90%;
    border: none;
    background-color: white;
    border-radius: 30px;
    margin: auto;
    padding: 30px;
    margin-top: 5%;
    box-sizing: border-box;
    overflow: auto;
}

.report-table {
    width: 4000px;
}

.report-table > tbody > tr > td {
    text-align: center;
}

.filter-section {
    display: flex;
    margin-bottom: 30px;
}

.filter-group {
    display: flex;
    margin-right: 20px;
}

.filter-group > label {
    margin-right: 10px;
}

.filter-section > button {
    margin-right: 10px;
}
</style>