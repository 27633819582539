<template>
    <div class="component-section">
        <div class="title-section">
            <img src="@/assets/scope.png">
            <span>{{ strings.sectionTitle[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        
        <ul id="mission">
            <li v-for="(item, index) in state.list" :key="index" @click="onShowDetail(item.id)">
                <div class="image-box">
                    <img :src="item.fileUrl" />
                </div>
                <p>{{ item.missionName }}</p>
            </li>
        </ul>

        <!--img id="missionscroll" src="/img/scroll.png" /-->

        <MissionDetailBlock ref="detail" />
    </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';
import MissionDetailBlock from '../mypage/MisstionBlock/MissionDetailBlock.vue';

export default {
    name: 'MissionCompleteComponent',
    mounted() {
        this.initPage();
    },
    updated() {
        /*const day1 = document.querySelector('#mission');
        const day1scroll = document.querySelector('#missionscroll');
        
        if (day1.clientWidth < day1.scrollWidth) {
            day1.addEventListener('scroll', function () {
                day1scroll.style.display = day1.scrollLeft == (day1.scrollWidth - day1.clientWidth) ? 'none' : 'block';
            }, { passive: true });
            day1scroll.style.display = 'block';
        }
        else {
            day1scroll.style.display = 'none';
        }*/
    },
    setup() {
        const store = useStore();
        const strings = reactive({
            isEnglish: 0,
            sectionTitle: [
                '오늘의 베스트 미션!',
                `Today's best mission!`,
            ],
            day1Title: [
                '10/2 월요일',
                '10/2 Mon'
            ],
            day2Title: [
                '10/3 화요일',
                '10/3 Tue'
            ],
            day3Title: [
                '10/4 수요일',
                '10/4 Wed'
            ],
            day4Title: [
                '10/5 목요일',
                '10/5 Thu'
            ]
        });
        const state = reactive({
            list: [],
        });
        const detail = ref();
        const initPage = () => {
            axios.get('/mission/random/5')
                .then((res) => {
                if (res.data?.result || false) {
                    state.list = res.data.data;
                }
            });
        };
        const onShowDetail = function (id) {
            detail.value.onShow(id);
        };
        return {
            store,
            detail,
            strings,
            state,
            initPage,
            onShowDetail,
        };
    },
    components: { MissionDetailBlock }
}
</script>


<style scoped>
.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 15vw;
        margin-top: 0px;
        margin-right: 7px;
    }

    .title-section > span {
        display: block;
        font-size: 8vw;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    ul {
        display: flex;
        list-style: none;
        white-space: nowrap;
        overflow: auto;
        padding: 0;
        margin: 0;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 40px;
    }

    ul::-webkit-scrollbar {
        display: none;
    }

    ul > li {
        width: 150px;
        height: 200px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px;
    }

    ul > li > .image-box {
        width: 130px;
        height: 70%;
        background-color: #E5E5E7;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top:0;
        text-align: center;
        overflow: hidden;
    }

    .image-box > img {
        width: 100%;
    }

    .complete-section > ul > li > p {
        bottom: 0px;
        left: 20px;
        font-size: 18px;
        font-weight: 700;
        width: 130px;
    }
}

@media (min-width:768px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 72px;
        margin-top: 0px;
        margin-right: 12px;
    }

    .title-section > span {
        display: block;
        font-size: 48px;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    ul {
        display: flex;
        list-style: none;
        width:83%;
        height: 299px;
        box-sizing: border-box;
        overflow: auto;
        margin-top: 40px;
        margin-left: 8%;
    }

    ul::-webkit-scrollbar {
        display: none;
    }

    ul > li {
        width: 240px;
        height: 299px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
    }

    ul > li > .image-box {
        width: 220px;
        height: 220px;
        background-color: #E5E5E7;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top:0;
        text-align: center;
        overflow: hidden;
    }

    .image-box > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ul > li > p {
        bottom: 0px;
        left: 20px;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        margin-top: 25px;
    }

    .component-section > img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(calc(-50% - 90px), calc(-50% + 22px));
        box-shadow: 0px 0px 20px rgba(65,91,114,0.5);
        border-radius: 100px;
    }
}

</style>