<template>
    <div class="component-section">
        <div class="video-section">
            <div class="video">
                <video width="950" height="534" autoplay muted loop src="https://girlscout2023.s3.ap-northeast-2.amazonaws.com/notice/2023_girlscout.mp4"></video>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainVideoComponent',
    setup() {

    }
}
</script>


<style scoped>
.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .video-section {
        width: 100%;
        margin-top: 50px;
        text-align: center;
    }

    .video-section > .video {
        width: 100vw;
        height: 56.6vw;
        margin-left: auto;
        margin-right: auto;
        background-color: black;
    }

    .video-section > .video > video {
        width: 100vw;
        height: 56.8vw;
    }
}

@media (min-width:768px) {
    .component-section {
        margin-bottom: 100px;
    }

    .video-section {
        width: 100%;
        margin-top: 150px;
        text-align: center;
    }

    .video-section > .video {
        width: 950px;
        height: 534px;
        max-width: 950px;
        max-height: 534px;
        margin-left: auto;
        margin-right: auto;
        background-color: black;
    }
}

</style>