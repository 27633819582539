const agreeItem = [
    {
        eng: `The 19th Girl Scouts International Camp is a safe space where only participants who applied in advance can participate, expressing themselves more freely online, participating in exchange activities with Girl Scouts members around the world, and speaking out together on various topics.`,
        kor: `제19회 걸스카우트 국제야영이 진행되는 이곳은<br />
        사전에 참가신청 한 참가자만 참여할 수 있는 안전한 공간으로<br />
        온라인상에서 보다 자유롭게 나를 표현하고, 전 세계 걸스카우트 회원들과의 교류활동에 참여하며,<br />
        다양한 주제에 대해 전 세계 소녀와 젊은 여성들이 함께 목소리를 내는 공간입니다.`,
        yesno: false,
    },
    {
        eng: `To this end, the Operations Headquarters will conduct continuous monitoring and take action immediately in the event of a problem, and we would appreciate it if participants could understand and pledge the contents of the activity safety guidelines before starting the activity and cooperate to start the activity.<br />
        <br />
        Okay! Should we get started now?`,
        kor: `이를 위해 운영본부에서는 지속적인 모니터링을 실시하여  문제발생시 바로 조치를 취할 예정이며,<br />
        참가자 여러분은 활동을 시작하기 전 활동안전지침의 내용을 숙지하고 다짐한 후<br />
        본격적인 활동에 들어갈 수 있도록 협조해주시면 감사하겠습니다.<br />
        <br />
        자! 이제 시작해볼까요?`,
        yesno: true,
    },
    {
        eng: `I have obtained permission from my guardian for participation in the 19th Girl Scouts International Camping Activities, personal information, and portrait rights (use and sharing of activity photos, videos, etc.) and I also agree with this.`,
        kor: `제19회 걸스카우트 국제야영 활동 참가 및 개인정보, 초상권(활동 사진, 동영상 등의 사용 과 공유)에 대해 보호자의 허락을 받았으며, 본인 또한 이에 동의합니다.`,
        yesno: true,
    },
    {
        eng: `I know the importance of protecting various personal information I encounter in activities and I respect them.
        When taking and sharing photos or videos during activities, special care will be taken to avoid exposing or stealing personal information of others.`,
        kor: `활동에서 접하는 다양한 개인정보 보호의 중요성을 알고 있으며,<br />
        이를 존중하는 자세를 가지고 있습니다.<br />
        활동 중 사진이나 동영상을 찍고 공유할 때, 나와 다른 사람들의 개인정보가 노출되거나<br />
        도용되지 않도록 각별히 유의할 것입니다.`,
        yesno: true,
    },
    {
        eng: `I will make it safe from viruses or malicious codes by using Internet security software, make it a habit to use passwords, do not share my password with others, and do not engage in financial activities such as using Internet banking during activities.`,
        kor: `인터넷 보안 소프트웨어를 사용하여 바이러스나 악성코드로부터 안전할 수 있도록 하였으며,<br />
        비밀번호 사용을 습관화하고, 나의 비밀번호를 남들과 공유하지 않도록 하며<br />
        활동 중 인터넷 뱅킹 사용 등 금융 관련 활동을 하지 않을 것입니다.`,
        yesno: true,
    },
    {
        eng: `Aware of the problems and risks of sharing illegal files or accessing inappropriate sites, and we will not post or share inappropriate photos•videos•links•illegal files or access inappropriate sites.`,
        kor: `불법 파일을 공유하거나 부적절한 사이트에 접근했을 때의 문제점과 위험성을 알고 있으며,<br />
        부적절한 사진·동영상·링크·불법파일을 게시·공유하거나 부적절한 사이트에 접근하지 않을 것입니다.`,
        yesno: true,
    },
    {
        eng: `Use the right language, respect the other person and take part in activities by following rules and courtesy with an open mind, and will not post inappropriate comments. <br />
        In addition I will listen carefully to the other person's story accurately convey my opinions and create a safe space where we can trust each other.`,
        kor: `바른 언어를 사용하고, 상대방을 존중하고 열린 마음으로 대하는 자세로<br />
        규칙과 예의를 지키며 활동에 참여하며, 부적절한 댓글은 달지 않을 것입니다. <br />
        또한 상대방의 이야기를 귀 기울여 들으며, 나의 의견을 정확하게 전달하고,<br />
        서로 신뢰할 수 있는 안전한 공간을 만들어갈 것입니다.`,
        yesno: true,
    },
    {
        eng: ``,
        kor: `화상미팅 시에는 적절한 복장을 갖추고, 외부 환경으로부터 방해를 받지 않으며 <br />
        나로 인한 소음으로 다른 사람들의 활동에 지장을 주지 않는 조용하고 적절한 장소에서 활동에 참여하도록 하고,<br />
        상대방이 나의 말을 정확히 듣고 이해할 수 있도록 적당한 크기와 속도로 명확하게 말하도록 하며,<br />
        발언 시에는 진행자의 허락을 받은 후 하도록 할 것입니다.`,
        yesno: true,
    },
    {
        eng: ``,
        kor: `온라인에서 제공되는 수많은 정보와 경험을 통해 <br />
        나의 잠재력을 개발하고 성장하는 기회가 될 수 있도록 노력하고,<br />
        활동 전후 SNS 등을 통해 전 세계 회원들과 활동을 공유하도록 할 것입니다.`,
        yesno: true,
    },
    {
        eng: ``,
        kor: `만약, 다음과 같은 상황이 발생했을 때는 보호자나 대장님, 운영본부(02-733-6801~8)로 <br />
        바로 연락하여 도움을 청하도록 합니다.`,
        yesno: false,
    },
    {
        eng: ``,
        kor: `∙ 예상치 못한 외부인이 등장하거니 성인이 청소년에게 오프라인 만남을 요구했을 때<br />
        ∙ 누군가 개인정보를 요구하거나 개인정보를 다른 사람에게 노출했을 때<br />
        ∙ 타인에 대한 욕설, 비방, 모욕 등의 부정적 언어를 사용하는 것을 알게 되었을 때<br />
        ∙ 온라인에서 괴롭힘, 따돌림 등 디지털 폭력을 사용하는 것을 알게 되었을 때<br />
        ∙ 그 외 안전이 위협되거나 의심되는 상황을 만나게 될 경우.`,
        yesno: true,
    },
    {
        eng: ``,
        kor: `또한, 문제발생 시 운영본부에서는 다음과 같은 조치를 취할 수 있음을 확인합니다.<br />
        <br />
        ∙ 해당 참가자에 대한 경고 조치 또는 행사의 모든 활동 참여 제한<br />
        ∙ 필요한 경우, 행정기관을 통한 청소년 보호 및 신고 조치 시행<br />
        ∙ 유사 사고가 발생하지 않도록 전체 참가자에게 사례 공지`,
        yesno: true,
    },
    {
        eng: ``,
        kor: `활동과 관련된 모든 자료의 저작권은 한국걸스카우트연맹에 있음을 알고 있으며 <br />
        이를 침해하지 않도록 할 것입니다. <br />
        또한 제19회 걸스카우트 국제야영(온라인)에 참가하면서 <br />
        운영본부의 지침을 준수하며 안전하고 건강한 온라인 활동을 할 것을 서약합니다.`,
        yesno: true,
    },
];

export default agreeItem;