<template>
    <div class="component-section">
        <div class="title-section">
            <img src="@/assets/scope.png">
            <span>{{ strings.sectionTitle[store.getters.isEnglish ? 1 : 0] }}</span>
        </div>

        <ul>
            <li @click="onOpenLink('https://korean.visitkorea.or.kr/main/main.do')">
                <img class="logo-image" src="/img/sites/vkorea-kor.jpg" style="height:80px;margin-top:-20px;">
                <img class="link-button" src="@/assets/link_button.png">
            </li>
            <li @click="onOpenLink('https://english.visitkorea.or.kr/svc/main/index.do')">
                <img class="logo-image" src="/img/sites/vkorea-eng.png" style="margin-top: 10px;">
                <img class="link-button" src="@/assets/link_button.png">
            </li>
            <li>
                <img class="logo-image" src="/img/sites/duru.png" style="margin-top:-12px;">
                <div class="link">
                    <a href="https://www.durunubi.kr" target="_blank">한글</a>
                    <a href="https://www.durunubi.kr/eng/main.do" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/sites/ggtour.jpg">
                <div class="link">
                    <a href="https://ggtour.or.kr/main.php" target="_blank">한글</a>
                    <a href="https://en.ggtour.or.kr" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/sites/dmz.png" style="height:90px;margin-top:-20px;">
                <div class="link">
                    <a href="https://dmz.gg.go.kr/" target="_blank">한글</a>
                    <a href="https://english.gg.go.kr/dmz-imjingak" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/sites/nmuse.png">
                <div class="link">
                    <a href="https://www.museum.go.kr/site/main/home" target="_blank">한글</a>
                    <a href="https://www.museum.go.kr/site/eng/home" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/sites/hyundae.png">
                <div class="link">
                    <a href="https://www.mmca.go.kr/" target="_blank">한글</a>
                    <a href="https://www.mmca.go.kr/eng/" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/sites/wagg.png" style="margin-top:-6px;">
                <div class="link">
                    <a style="margin-top:30px;" href="https://www.wagggs.org/" target="_blank">English</a>
                </div>
            </li>
            <li>
                <img class="logo-image" src="/img/logos/girlscout.jpeg">
                <div class="link">
                    <a href="https://www.girlscout.or.kr/" target="_blank">한글</a>
                    <a href="https://www.girlscout.or.kr/Content/eng/main.html" target="_blank">English</a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'OtherHomepageComponent',
    setup() {
        const store = useStore();
        const strings = reactive({
            sectionTitle: [
                '랜선여행',
                'Online Travel',
            ],
        })
        const onOpenLink = (link) => {
            window.open(link);
        }

        return {
            store,
            strings,
            onOpenLink,
        }
    }
}
</script>


<style scoped>.component-section {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    overflow: hidden;
}

@media (min-width: 100px) and (max-width: 767px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 15vw;
        margin-top: 0px;
        margin-right: 7px;
    }

    .title-section > span {
        display: block;
        font-size: 8vw;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }

    .component-section > ul {
        list-style: none;
        margin: 0;
        width: 80vw;
        margin-top: 60px;
    }

    .component-section > ul > li {
        color: black;
        width: 100%;
        height: 110px;
        background-color: white;
        border-radius: 15px;
        margin-bottom: 20px;
        position: relative;
        box-sizing: border-box;
        padding: 30px;
        display: flex;
    }

    .component-section > ul > li > span {
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
    }

    .component-section > ul > li > .logo-image {
        position: absolute;
        left: 30px;
        max-width: 50%;
        margin-top: 7px;
    }

    .component-section > ul > li > .link-button {
        position: absolute;
        right: 30px;
    }

    .component-section > ul > li:nth-child(even) {
        margin-right: 0;
    }

    .component-section > ul > li:first {
        margin-top: 10px;
    }

    .component-section > ul > li > .link {
        position: absolute;
        right: 10px;
        top:20px;
    }

    .component-section > ul > li > .link > a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        display: block;
        background-color: #84CAFF;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
}

@media (min-width:768px) {
    .title-section {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .title-section > img {
        width: 72px;
        margin-top: 0px;
        margin-right: 12px;
    }

    .title-section > span {
        display: block;
        font-size: 48px;
        color: white;
        font-weight: 800;
        margin-top: 10px;
    }
    .component-section > ul {
        display: flex;
        list-style: none;
        margin: 0;
        flex-wrap: wrap;
        width: 70vw;
        margin-top: 60px;
        margin-left: 50%;
        transform: translateX(-50%);
        justify-content: space-between;
    }

    .component-section > ul > li {
        color: black;
        width: 30%;
        height: 120px;
        background-color: white;
        border-radius: 15px;
        margin-bottom: 50px;
        position: relative;
        box-sizing: border-box;
        padding: 2vw;
    }

    .component-section > ul > li > span {
        display: block;
        font-size: 1.6vw;
        font-weight: 700;
        line-height: 2vw;
    }

    .component-section > ul > li > .logo-image {
        position: absolute;
        left: 30px;
        max-width: 60%;
    }

    .component-section > ul > li > .link-button {
        position: absolute;
        right: 30px;
    }

    .component-section > ul > li:last-child {
        margin-right: 0;
    }

    .component-section > ul > li:first {
        margin-top: 10px;
    }

    .component-section > ul > li > .link {
        position: absolute;
        right: 10px;
        top:20px;
    }

    .component-section > ul > li > .link > a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        display: block;
        background-color: #84CAFF;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
}

</style>