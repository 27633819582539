<template>
    <div class="component-section">
        <img src="/img/19thpatch.png">
        <ul>
            <li>
                <img @click="onSwitchLanguage" :src="store.getters.isEnglish ? '/img/english.png' : '/img/korean.png'">
            </li>
            <li>
                <img @click="onShowNotice" :src="store.getters.isEnglish ? '/img/notice_eng.png' : '/img/notice.png'">
            </li>
            <li v-if="store.getters.getUserObject?.role == 'ADMIN'">
                <img @click="onReport" :src="store.getters.isEnglish ? '/img/report.png' : '/img/report.png'">
            </li>
            <li v-if="store.getters.isVisitMode || store.getters.getCurrentPath == '/'">
                <img @click="onMypage" :src="store.getters.isEnglish ? '/img/mypage_eng.png' : '/img/mypage.png'">
            </li>
            <li v-if="!store.getters.isVisitMode && store.getters.getCurrentPath != '/'">
                <img @click="onHome" :src="store.getters.isEnglish ? '/img/home_eng.png' : '/img/home.png'">
            </li>
            <li>
                <img @click="onLogout" :src="store.getters.isEnglish ? '/img/logout_eng.png' : '/img/logout.png'">
            </li>
        </ul>

        <div v-if="state.showNotice" class="modalFull" @click="onClickCloseNotice">
            <div class="modal" @click="onClickModal">
                <ul>
                    <li v-for="(item, index) in state.list" :key="index" @click="onToggenItem(index)">
                        <h1>{{ store.getters.isEnglish ? item.engSubject : item.korSubject }}</h1>
                        <p v-if="index == state.currentIndex" :innerHTML="store.getters.isEnglish ? state.currentItem?.engContent || '' : state.currentItem?.korContent || ''"></p>
                    </li>
                </ul>
            </div>
            <button @click="onClickCloseNotice">X</button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { reactive } from 'vue';

export default {
    name: 'TopMenuComponent',
    emits: ['reset'],
    setup(pops, { emit }) {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            list: [],
            showNotice: false,
            currentIndex: -1,
            currentItem: null,
            currentPath: '',
        })

        const onSwitchLanguage = () => {
            store.commit('setEnglishMode', store.getters.isEnglish ? 0 : 1);
        }

        const onNotice = () => {}

        const onMypage = () => {
            if(store.getters.isVisitMode) {
                store.dispatch('visitCheckout')
                emit('reset');
            } else {
                router.push('/mypage');
            }
        }

        const onHome = () => {
            router.push('/');
        }

        const onShowNotice = () => {
            state.showNotice = true;
            axios.get('/notice/list')
            .then((res) => {
                if(res.data.result) {
                    state.list = res.data.data;
                } else {
                    state.list = [];
                }
            })
        }

        const onReport = () => {
            router.push('/report')
        }

        const onClickCloseNotice = () => {
            state.showNotice = false;
        }

        const onClickModal = (e) => {
            e.stopPropagation();
        }

        const onToggenItem = (idx) => {
            if(state.currentIndex == idx) {
                state.currentIndex = -1;
                state.currentItem = null;
            } else {
                state.currentIndex = idx;

                axios.get('/notice/detail/' + state.list[idx].id.toString())
                .then((res) => {
                    if(res.data.result) {
                        state.currentItem = res.data.data;
                        console.log(state.currentItem);
                    } else {
                        state.currentItem = null;
                    }
                })
            }
        }

        const onLogout = () => {
            router.push('/login');
            store.commit('clearUserObject');
        }

        return {
            store,
            state,
            onSwitchLanguage,
            onNotice,
            onMypage,
            onReport,
            onHome,
            onShowNotice,
            onClickCloseNotice,
            onClickModal,
            onToggenItem,
            onLogout,
        }
    }
}
</script>

<style scoped>
.component-section {
    width: 100%;
}

@media (min-width: 100px) and (max-width: 767px) {
    .component-section {
        display: block;
        position: relative;
        margin-bottom: 50px;
    }

    .component-section > ul {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
        width: 30%;
    }

    .component-section > ul > li {
        margin-right: 10px;
        width: 100%;
    }

    .component-section > ul > li:last-child {
        margin-right: 0;
    }

    .component-section > ul > li > img {
        height: 7vw;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 90%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 30px;
        text-align: center;

        overflow: auto;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }

    .component-section > img {
        height: 120px;
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 500px;
    }
}

@media (min-width:768px) {
    .component-section {
        display: flex;
        padding:50px 50px 0px 50px;
    }

    .component-section > ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .component-section > ul > li {
        margin-right: 10px;
    }

    .component-section > ul > li:last-child {
        margin-right: 0;
    }

    .component-section > ul > li > img {
        height: 50px;
    }

    .modalFull {
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        backdrop-filter: blur(10px);
        z-index: 999;
    }

    .modal {
        position: fixed;
        z-index: 1000;
        width: 1000px;
        height: 620px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        
        background-color: rgba(255,255,255,1);
        backdrop-filter: blur(10px);
        box-sizing: border-box;
        padding: 50px;
        text-align: center;

        overflow: auto;
    }

    .modalFull > button {
        background: none;
        border: none;
        color: #5f5f5f;
        font-size: 24px;
        background-color: rgba(255,255,255,0.5);
        padding: 10px 15px;
        border-radius: 30px;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }

    .modal > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .modal > ul > li {
        width: 100%;
        background-color: #F5F7F9;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .modal > ul > li > h1 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 20px;
        text-align: left;
        box-sizing: border-box;
    }

    .modal > ul > li > p {
        text-align: left;
        border-top: 1px solid #a1a1a1;
        padding: 20px;
        margin-top: 0;
    }

    .component-section > img {
        height: 180px;
        margin-top: -20px;
        margin-left: 40px;
        margin-right: 500px;
    }
}

</style>