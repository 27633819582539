<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>{{ store.getters.isEnglish ? 'Reawad' : '리워드' }}</label>
        </div>

        <div class="total-section">
            <div class="title" :innerHTML="store.getters.isEnglish ? 'My<br />Reward point' : '나의<br/>리워드 포인트'">
            </div>
            <div class="value">
                {{ state.reward }} P
            </div>
        </div>

        <h1>{{ store.getters.isEnglish ? 'Reawad list' : '리워드 내역' }}</h1>

        <ul>
            <li v-for="(item,index) in state.list" :key="index">
                <label class="date">{{ item.date.substring(2,10) }}</label>
                <label class="title">{{ getMission(item.missionId) || item.missionName }}</label>
                <span class="value">+ {{ item.point }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';
import missionFlatData from '../missionFlatData';

export default {
    name: 'RewardBlock',
    mounted() {
        this.initPage();
    },
    setup() {
        const store = useStore();
        const state = reactive({
            list: [],
            reward: 0,
            missionItems: missionFlatData,
        })

        const initPage = () => {
            axios.get('/mission/reward')
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data;

                    state.reward = 0;
                    state.list.forEach((i) => state.reward += i.point);
                }
            })
        }

        const getMission = (id) => {
            if(id < 10) return false;

            const item = state.missionItems.find((v) => v.id == id);

            return store.getters.isEnglish ? item.eng : item.kor;
        }

        return {
            store,
            state,
            initPage,
            getMission,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 20px 20px;
        background-color: #F5F7F9;
        border-radius: 10px;
        margin-top: 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > h1 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
        margin-top: 30px;
    }

    .block-section > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 20px;
        box-sizing: border-box;
    }

    .block-section > ul > li {
        display: flex;
        justify-content: space-between;
        height: 60px;
    }

    .block-section > ul > li > .date {
        color: #8F9EA8;
        font-size: 16px;
        font-weight: 500;
        width:80px;
    }

    .block-section > ul > li > .title {
        color: #8F9EA8;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        width: auto;
    }

    .block-section > ul > li > .value {
        color: #84CAFF;
        font-size: 20px;
        font-weight: 500;
        text-align: right;
        width: 50px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 90%;
        height: 100%;
        box-sizing: border-box;
        padding: 50px;
        background-color: white;
        border-radius: 20px;
        margin-left: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 30px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .total-section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 40px 20px 0px 20px;
    }

    .total-section > .title {
        width: 40%;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
    }

    .total-section > .value {
        width: 60%;
        font-size: 35px;
        color: #84CAFF;
        font-weight: 900;
        text-align: right;
    }

    .block-section > h1 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        color: #69757F;
        margin-top: 30px;
        margin-left: 20px;
    }

    .block-section > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .block-section > ul > li {
        display: flex;
        justify-content: space-between;
        height: 60px;
    }

    .block-section > ul > li > label {
        color: #8F9EA8;
        font-size: 20px;
        font-weight: 500;
    }

    .block-section > ul > li > .value {
        color: #84CAFF;
        font-size: 20px;
        font-weight: 500;
    }
}
</style>