<template>
    <div class="block-section">
        <img class="chat" src="@/assets/mypage/chat.png" />
        <div class="text-section">
            <h1>Friend Chat</h1>
            <h2 :innerHTML='store.getters.isEnglish ? "Joinable at 10:00, 20:00 (KST)<br />in every day" : "매일 24시간 친구들과 소통해봐요!<br />[10:00, 20:00] (KST)는<br />모두가 함께하는 시간!"'></h2>
        </div>
        <img class="chatlink" src="@/assets/mypage/chatlink.png" />
    </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
    name: 'FriendChatBlock',
    setup() {
        const store = useStore();

        return {
            store,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}
@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 90px;
        display: flex;
        background-color: white;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 20px;
    }

    .block-section > .chat {
        width: 50px;
        height: 50px;
    }

    .block-section > .chatlink {
        width: 50px;
        height: 50px;
        margin-top: 0px;
        margin-left: auto;
    }

    .block-section > .text-section {
        margin-left: 20px;
    }

    .block-section > .text-section > h1 {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }

    .block-section > .text-section > h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        margin-top: 5px;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 170px;
        display: flex;
        background-color: white;
        box-sizing: border-box;
        padding: 40px;
        border-radius: 20px;
        justify-content: space-between;
    }

    .block-section > .chat {
        width: 90px;
        height: 80px;
    }

    .block-section > .chatlink {
        width: 60px;
        height: 60px;
        margin-top: 10px;
    }

    .block-section > .text-section > h1 {
        margin: 0;
        font-size: 30px;
        font-weight: 500;
    }

    .block-section > .text-section > h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
    }
}
</style>