import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';

import LoginPage from '@/pages/login'
import MainPage from '@/pages/main'
import MyPage from '@/pages/mypage'
import Report from '@/pages/report'

const routes = [
    {
        path: '/',
        component: MainPage,
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/mypage',
        component: MyPage,
    },
    {
        path: '/report',
        component: Report
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to) => {
    const store = useStore();
    
    if(!store.getters.isLoggedIn && to.fullPath != '/login') {
        return "/login";
    }
})

export default router
