const missionItem = [
    {
        id: 11,
        kor: '개영식',
        eng: 'Opening<br />Ceremony',
        img: 'opening.png',
        url: 'https://zep.us/play/2XnKNx',
        point: 10,
        date: '1002',
    },
    {
        id: 12,
        kor: 'Self<br />스크린타임',
        eng: 'Self<br />Screen Time',
        img: 'screentime.png',
        url: 'https://zep.us/play/2zMWwO',
        point: 10,
        date: '1002',
    },
    {
        id: 13,
        kor: 'Guess<br />Who?',
        eng: 'Guess<br />Who?',
        img: 'guesswho.png',
        url: '/mypage',
        point: 10,
        date: '1002',
    },
    {
        id: 14,
        kor: 'Today<br />Quest',
        eng: 'Today<br />Quest',
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        date: '1002',
        autocerti: true,
    },
    {
        id: 21,
        kor: '나만의<br />해피박스',
        eng: 'Happy<br />Box',
        img: 'happybox.png',
        url: 'https://zep.us/play/y1qB6x',
        point: 10,
        date: '1003',
    },
    {
        id: 22,
        kor: '미니요가',
        eng: 'Mini yoga',
        img: 'miniyoga.png',
        url: 'https://zep.us/play/y1qB6x',
        point: 10,
        date: '1003',
    },
    {
        id: 23,
        kor: 'Friend<br />Chat',
        eng: 'Friend<br />Chat',
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
        date: '1003',
    },
    {
        id: 24,
        kor: 'Challenge!<br />Golden Bell',
        eng: 'Challenge!<br />Golden Bell',
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
        date: '1003',
    },
    {
        id: 25,
        kor: 'Today<br />Quest',
        eng: 'Today<br />Quest',
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        date: '1003',
        autocerti: true,
    },
    {
        id: 31,
        kor: '언니들의<br />백패킹',
        eng: 'Sisters<br />Backpacking',
        img: 'backpacking.png',
        url: 'https://zep.us/play/ydw6N4',
        point: 10,
        date: '1004',
    },
    {
        id: 32,
        kor: 'Guide<br />Your Money',
        eng: 'Guide<br />Your Money',
        img: 'guidemoney.png',
        url: 'https://zep.us/play/ydw6N4',
        point: 10,
        date: '1004',
    },
    {
        id: 33,
        kor: 'Friend<br />Chat',
        eng: 'Friend<br />Chat',
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
        date: '1004',
    },
    {
        id: 34,
        kor: 'Challenge!<br />Golden Bell',
        eng: 'Challenge!<br />Golden Bell',
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
        date: '1004',
    },
    {
        id: 35,
        kor: 'Today<br />Quest',
        eng: 'Today<br />Quest',
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        date: '1004',
        autocerti: true,
    },
    {
        id: 41,
        kor: '금쪽이들의<br />뮤직큐',
        eng: 'Music Q',
        img: 'musicq.png',
        url: 'https://zep.us/play/8lwQjr',
        point: 10,
        date: '1005',
    },
    {
        id: 42,
        kor: '디지털<br />발자국',
        eng: 'Digital<br />Footprint',
        img: 'digitalstamp.png',
        url: 'https://zep.us/play/8lwQjr',
        point: 10,
        date: '1005',
    },
    {
        id: 43,
        kor: 'Friend<br />Chat',
        eng: 'Friend<br />Chat',
        img: 'friendchat.png',
        url: 'https://zep.us/play/yowkzX',
        point: 10,
        date: '1005',
    },
    {
        id: 44,
        kor: 'Challenge!<br />Golden Bell',
        eng: 'Challenge!<br />Golden Bell',
        img: 'goldenbell.png',
        url: 'https://zep.us/play/8Aqz95',
        point: 10,
        date: '1005',
    },
    {
        id: 45,
        kor: 'Today<br />Quest',
        eng: 'Today<br />Quest',
        img: 'todayquest.png',
        url: '/mypage',
        point: 3,
        date: '1005',
        autocerti: true,
    },
    {
        id: 51,
        kor: 'International<br />Day',
        eng: 'International<br />Day',
        img: 'closing.png',
        url: 'https://zep.us/play/2XnKNx',
        date: '1006',
        point: 10,
    },
    /*{
        id: 52,
        kor: '오프라인<br />미션',
        eng: 'Offline<br />Mission',
        img: 'offline.png',
        url: '/',
        point: 10,
    },*/
]

export default missionItem;