<template>
    <div class="block-section">
        <div class="title-section">
            <img src="@/assets/mypage/mycompass.png">
            <label>{{ store.getters.isEnglish ? 'Mission' : '미션' }}</label>
        </div>

        <button class="btn-more" @click="onClickMore">{{ store.getters.isEnglish ? '+ more' : '+ 더보기' }}</button>

        <div class="progress-section">
            <div class="background-bar"></div>
            <div class="progress-bar" :style="{'width': Math.floor(state.list.length / 23 * 100).toString()+'%'}"></div>
            <div class="sun-bar" :style="{'left': Math.floor(state.list.length / 23 * 100).toString()+'%'}">
                <img src="/img/mypage/missionSun.png">
            </div>
            <div class="percent-bar" :style="{'left': Math.floor(state.list.length / 23 * 100).toString()+'%'}">
                {{Math.floor(state.list.length / 23 * 100)}}%
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch } from 'vue';
import axios from 'axios'
import { useStore } from 'vuex';

export default {
    name: 'MissionBlock',
    emits: ['more'],
    mounted() {
        this.initPage();
    },
    setup(props, { emit }) {
        const store = useStore();
        const state = reactive({
            list: [],
        })

        watch(() => store.getters.getUserObject, () => initPage() );

        const initPage = () => {
            axios.get('/mission/list' + (store.getters.isVisitMode ? '/' + store.getters.getUserObject.id.toString() : ''))
            .then((res) => {
                if(res.data?.result || false) {
                    state.list = res.data.data.filter((v) => v.certified == 1);
                } else {
                    state.list = [];
                }
            })
        }

        const onClickMore = () => {
            emit('more');
        }

        return {
            state,
            store,
            initPage,
            onClickMore,
        }
    }
}
</script>

<style scoped>
.block-section {
    position: relative;
    box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.05);
}

@media (min-width: 100px) and (max-width: 767px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
        margin-top: 40px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .progress-section {
        box-sizing: border-box;
        position: relative;
        height: 100%;
    }

    .background-bar {
        width: 100%;
        height: 30px;
        border-radius: 30px;
        background-color: #F5F7F9;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.1);
        position: absolute;
        bottom: 25%;
        z-index: 1;
    }

    .progress-bar {
        width: 30%;
        height: 30px;
        border-radius: 30px;
        background-color: #84CAFF;
        position: absolute;
        bottom: 25%;
        z-index: 2;
    }

    .sun-bar {
        position: absolute;
        bottom: -10px;
        left: 30%;
        transform: translateX(-45px);
        z-index: 3;
    }

    .percent-bar {
        background-image: url(~@/assets/mypage/percentbox.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 5;
        top: 15px;
        left: 30%;
        transform: translateX(-50%);
        height: 43px;
        width: 59px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 9px;
        color: white;
        font-size: 17px;
        font-weight: 500;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: #A5B5BB;
    }
}

@media (min-width:768px) {
    .block-section {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        border-radius: 20px;
    }

    .title-section {
        display: flex;
    }

    .title-section > img {
        width: 28px;
        height: 32px;
    }

    .title-section > label {
        font-size: 20px;
        display: block;
        margin-top: 4px;
        margin-left: 10px;
    }

    .progress-section {
        box-sizing: border-box;
        position: relative;
        height: 100%;
    }

    .background-bar {
        width: 100%;
        height: 30px;
        border-radius: 30px;
        background-color: #F5F7F9;
        box-shadow: 4.8px 4.8px 5.8px rgba(65,91,114,0.1);
        position: absolute;
        bottom: 25%;
        z-index: 1;
    }

    .progress-bar {
        width: 30%;
        height: 30px;
        border-radius: 30px;
        background-color: #84CAFF;
        position: absolute;
        bottom: 25%;
        z-index: 2;
    }

    .sun-bar {
        position: absolute;
        bottom: -10px;
        left: 30%;
        transform: translateX(-45px);
        z-index: 3;
    }

    .percent-bar {
        background-image: url(~@/assets/mypage/percentbox.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 5;
        top: 15px;
        left: 30%;
        transform: translateX(-50%);
        height: 43px;
        width: 59px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 9px;
        color: white;
        font-size: 17px;
        font-weight: 500;
    }

    .btn-more {
        position: absolute;
        top: 33px;
        right: 30px;
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: #A5B5BB;
    }
}
</style>