import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    state: {
        englishMode: 0,
        user: null,
        visit: null,
        currentPath: '',
        rememberId: '',
    },
    getters: {
        getUserObject(state) {
            return state.visit ? state.visit : state.user;
        },
        getOriginalUser(state) {
            return state.user;
        },
        getCurrentPath(state) {
            return state.currentPath;
        },
        isEnglish(state) {
            return state.englishMode;
        },
        isVisitMode(state) {
            return state.visit ? true : false;
        },
        isLoggedIn(state) {
            return state.user == null ? false : true;
        },
        getRememberId(state) {
            return state.rememberId;
        }
    },
    mutations: {
        setUserObject(state, user) {
            state.user = user;
        },
        setVisitObject(state, visit) {
            state.visit = visit;
        },
        setCurrentPath(state, path) {
            state.currentPath = path
        },
        clearUserObject(state) {
            state.user = null;
        },
        clearVisitObject(state) {
            state.visit = null;
        },
        setEnglishMode(state, mode) {
            state.englishMode = mode;
        },
        setRememberId(state, id) {
            state.rememberId = id;
        },
        clearRememberId(state) {
            state.rememberId = '';
        }
    },
    actions: {
        logout({commit}) {
            commit('clearUserObject')
        },
        visitCheckout({commit}) {
            commit('clearVisitObject')
        },
        clearMe({commit}) {
            commit('clearRememberId')
        }
    },
    plugins: [
        createPersistedState({
            paths: ["englishMode", "user", "rememberId"]
        })
    ]
});

export default store;
